import React from "react"
import { MedicalFileDoc } from "../../../../domain/MedicalFile"
import { Grid } from "@mui/material"
import { SkinAllergies } from "./SkinAllergies"
import { DrugAllergies } from "./DrugAllergies"
import { RespiratoryAllergies } from "./RespiratoryAllergies"

interface AllergiesProps {
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

export const Allergies: React.FC<AllergiesProps> = ({
  medicalFile,
  setMedicalFile,
}) => {
  return (
    <Grid container columnSpacing={8} rowSpacing={2}>
      <SkinAllergies
        medicalFile={medicalFile}
        setMedicalFile={setMedicalFile}
      />
      <RespiratoryAllergies
        medicalFile={medicalFile}
        setMedicalFile={setMedicalFile}
      />
      <DrugAllergies
        medicalFile={medicalFile}
        setMedicalFile={setMedicalFile}
      />
    </Grid>
  )
}
