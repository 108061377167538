import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore"
import firebase from "firebase/compat"
import {
  CollectionReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  UpdateData,
} from "@firebase/firestore"
import { Message, MessageDoc } from "../domain/Message"
import { User, UserDoc } from "../domain/User"
import { Resident, ResidentDoc } from "../domain/Resident"
import { Facility, FacilityDoc } from "../domain/Facility"
import { Protocol } from "../domain/Protocol"
import { AssignedJob } from "../domain/AssignedJob"
import { RoleDoc } from "../domain/Role"
import dayjs, { Dayjs } from "dayjs"
import { TimesheetDoc } from "../domain/Timesheet"
import { sendPasswordResetEmail } from "@firebase/auth"
import { ProfileDoc } from "../domain/Profile"
import { ContractDoc } from "../domain/Contract"
import { TaskDoc } from "../domain/Task"
import { MessageButtonDoc } from "../domain/MessageButton"
import { VitalSignDoc } from "../domain/VitalSigns"
import { Constants } from "../domain/Constants"
import {
  CarePlanActivityDoc,
  CarePlanActivityLibraryItem,
} from "../domain/CarePlan"
import { MedicalFileDoc } from "../domain/MedicalFile"
import { Diets } from "../domain/Diet"
import { AdministrativeFileDoc } from "../domain/AdministrativeFile"
import { Token } from "../domain/Token"
import { TreatmentDoc } from "../domain/Treatment"
import {
  PathologyLibrary,
  PathologyLibraryItem,
  VaccineLibrary,
} from "../domain/Medical"
import { OrganizationDoc } from "../domain/Organization"
import { Event, EventDoc } from "../domain/Event"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

export const app = initializeApp(firebaseConfig)

export const db = getFirestore()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const COLLECTIONS = {
  ASSIGNED_JOB: collection(
    db,
    "AssignedJob"
  ) as CollectionReference<AssignedJob>,
  FACILITY: collection(db, "Facility") as CollectionReference<Facility>,
  FACILITY_DOC: collection(db, "Facility") as CollectionReference<FacilityDoc>,
  CARE_PLAN: collection(
    db,
    "CarePlan"
  ) as CollectionReference<CarePlanActivityDoc>,
  MESSAGE: collection(db, "Message") as CollectionReference<Message>,
  RESIDENT: collection(db, "Resident") as CollectionReference<Resident>,
  RESIDENT_DOC: collection(db, "Resident") as CollectionReference<ResidentDoc>,
  USERS: collection(db, "Users").withConverter(
    converter<User>()
  ) as CollectionReference<User>,
  PROTOCOLS: collection(db, "Protocols") as CollectionReference<Protocol>,
  MDM_ROLES_DOC: collection(db, "Mdm_Roles") as CollectionReference<RoleDoc>,
  MDM_PROFILES_DOC: collection(
    db,
    "Mdm_Profiles"
  ) as CollectionReference<ProfileDoc>,
  MDM_CONTRACTS_DOC: collection(
    db,
    "Mdm_Contracts"
  ) as CollectionReference<ContractDoc>,
  MDM_TASKS_DOC: collection(db, "Mdm_Tasks") as CollectionReference<TaskDoc>,
  MDM_BUTTONS: collection(
    db,
    "Mdm_Buttons"
  ) as CollectionReference<MessageButtonDoc>,
  MDM_VITAL_SIGNS: collection(
    db,
    "Mdm_VitalSigns"
  ) as CollectionReference<VitalSignDoc>,
  MDM_CARES: collection(
    db,
    "Mdm_Cares"
  ) as CollectionReference<CarePlanActivityLibraryItem>,
  MEDICAL_FILE: collection(
    db,
    "MedicalFile"
  ) as CollectionReference<MedicalFileDoc>,
  ADMINISTRATIVE_FILE: collection(
    db,
    "AdministrativeFile"
  ) as CollectionReference<AdministrativeFileDoc>,
  TOKEN: collection(db, "Token") as CollectionReference<Token>,
  TREATMENT: collection(db, "Treatment") as CollectionReference<TreatmentDoc>,
  ORGANIZATIONS: collection(
    db,
    "Organizations"
  ) as CollectionReference<OrganizationDoc>,
  EVENTS: collection(db, "Events") as CollectionReference<EventDoc>,
}
export const FACILITY_COLLECTIONS = {
  TIMESHEET: (facilityId: string) =>
    collection(COLLECTIONS.FACILITY, facilityId, "Timesheet").withConverter(
      converter<TimesheetDoc>()
    ),
}

export const getAllFacilities = () => {
  return getDocs<FacilityDoc>(
    query<FacilityDoc>(COLLECTIONS.FACILITY_DOC).withConverter(
      converter<FacilityDoc>()
    )
  )
}

export const getAllRoles = () => {
  return getDocs<RoleDoc>(
    query<RoleDoc>(COLLECTIONS.MDM_ROLES_DOC).withConverter(
      converter<RoleDoc>()
    )
  )
}

export const getAllProfiles = () => {
  return getDocs<ProfileDoc>(
    query<ProfileDoc>(COLLECTIONS.MDM_PROFILES_DOC).withConverter(
      converter<ProfileDoc>()
    )
  )
}

export const getAllContracts = () => {
  return getDocs<ContractDoc>(
    query<ContractDoc>(COLLECTIONS.MDM_CONTRACTS_DOC).withConverter(
      converter<ContractDoc>()
    )
  )
}

export const getAllTasks = () => {
  return getDocs<TaskDoc>(
    query<TaskDoc>(COLLECTIONS.MDM_TASKS_DOC).withConverter(
      converter<TaskDoc>()
    )
  )
}

export const getAllMessageButtons = () => {
  return getDocs<MessageButtonDoc>(
    query<MessageButtonDoc>(COLLECTIONS.MDM_BUTTONS).withConverter(
      converter<MessageButtonDoc>()
    )
  )
}

export const getAllVitalSigns = () => {
  return getDocs<VitalSignDoc>(
    query<VitalSignDoc>(COLLECTIONS.MDM_VITAL_SIGNS).withConverter(
      converter<VitalSignDoc>()
    )
  )
}

export const getAllCares = () => {
  return getDocs<CarePlanActivityLibraryItem>(
    query<CarePlanActivityLibraryItem>(COLLECTIONS.MDM_CARES).withConverter(
      converter<CarePlanActivityLibraryItem>()
    )
  )
}

export const getOrganizationFacilities = (organizationId: string) => {
  return getDocs<FacilityDoc>(
    query<FacilityDoc>(
      COLLECTIONS.FACILITY_DOC,
      where("organizationId", "==", organizationId)
    ).withConverter(converter<FacilityDoc>())
  )
}

export const getAllOrganizations = () => {
  return getDocs<OrganizationDoc>(
    query<OrganizationDoc>(COLLECTIONS.ORGANIZATIONS).withConverter(
      converter<OrganizationDoc>()
    )
  )
}

export const removeFacility = (id: string) => {
  return remove(COLLECTIONS.FACILITY.path, id)
}

export const getResidentCarePlan = (residentId: string) => {
  return getDocs<CarePlanActivityDoc>(
    query<CarePlanActivityDoc>(
      COLLECTIONS.CARE_PLAN,
      where("residentId", "==", residentId)
    ).withConverter(converter<CarePlanActivityDoc>())
  )
}

export const getAllMedicalFiles = (facilityId: string) => {
  return getDocs<MedicalFileDoc>(
    query<MedicalFileDoc>(
      COLLECTIONS.MEDICAL_FILE,
      where("facilityId", "==", facilityId)
    ).withConverter(converter<MedicalFileDoc>())
  )
}

export const getAllTreatments = (residentId: string) => {
  return getDocs<TreatmentDoc>(
    query<TreatmentDoc>(
      COLLECTIONS.TREATMENT,
      where("residentId", "==", residentId)
    ).withConverter(converter<TreatmentDoc>())
  )
}

export const singleDoc = {
  facility: (id: string) => ({
    fetch: () => fetchDoc<FacilityDoc>("Facility", id),
    update: (data: UpdateData<FacilityDoc>) => update("Facility", id, data),
    remove: () => remove("Facility", id),
  }),
  message: (id: string) => ({
    fetch: () => fetchDoc<MessageDoc>("Message", id),
    update: (data: UpdateData<MessageDoc>) => update("Message", id, data),
  }),
  resident: (id: string) => ({
    update: (data: UpdateData<ResidentDoc>) => update("Resident", id, data),
    remove: () => remove("Resident", id),
  }),
  timesheet: (facilityId: string) => ({
    remove: (id: string) =>
      remove(FACILITY_COLLECTIONS.TIMESHEET(facilityId).path, id),
    create: (timesheet: TimesheetDoc) =>
      createDoc(FACILITY_COLLECTIONS.TIMESHEET(facilityId), timesheet),
  }),
  user: (id: string) => ({
    update: (data: UpdateData<UserDoc>) => update("Users", id, data),
    remove: () => remove("Users", id),
  }),
  role: (id: string) => ({
    fetch: () => fetchDoc<RoleDoc>("Mdm_Roles", id),
  }),
  constants: (id: string) => ({
    fetch: () => fetchDoc<Constants>("Constant", id),
  }),
  carePlan: (id: string) => ({
    update: (data: UpdateData<CarePlanActivityDoc>) =>
      update("CarePlan", id, data),
    remove: () => remove("CarePlan", id),
  }),
  medicalFile: (id: string) => ({
    fetch: () => fetchDoc<MedicalFileDoc>("MedicalFile", id),
    update: (data: UpdateData<MedicalFileDoc>) =>
      update("MedicalFile", id, data),
  }),
  administrativeFile: (id: string) => ({
    fetch: () => fetchDoc<AdministrativeFileDoc>("AdministrativeFile", id),
    update: (data: UpdateData<AdministrativeFileDoc>) =>
      update("AdministrativeFile", id, data),
  }),
  diets: (id: string) => ({
    fetch: () => fetchDoc<Diets>("Mdm_Diets", id),
  }),
  treatment: (id: string) => ({
    update: (data: UpdateData<TreatmentDoc>) => update("Treatment", id, data),
    remove: () => remove("Treatment", id),
  }),
  medical: <T>(id: string) => ({
    fetch: () => fetchDoc<T>("Mdm_Medical", id),
  }),
  organization: (id: string) => ({
    fetch: () => fetchDoc<OrganizationDoc>("Organizations", id),
    update: (data: UpdateData<OrganizationDoc>) =>
      update("Organizations", id, data),
    remove: () => remove("Organizations", id),
  }),
  event: (id: string) => ({
    update: (data: UpdateData<EventDoc>) => update("Events", id, data),
    remove: () => remove("Events", id),
  }),
  calendar: <T>(id: string) => ({
    fetch: () => fetchDoc<T>("Mdm_Calendar", id),
  }),
}

export const fetchDoc = <T>(path: string, id: string) =>
  getDoc<T>(doc(db, path, id).withConverter(converter<T>()))

export const update = <T>(path: string, id: string, data: UpdateData<T>) =>
  updateDoc<T>(doc(db, path, id).withConverter(converter<T>()), data)

export const createDoc = <T>(col: CollectionReference<T>, data: T) =>
  addDoc<T>(col, data)

export const remove = (path: string, id: string) => deleteDoc(doc(db, path, id))

export const auth = () => getAuth(app)

export const sendPasswordResetEmailTo = (email: string) =>
  sendPasswordResetEmail(auth(), email)

export const logout = () => {
  auth()
    .signOut()
    .then(() => console.log("Logout succeed"))
    .catch((e) => console.log("Logout failed", e))
}

export function converter<T extends {}>(): FirestoreDataConverter<T> {
  return {
    toFirestore: (user: T) => {
      return user
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot<T>, options) => {
      const data = snapshot.data(options)
      if (!data) {
        throw new Error(`No data for id: ${snapshot.id}`)
      }
      return { ...data, id: snapshot.id }
    },
  }
}

export const dayjsOf = (timestamp: Timestamp): Dayjs | undefined => {
  return timestamp && dayjs(timestamp.toMillis())
}

export const timestampOf = (date: { valueOf: () => number }): Timestamp => {
  return date && firebase.firestore.Timestamp.fromMillis(date.valueOf())
}

export type Timestamp = firebase.firestore.Timestamp

export const isDate = (date: Dayjs) => {
  return !Number.isNaN(date.toDate().getTime())
}
