import React, { useEffect, useState } from "react"
import "./login.less"
import { Button, Form, Input, Space, Typography } from "antd"
import { sendPasswordResetEmail } from "firebase/auth"
import { auth } from "../../core/firebase"
import { Link } from "react-router-dom"
import { I18 } from "../../core/i18n"

interface FormValues {
  email: string
}

export const ResetPasswordForm: React.FC = () => {
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [displayMessage, setDisplayMessage] = useState(false)

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({})
  }, [])

  const onFinish = (values: FormValues) => {
    setDisplayMessage(false)
    sendPasswordResetEmail(auth(), values.email)
      .then(() => setDisplayMessage(true))
      .catch((e) => {
        console.log("Send email error:", e)
        setDisplayMessage(true)
      })
  }

  return (
    <Space direction={"vertical"} className={"loginForm"} size={20}>
      <span>
        <b>{I18.t("loginPasswordForgotten")}</b>
      </span>
      <span>{I18.t("loginPasswordForgottenSentence")}</span>
      <Form
        form={form}
        name="resetPassword"
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, pattern: /(\S)+@(\S)+$/ }]}
          help={""}
        >
          <Input type={"email"} placeholder={I18.t("email")} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              className={"loginButton"}
              type="primary"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              {I18.t("validate")}
            </Button>
          )}
        </Form.Item>
        {displayMessage && (
          <Typography.Text type={"success"}>
            {I18.t("loginPasswordForgottenMessage")}
          </Typography.Text>
        )}
        <Form.Item>
          <Link className={"loginPasswordResetButton"} to="/login">
            {I18.t("back")}
          </Link>
        </Form.Item>
      </Form>
    </Space>
  )
}
