import React, { useState } from "react"
import { TableRow, TableCell, Box, Tooltip } from "@mui/material"
import { IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js"
import { DialogConfirm } from "../../../../components/DialogConfirm"
import { Vaccine } from "../../../../domain/MedicalFile"
import { dayjsOf } from "../../../../core/firebase"
import dayjs from "dayjs"

interface VaccineRowProps {
  vaccine: Vaccine
  index: number
  onEditClick: (index: number) => void
  onDeleteClick: (index: number) => void
}

export const VaccineRow: React.FC<VaccineRowProps> = ({
  vaccine,
  index,
  onEditClick,
  onDeleteClick,
}) => {
  const { name, vaccineDate, targetDate, comment } = vaccine
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const vdate =
    vaccineDate && `Le ${dayjsOf(vaccineDate)!.format("DD/MM/YYYY")}`
  const tdate = targetDate && `Le ${dayjsOf(targetDate)!.format("DD/MM/YYYY")}`
  const isOverdue = vaccineDate
    ? false
    : targetDate && dayjsOf(targetDate)!.isBefore(dayjs())

  return (
    <>
      <TableRow
        hover={true}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {name}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: isOverdue ? "#F44336" : "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {tdate}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {vdate}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {comment}
        </TableCell>

        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip title={"Editer"}>
              <div>
                <IconButton
                  path={mdiPencil}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      onEditClick(index)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Supprimer"}>
              <div>
                <IconButton
                  path={mdiTrashCanOutline}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsDeleteDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <DialogConfirm
        isDialogOpen={isDeleteDialogOpen}
        setIsDialogOpen={setIsDeleteDialogOpen}
        title={"Supprimer ce vaccin ?"}
        onConfirm={() => onDeleteClick(index)}
        cancelText="Annuler"
        confirmText="Supprimer"
      />
    </>
  )
}
