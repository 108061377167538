import { styled } from "@mui/material/styles"
import { DateField, DatePicker, DateTimePicker } from "@mui/x-date-pickers"

export const MuiDateField = styled(DateField)({
  "& .MuiInputLabel-root": {
    color: "#FFFFFF99",
    "&.Mui-focused": {
      color: "#FFFFFF99",
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFFFFFE6",
    "& fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF99",
    },
  },
})

export const MuiDatePicker = styled(DatePicker)({
  "& .MuiDateCalendar-root": {
    backgroundColor: "#FFFFFF11",
    color: "#FFFFFF",
  },
  "& .MuiIconButton-root": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: 14,
    "& fieldset": {
      color: "#FFFFFF",
      border: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF99",
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
  },
})

export const MuiDateTimePicker = styled(DateTimePicker)({
  "& .MuiIconButton-root": {
    color: "#FFFFFF",
  },
  "& .MuiInputLabel-root": {
    color: "#FFFFFF99",
    "&.Mui-focused": {
      color: "#FFFFFF99",
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFFFFFE6",
    "& fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF99",
    },
  },
})
