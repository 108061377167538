import React, { useState } from "react"
import { Page } from "../../components/page/Page"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import { MessageButtonsCRUD } from "./MessageButtonsCRUD"
import { TasksCRUD } from "./TasksCRUD"
import { JobsCRUD } from "./JobsCRUD"
import { UpsertFacility } from "./UpsertFacility"
import { useAuthUser } from "../../core/auth"
import { Box } from "@material-ui/core"
import { HeaderToolbar } from "./HeaderToolbar"
import { Organizations } from "./Organizations"

const TabPanel: React.FC<{
  activeTab: number
  index: number
  children: React.ReactNode
}> = ({ activeTab, index, children }) => {
  return (
    <Box hidden={activeTab !== index} sx={{}}>
      {children}
    </Box>
  )
}

export const Facility: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(3)
  usePageTitle(WebScreen.ADMIN_FACILITY)

  const { user } = useAuthUser()

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <HeaderToolbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <Page>
        {/* <TabPanel activeTab={activeTab} index={0}>
          <TasksCRUD />
        </TabPanel>
        <TabPanel activeTab={activeTab} index={1}>
          <MessageButtonsCRUD />
        </TabPanel>
        <TabPanel activeTab={activeTab} index={2}>
          <JobsCRUD />
        </TabPanel> */}
        {user?.isSuperAdmin && (
          <>
            <TabPanel activeTab={activeTab} index={3}>
              <UpsertFacility />
            </TabPanel>
            <TabPanel activeTab={activeTab} index={4}>
              <Organizations />
            </TabPanel>
          </>
        )}
      </Page>
    </Box>
  )
}
