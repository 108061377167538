import { ZoneId } from "../../domain/Zone"
import { Timesheet } from "../../domain/Timesheet"
import dayjs, { Dayjs } from "dayjs"
import { dayjsOf } from "../../core/firebase"
import { or0 } from "../../core/lang"
import { ProfileId } from "../../domain/Profile"

export type ProfilePresence = {
  total: number
  byZone: Record<ZoneId, number>
}
export type ProfilesPresence = Record<ProfileId, ProfilePresence>

export function profilesPresenceFromTimesheets(
  timesheets: Timesheet[],
  profiles: ProfileId[],
  now: Dayjs = dayjs()
): ProfilesPresence {
  const byProfile = timesheets
    .filter(
      (t) =>
        now.isAfter(dayjsOf(t.startDateTime)) &&
        now.isBefore(dayjsOf(t.stopDateTime))
    )
    .filter((t) => profiles.includes(t.profileId))
    .reduce((state, sheet) => {
      state[sheet.profileId] = mergeToProfile(state[sheet.profileId], sheet)
      return state
    }, initBuilders(profiles))

  return byProfile
}

function initBuilders(profiles: ProfileId[]): ProfilesPresence {
  return profiles.reduce((builders, profile) => {
    builders[profile] = emptyProfilesState()
    return builders
  }, {} as ProfilesPresence)
}

function emptyProfilesState(): ProfilePresence {
  return { total: 0, byZone: {} }
}

function mergeToProfile(
  state: ProfilePresence,
  sheet: Timesheet
): ProfilePresence {
  return {
    total: or0(state.total) + 1,
    byZone: {
      ...state.byZone,
      [sheet.currentZoneId]: or0(state.byZone[sheet.currentZoneId]) + 1,
    },
  }
}
