import { Alert, Upload } from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface"
import { uploadAvatar } from "../core/firebaseStorage"
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface"

interface Props {
  filenamePrefix: string
  avatarUrl?: string
  onChange?: (avatarUrl: string) => void
}

export const AvatarUploader: React.FC<Props> = ({
  avatarUrl,
  filenamePrefix,
  onChange,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>()

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  function customRequest({ file, onError, onSuccess }: RcCustomRequestOptions) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const a: any = file
    triggerUpload(a)
      .then((fileUrl) => {
        onChange && fileUrl && onChange(fileUrl)
      })
      .then(onSuccess)
      .catch(onError)

    return {
      abort() {
        console.log("upload progress is aborted.")
      },
    }
  }

  function beforeUpload(file: RcFile) {
    setMessage(undefined)
    const isJpeg = file.type === "image/jpeg"
    const isPng = file.type === "image/png"
    const isJpgOrPng = isJpeg || isPng
    if (!isJpgOrPng) {
      setMessage("You can only upload JPG/PNG file!")
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      setMessage("Image must smaller than 2MB!")
    }
    return isJpgOrPng && isLt2M
  }

  async function triggerUpload(uploadFile: RcFile) {
    if (!uploadFile) {
      return
    }
    const isJpeg = uploadFile.type === "image/jpeg"
    const filename = `${filenamePrefix}-${new Date().valueOf()}.${
      isJpeg ? "jpg" : "png"
    }`

    return uploadAvatar(filename, uploadFile)
  }

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "uploading") {
      setLoading(true)
    }
    if (info.file.status === "done" && info.file.originFileObj) {
      setLoading(false)
    }
  }

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
      >
        {avatarUrl && !loading ? (
          <img
            src={avatarUrl}
            alt="avatar"
            style={{ width: "100%" }}
            // style={{ position: "absolute", maxHeight: "90%", top: 0 }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {message && <Alert type={"error"} message={message} />}
    </>
  )
}
