import { mapValues } from "lodash"
import { Message, MessageDoc } from "../../domain/Message"
import { ZoneId } from "../../domain/Zone"
import { ProfilesPresence } from "./profilesPresenceFromTimesheets"
import { ProfileId } from "../../domain/Profile"

export type ZoneProfileAlerts = {
  people: number
  ratio: number
  warning: number
  danger: number
}
export type ZoneProfilesAlerts = Record<ProfileId, ZoneProfileAlerts>
export type ZonesProfilesAlerts = Record<ZoneId, ZoneProfilesAlerts>

export function zonesProfilesAlerts(
  messages: MessageDoc[],
  profiles: ProfileId[],
  presences: ProfilesPresence
): ZonesProfilesAlerts {
  const builderByZone = messages.reduce((byZone, msg) => {
    const zone = msg.zoneId
    if (!zone) {
      return byZone
    }
    byZone[zone] = byZone[zone] || new Builder(zone, presences, profiles)
    byZone[zone].addMessage(msg)
    return byZone
  }, {} as Record<ZoneId, Builder>)

  return mapValues(builderByZone, (builder) => builder.build())
}

type Levels = "danger" | "warning"

class Builder {
  readonly profilesMsgs: Map<ProfileId, Message[]>
  constructor(
    readonly zoneId: ZoneId,
    readonly presences: ProfilesPresence,
    profiles: ProfileId[]
  ) {
    this.profilesMsgs = new Map()
    profiles.forEach((p) => this.profilesMsgs.set(p, []))
  }

  addMessage(msg: Message): void {
    if (msg.alertLevel === "danger" || msg.alertLevel === "warning") {
      msg.profiles.forEach((profile) => {
        this.profilesMsgs.get(profile)?.push(msg)
      })
    }
  }

  build(): ZoneProfilesAlerts {
    const state: ZoneProfilesAlerts = {}

    this.profilesMsgs.forEach((messages, profile) => {
      const profileState = messages.reduce((state, msg) => {
        state[msg.alertLevel as Levels] = state[msg.alertLevel as Levels] + 1
        return state
      }, emptyState())

      const peopleOnZone = this.presences[profile].byZone[this.zoneId]

      if (peopleOnZone && peopleOnZone !== 0) {
        profileState.people = peopleOnZone
        profileState.ratio =
          (profileState.warning + profileState.danger * 2) / peopleOnZone
      }

      state[profile] = profileState
    })
    return state
  }
}

const emptyState = (): ZoneProfileAlerts => ({
  people: 0,
  ratio: 0,
  warning: 0,
  danger: 0,
})
