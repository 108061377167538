import React from "react"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { Box } from "@mui/material"
import { Resident } from "../../../domain/Resident"
import { ResidentInfo } from "../../residents/components/ResidentInfo"
import { useWorkContext } from "../../../contexts/work.context"
import { Icon, IconButton } from "../../../components/Icon"
import { useNavigate } from "react-router-dom"
import { Tab, Tabs } from "@mui/material"
import {
  mdiChevronLeft,
  mdiDoctor,
  mdiInformationOutline,
  mdiMedicalBag,
  mdiCalendar,
} from "@mdi/js"

interface Props {
  activeTab: number
  setActiveTab: (tab: number) => void
  resident?: Resident
}

export const HeaderToolbar: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  resident,
}) => {
  const { facilities } = useWorkContext()
  const navigate = useNavigate()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }
  return (
    <HeaderNew>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
          }}
        >
          <IconButton
            path={mdiChevronLeft}
            color={"#FFFFFF8F"}
            buttonProps={{
              onClick: () => navigate(-1),
            }}
          />
          {resident && (
            <ResidentInfo resident={resident} facilities={facilities} />
          )}
        </Box>
        <Box
          sx={{
            height: 60,
            marginRight: 5,
          }}
        >
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab
              icon={<Icon path={mdiInformationOutline} />}
              label="Administratif"
              iconPosition="start"
              value={0}
            />
            <Tab
              icon={<Icon path={mdiDoctor} />}
              label="Dossier Médical"
              iconPosition="start"
              value={1}
            />
            <Tab
              icon={<Icon path={mdiMedicalBag} />}
              label="Plan de soin"
              iconPosition="start"
              value={2}
            />
            <Tab
              icon={<Icon path={mdiCalendar} />}
              label="Planning"
              iconPosition="start"
              value={3}
            />
          </Tabs>
        </Box>
      </Box>
    </HeaderNew>
  )
}
