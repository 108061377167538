import React from "react"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { Box } from "@mui/material"
import { DocImport } from "./DocImport"
import { ResidentDoc } from "../../../../domain/Resident"
import { useWorkContext } from "../../../../contexts/work.context"

interface IdDocsProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
  resident: ResidentDoc
}

export const IdDocs: React.FC<IdDocsProps> = ({
  adminFile,
  setAdminFile,
  resident,
}) => {
  const { facility, facilities } = useWorkContext()
  const residentName = `${resident.lastname}-${resident.firstname}`
  const facilityName = facilities
    ? facilities[resident.facilityId].name
    : facility?.name
  const prefix = `${facilityName}-${residentName}`

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 8,
        flexWrap: "wrap",
      }}
    >
      <DocImport
        adminFile={adminFile}
        filenamePrefix={prefix}
        label={"Carte vitale"}
        id={"socialSecurityCard"}
        setAdminFile={setAdminFile}
      />
      <DocImport
        adminFile={adminFile}
        filenamePrefix={prefix}
        label={"Carte d'identité"}
        id={"idDocument"}
        setAdminFile={setAdminFile}
      />
      <DocImport
        adminFile={adminFile}
        filenamePrefix={prefix}
        label={"Carte mutuelle"}
        id={"mutualCard"}
        setAdminFile={setAdminFile}
      />
      <DocImport
        adminFile={adminFile}
        filenamePrefix={prefix}
        label={"Autre document"}
        id={"otherDoc"}
        setAdminFile={setAdminFile}
      />
    </Box>
  )
}
