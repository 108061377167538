import React from "react"
import { TableCell, TableCellProps } from "@mui/material"

export const Cell: React.FC<{
  children: React.ReactNode
  props?: TableCellProps
}> = ({ children, props }) => {
  return (
    <TableCell
      {...props}
      sx={{
        color: "#FFFFFF",
        fontSize: 14,
        fontWeight: 500,
        borderBottom: "1px solid #FFFFFF1F",
      }}
    >
      {children}
    </TableCell>
  )
}
