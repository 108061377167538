import { Select } from "antd"
import React from "react"
import { useWorkContext } from "../../contexts/work.context"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectRole: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  formControlProps,
}) => {
  const { roleLabelById } = useWorkContext()
  const rolesSelectOptions = Object.entries(roleLabelById).map(
    ([id, name]) => ({ label: name, value: id })
  )

  return (
    <CustomSelect
      label="Rôle"
      value={value}
      options={rolesSelectOptions}
      allOptionsLabel="Sélectionnez un rôle"
      onChange={onChange}
      restricted={false}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
    // <Select
    //   style={{ minWidth: "10rem" }}
    //   value={value}
    //   onChange={onChange}
    //   options={rolesSelectOptions}
    // />
  )
}
