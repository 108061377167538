import React, { useState } from "react"
import { Box, CardActionArea, Typography } from "@mui/material"
import { Icon } from "../../../../components/Icon"
import { mdiPencil, mdiUpload } from "@mdi/js"
import { FileUploader } from "../../../../components/FileUploader"
import { singleDoc } from "../../../../core/firebase"
import {
  AdministrativeFileDoc,
  idFilesNames,
} from "../../../../domain/AdministrativeFile"
import { getFileMetadata, getFileRef } from "../../../../core/firebaseStorage"
import { getDownloadURL, ref } from "firebase/storage"

interface DocImportProps {
  adminFile: AdministrativeFileDoc
  filenamePrefix: string
  id: idFilesNames
  label: string
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export const DocImport: React.FC<DocImportProps> = ({
  adminFile,
  filenamePrefix,
  id,
  label,
  setAdminFile,
}) => {
  const [isImage, setIsImage] = useState(false)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)
  const idFiles = adminFile.idFiles
  const filePath = idFiles ? idFiles[id] : undefined
  const fileRef = filePath && getFileRef(filePath)
  console.log("filePath", filePath)

  fileRef &&
    getFileMetadata(fileRef).then((metadata) => {
      console.log("metadata", metadata)
      setIsImage(
        (metadata &&
          metadata.contentType &&
          metadata?.contentType.includes("image/jpeg")) ||
          metadata?.contentType?.includes("image/png") ||
          false
      )
    })
  fileRef && getDownloadURL(fileRef).then((url) => setFileUrl(url))

  const handleChange = (fileUrl: string) => {
    singleDoc.administrativeFile(adminFile.id).update({
      [`idFiles.${id}`]: fileUrl,
    })
    setAdminFile({
      ...adminFile,
      idFiles: {
        ...adminFile.idFiles!,
        [id]: fileUrl,
      },
    })
  }
  return (
    <>
      {fileRef ? (
        <Box sx={{ display: "flex", flexDirection: "row", marginY: 4 }}>
          <Box sx={{ height: 160 }}>
            <CardActionArea
              href={fileUrl || ""}
              target="_blank"
              sx={{
                backgroundColor: "#FFFFFF33",
                display: "flex",
                height: "100%",
                cursor: "pointer",
              }}
            >
              {isImage ? (
                <img src={fileUrl} alt="document" style={{ height: "100%" }} />
              ) : (
                <Box
                  sx={{
                    minWidth: 200,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                    }}
                  >
                    {`Ouvrir ${label}`}
                  </Typography>
                </Box>
              )}
            </CardActionArea>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-start",
              alignContent: "start",
              padding: 1,
            }}
          >
            <FileUploader
              filenamePrefix={`${id}-${filenamePrefix}`}
              returnedOption="path"
              onChange={(fileUrl) => {
                handleChange(fileUrl)
              }}
            >
              <Icon path={mdiPencil} color={"#FFFFFF8F"} />
            </FileUploader>
          </Box>
        </Box>
      ) : (
        <FileUploader
          filenamePrefix={`${id}-${filenamePrefix}`}
          returnedOption="path"
          onChange={(fileUrl) => {
            handleChange(fileUrl)
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: 200,
              height: 160,
              backgroundColor: "#FFFFFF33",
              borderRadius: 4,
              borderStyle: "dashed",
              borderColor: "#FFFFFFB2",
              cursor: "pointer",
            }}
          >
            <Icon path={mdiUpload} color={"#FFFFFF"} size={2} />
            <Typography sx={{ color: "#FFFFFF", marginTop: 2 }}>
              {label}
            </Typography>
          </Box>
        </FileUploader>
      )}
    </>
  )
}
