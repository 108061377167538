import React, { useEffect, useState } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { singleDoc } from "../../../../core/firebase"
import { Icon, IconButton } from "../../../../components/Icon"
import { mdiWeatherNight, mdiWhiteBalanceSunny, mdiWindowClose } from "@mdi/js"
import {
  Acquaintance,
  AdministrativeFileDoc,
} from "../../../../domain/AdministrativeFile"
import { ModalConfig } from "./Acquaintances"
import { SelectTies } from "../../../../components/select/SelectTies"
import { arrayUnion } from "firebase/firestore"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface AcquaintanceModalProps {
  adminFile: AdministrativeFileDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (adminFile: AdministrativeFileDoc, config: ModalConfig) => void
}

export const defaultValues: Acquaintance = {
  name: "",
  ties: "Fils",
  phoneNumber: "",
  dayContact: false,
  nightContact: false,
}

export const AcquaintanceModal: React.FC<AcquaintanceModalProps> = ({
  adminFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset } = useForm<Acquaintance>({
    defaultValues: config.acquaintance || defaultValues,
  })

  useEffect(() => {
    reset(config.acquaintance || defaultValues)
  }, [config.acquaintance, reset])

  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)

  const createOrUpdate = (
    acquaintance: Acquaintance
  ): Promise<AdministrativeFileDoc> => {
    if (config.isUpdate && config.acquaintance) {
      const updatedAcquaintances = adminFile.acquaintances.map((a, i) =>
        i === config.index ? acquaintance : a
      )
      return singleDoc
        .administrativeFile(adminFile.id)
        .update({
          acquaintances: updatedAcquaintances,
        })
        .then(() => ({
          ...adminFile,
          acquaintances: updatedAcquaintances,
        }))
    } else {
      return singleDoc
        .administrativeFile(adminFile.id)
        .update({
          acquaintances: arrayUnion(acquaintance),
        })
        .then(() => ({
          ...adminFile,
          acquaintances: [...adminFile.acquaintances, acquaintance],
        }))
    }
  }

  const onFormSubmit: SubmitHandler<Acquaintance> = (
    acquaintance: Acquaintance
  ) => {
    setIsConfirmationLoading(true)
    createOrUpdate(acquaintance)
      .then((adminFile) => {
        onSubmit(adminFile, config)
        setIsConfirmationLoading(false)
        handleClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(config.acquaintance || defaultValues)
    onClose()
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="xs"
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {config.isUpdate
                  ? "Editer personne à contacter"
                  : "Personne à contacter"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => handleClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Nom Prénom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="ties"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectTies
                        {...field}
                        selectProps={{ style: { width: 396 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="phoneNumber"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Numéro de téléphone"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Icon path={mdiWhiteBalanceSunny} color={"#FFFFFF"} />
                    <Typography
                      sx={{
                        color: "#FFFFFFB2",
                        marginLeft: 4,
                        marginRight: 6,
                      }}
                    >
                      A prévenir de jour
                    </Typography>
                    <Controller
                      name="dayContact"
                      defaultValue={false}
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <Switch {...field} checked={value} value={value} />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Icon path={mdiWeatherNight} color={"#FFFFFF"} />
                    <Typography
                      sx={{
                        color: "#FFFFFFB2",
                        marginLeft: 4,
                        marginRight: 6,
                      }}
                    >
                      A prévenir de nuit
                    </Typography>
                    <Controller
                      name="nightContact"
                      defaultValue={false}
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <Switch {...field} checked={value} value={value} />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
