import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: "#90CAF9",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "0 16px",
          color: "#FFFFFFB2",
          "&.Mui-selected": {
            color: "#90CAF9",
          },
          "&:hover": {
            color: "#90CAF9",
          },
        },
      },
    },
  },
})
