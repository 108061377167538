import React from "react"
import { bem, classes } from "../../../styles/bem"
import { ZoneProfileAlerts } from "../zonesProfilesAlerts"
import { Panel } from "../../../components/panel/Panel"
import { ProfileAlerts } from "../profilesAlerts"
import "./zoneProfile.less"
import { Icon } from "../../../components/Icon"
import { mdiAccountMultiple } from "@mdi/js"
import { ProfileDoc, ProfileId, Profiles } from "../../../domain/Profile"
import { I18 } from "../../../core/i18n"
import { IskusSpinner } from "../../../components/IskusSpinner"

const b = "zoneProfile"

export interface Props {
  className: string
  profile: ProfileId
  profiles: ProfileDoc[]
  // profiles: Profiles
  profileAlerts?: ProfileAlerts
  zoneState?: ZoneProfileAlerts
}

type Status = "normal" | "help" | "availability"

function getStatus(
  zoneState: ZoneProfileAlerts,
  profileAlerts: ProfileAlerts
): Status {
  if (zoneState.people < 1) {
    return "help"
  }
  if (zoneState.ratio > profileAlerts.ratio * 1.25) {
    return "help"
  }
  if (zoneState.ratio < profileAlerts.ratio * 0.75) {
    return "availability"
  }
  return "normal"
}

export const ZoneProfile: React.FC<Props> = ({
  className,
  profile,
  profiles,
  profileAlerts,
  zoneState,
}) => {
  if (!zoneState || !profileAlerts) {
    return <IskusSpinner />
  }

  const status = getStatus(zoneState, profileAlerts)

  return (
    <div className={classes(b, className)}>
      <div className={bem(b, "content")}>
        <div className={bem(b, "contentProfile")}>
          <span>{profiles.filter((p) => p.id === profile)[0].fullName}</span>
          <span className={bem(b, "contentProfilePeople")}>
            {zoneState.people}
            <Icon path={mdiAccountMultiple} />
          </span>
        </div>
        <div className={classes(bem(b, "contentAlerts"))}>
          <div
            className={bem(b, "contentAlert", {
              warning: zoneState.warning > 0,
              empty: zoneState.warning === 0,
            })}
          >
            {zoneState.warning}
          </div>
          <div
            className={bem(b, "contentAlert", {
              danger: zoneState.danger > 0,
              empty: zoneState.danger === 0,
            })}
          >
            {zoneState.danger}
          </div>
        </div>
      </div>
      <div className={classes(bem(b, "status", { [status]: true }))}>
        {I18.t(`organisationStatus_${status}`)}
      </div>
    </div>
  )
}
