import React, { useMemo } from "react"
import { useWorkContext } from "../../../contexts/work.context"
import { debounce } from "lodash"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { CustomTextSearch } from "../../../components/CustomTextSearch"
import { SelectZone } from "../../../components/select/SelectZone"
import { SelectFacility } from "../../../components/select/SelectFacility"
import { IconButton } from "../../../components/Icon"
import { mdiPlus } from "@mdi/js"
import { Box } from "@mui/material"

export interface Filters {
  facility?: string
  zone?: string
}

interface Props {
  filters: Filters
  onFiltersChange: (filters: Filters) => void
  onSearchTextChange: (search: string) => void
  onCreate: () => void
}

export const HeaderToolbar: React.FC<Props> = ({
  filters,
  onFiltersChange,
  onCreate,
  onSearchTextChange,
}) => {
  const { facility, facilities } = useWorkContext()
  const debouncedSetSearchText = useMemo(
    () => debounce(onSearchTextChange, 500),
    [onSearchTextChange]
  )

  if (!facility) {
    return null
  }

  return (
    <HeaderNew title="Résidents">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <CustomTextSearch
            placeholder={"Recherche par nom, chambre..."}
            onChange={(text) => debouncedSetSearchText(text)}
            minWidth={280}
            textFieldProps={{ size: "small" }}
          />
          <SelectZone
            facility={
              (facilities &&
                filters.facility &&
                facilities[filters.facility]) ||
              facility
            }
            value={filters.zone}
            selectProps={{
              size: "small",
            }}
            onChange={(zone) =>
              onFiltersChange({
                ...filters,
                zone: zone,
              })
            }
          />
          <SelectFacility
            value={filters.facility}
            selectProps={{ size: "small" }}
            onChange={(facility) =>
              onFiltersChange({
                ...filters,
                facility: facility,
              })
            }
          />
        </Box>
        <Box sx={{ marginLeft: 3 }}>
          <IconButton
            path={mdiPlus}
            buttonProps={{
              onClick: onCreate,
              style: { backgroundColor: "#FFFFFF" },
            }}
            color={"#000000"}
          />
        </Box>
      </Box>
    </HeaderNew>
  )
}
