import React, { useEffect, useState } from "react"
import { ResidentDoc } from "../../../../domain/Resident"
import { COLLECTIONS, createDoc, singleDoc } from "../../../../core/firebase"
import { Box, Button } from "@mui/material"
import { mdiArchiveArrowUp, mdiPlus } from "@mdi/js"
import { SubHeader } from "../../../../components/SubHeader"
import { Icon, IconButton } from "../../../../components/Icon"
import {
  AdministrativeFile,
  AdministrativeFileDoc,
} from "../../../../domain/AdministrativeFile"
import { omitNils } from "../../../../core/lang"
import { Identity } from "./Identity"
import { HealthCare } from "./HealthCare"
import { IdDocs } from "./IdDocs"
import { ResidentAvatar } from "./ResidentAvatar"
import { Acquaintances } from "./Acquaintances"
import { Legal } from "./Legal"
import { DialogConfirm } from "../../../../components/DialogConfirm"
import { About } from "./About"

interface AdminFileProps {
  resident: ResidentDoc
}

export const AdminFile: React.FC<AdminFileProps> = ({ resident }) => {
  const [adminFile, setAdminFile] = useState<AdministrativeFileDoc>()
  const [dialogOpen, setDialogOpen] = useState(false)

  const archiveResident = () => {
    singleDoc.resident(resident.id).update({ ...resident, isArchived: true })
  }

  const createAdministrativeFile = (adminFile: AdministrativeFile) => {
    const full: AdministrativeFile = omitNils({
      ...adminFile,
    })
    return createDoc(COLLECTIONS.ADMINISTRATIVE_FILE, full).then((ref) => ({
      ...full,
      id: ref.id,
    }))
  }

  const handleCreateAdminFile = () => {
    console.log("create administrative file")
    const adminFile: AdministrativeFile = {
      residentId: resident.id,
      facilityId: resident.facilityId,
      organizationId: resident.organizationId,
      identity: {
        firstname: resident.firstname,
        lastname: resident.lastname,
        zoneId: resident.zoneId,
        room: resident.room,
      },
      healthCare: {},
      acquaintances: [],
      legalProtections: [],
      consents: [],
    }
    createAdministrativeFile(adminFile).then((adminFile) => {
      singleDoc
        .resident(resident.id)
        .update({ administrativeFileId: adminFile.id })
        .then(() => {
          setAdminFile(adminFile)
        })
    })
  }

  useEffect(() => {
    if (resident.administrativeFileId) {
      singleDoc
        .administrativeFile(resident.administrativeFileId)
        .fetch()
        .then((admFile) => {
          if (admFile) {
            setAdminFile(admFile.data())
          } else {
            console.log("no administrative file")
          }
        })
    } else {
      setAdminFile(undefined)
    }
  }, [resident])

  return (
    <Box sx={{ marginTop: 0, marginBottom: 5 }}>
      {adminFile ? (
        <>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
            }}
          >
            <IconButton
              path={mdiArchiveArrowUp}
              color={"#FFFFFF"}
              size={1.5}
              buttonProps={{
                style: {
                  position: "absolute",
                  border: 0,
                  right: 50,
                },
                onClick: () => {
                  setDialogOpen(true)
                },
              }}
            />
          </Box>
          <SubHeader text={"Etat civil"} />
          <Box sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
            <Box sx={{ flex: 1 }}>
              <Identity adminFile={adminFile} setAdminFile={setAdminFile} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <HealthCare adminFile={adminFile} setAdminFile={setAdminFile} />
              <ResidentAvatar
                resident={resident}
                adminFile={adminFile}
                setAdminFile={setAdminFile}
              />
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <About adminFile={adminFile} setAdminFile={setAdminFile} />
          </Box>
          <SubHeader text={"Documents d'identité"} />
          <IdDocs
            adminFile={adminFile}
            setAdminFile={setAdminFile}
            resident={resident}
          />
          <SubHeader text={"Entourage"} />
          <Acquaintances adminFile={adminFile} setAdminFile={setAdminFile} />
          <SubHeader text={"Juridique"} />
          <Legal adminFile={adminFile} setAdminFile={setAdminFile} />
        </>
      ) : (
        <Button
          variant="contained"
          startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
          style={{ marginTop: 50 }}
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#001C41",
            fontWeight: 500,
            fontSize: 14,
            textTransform: "capitalize",
          }}
          onClick={() => {
            handleCreateAdminFile()
          }}
        >
          Créer dossier administratif
        </Button>
      )}
      <DialogConfirm
        isDialogOpen={dialogOpen}
        setIsDialogOpen={() => {
          setDialogOpen(false)
        }}
        onConfirm={() => {
          archiveResident()
        }}
        cancelText="Annuler"
        title={"Souhaitez vous vraiment archiver ce résident ?"}
      />
    </Box>
  )
}
