import { Document } from "./Document"
// export type Screen =
//   | "home"
//   | "dashboard-list"
//   | "dashboard-kpi"
//   | "planning"
//   | "admin-users"
//   | "admin-residents"
//   | "admin-facility"
export enum WebScreen {
  CONFIDENTIALITY = "confidentiality",
  ADMIN_FACILITY = "admin-facility",
  ADMIN_RESIDENTS = "admin-residents",
  ADMIN_RESIDENT = "admin-resident",
  ADMIN_USERS = "admin-users",
  DASHBOARD_LIST = "dashboard-list",
  DASHBOARD_KPI = "dashboard-kpi",
  HOME = "home",
  LOGIN = "login",
  LOGIN_RESET_PWD = "login-reset-pwd",
  PLANNING = "planning",
  PROTOCOLS = "protocols",
  KITCHEN = "kitchen",
}

export interface Role {
  id: string
  name: string
  isRestrictedAccess: boolean
  screens: WebScreen[]
}

export type RoleDoc = Document & Role
