import React, { useState } from "react"
import { Box, Table, TableBody, Typography } from "@mui/material"
import { ButtonAddIcon, Icon } from "../../../../components/Icon"
import { mdiAccountSupervisor } from "@mdi/js"
import { Center } from "../../../../components/Center"
import {
  Acquaintance,
  AdministrativeFileDoc,
} from "../../../../domain/AdministrativeFile"
import { AcquaintanceModal } from "./AcquaintanceModal"
import { AcquaintanceHeader } from "./AcquaintanceHeader"
import { AcquaintanceRow } from "./AcquaintanceRow"
import { singleDoc } from "../../../../core/firebase"
import { arrayRemove } from "firebase/firestore"
import { DialogConfirm } from "../../../../components/DialogConfirm"

interface AcquaintancesProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export interface ModalConfig {
  isOpen: boolean
  isUpdate?: boolean
  acquaintance?: Acquaintance
  index?: number
}

export const Acquaintances: React.FC<AcquaintancesProps> = ({
  adminFile,
  setAdminFile,
}) => {
  const [isMaxContactDialogOpen, setIsMaxContactDialogOpen] = useState(false)
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })
  const acquaintances = adminFile.acquaintances

  const onModalSubmit = (
    adminFile: AdministrativeFileDoc,
    config: ModalConfig
  ): void => {
    setAdminFile(adminFile)
    setModalConfig(config)
  }

  const onAddClickHandler = () => {
    if (adminFile.acquaintances.length < 3) {
      setModalConfig({ isOpen: true })
    } else {
      setIsMaxContactDialogOpen(true)
    }
  }

  const onEditClickHandler = (index: number): void => {
    setModalConfig({
      isOpen: true,
      isUpdate: true,
      index: index,
      acquaintance: acquaintances[index],
    })
  }

  const onDeleteClickHandler = (index: number): void => {
    singleDoc
      .administrativeFile(adminFile.id)
      .update({
        acquaintances: arrayRemove(adminFile.acquaintances[index]),
      })
      .then(() => {
        setAdminFile({
          ...adminFile,
          acquaintances: adminFile.acquaintances.filter(
            (acquaintance, i) => i !== index
          ),
        })
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiAccountSupervisor} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"Personnes à contacter"}
          </Typography>
        </Box>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <ButtonAddIcon buttonProps={{ onClick: () => onAddClickHandler() }} />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        <Table size="small" sx={{ minWidth: 400 }} aria-label="simple table">
          <AcquaintanceHeader />
          <TableBody>
            {acquaintances?.map((acquaintance, index) => (
              <AcquaintanceRow
                key={acquaintance.name}
                index={index}
                onEditClick={(index) => onEditClickHandler(index)}
                acquaintance={acquaintance}
                onDeleteClick={(index) => onDeleteClickHandler(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <AcquaintanceModal
        adminFile={adminFile}
        config={modalConfig}
        onClose={() => setModalConfig({ isOpen: false })}
        onSubmit={onModalSubmit}
      />
      <DialogConfirm
        isDialogOpen={isMaxContactDialogOpen}
        setIsDialogOpen={setIsMaxContactDialogOpen}
        title={
          "Le maximum de contacts est atteint, supprimez un contact pour en ajouter un nouveau."
        }
        onConfirm={() => setIsMaxContactDialogOpen(false)}
        cancelText="Annuler"
        confirmText="Ok"
      />
    </>
  )
}
