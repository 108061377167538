const CLOUD_FUNCTION_CREATE_USER =
  process.env.REACT_APP_CLOUD_FUNCTION_CREATE_USER || ""
// "https://us-central1-iskus-qa.cloudfunctions.net/createUser"
// "http://localhost:5001/iskus-qa/us-central1/createUser"

export default function createUser(email: string, password: string) {
  return fetch(CLOUD_FUNCTION_CREATE_USER, {
    body: JSON.stringify({ email, password }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  }).catch((error) => console.log("error", error))
}
