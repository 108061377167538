import React, { useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiButton } from "../../../components/Button"
import { IconButton } from "../../../components/Icon"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { MuiTextField } from "../../../components/TextField"
import { mdiWindowClose } from "@mdi/js"
import { COLLECTIONS, createDoc, singleDoc } from "../../../core/firebase"
import { IskusSpinner } from "../../../components/IskusSpinner"
import { OrganizationDoc } from "../../../domain/Organization"

type FormValues = {
  name: string
}

export interface ModalConfig {
  isOpen: boolean
  organization?: OrganizationDoc
  isUpdate?: boolean
}

interface OrganizationModalProps {
  config: ModalConfig
  onClose: (newOrganization?: OrganizationDoc) => void
}

export const defaultValues: FormValues = {
  name: "",
}

export const OrganizationModal: React.FC<OrganizationModalProps> = ({
  config,
  onClose,
}) => {
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: config.isUpdate ? config.organization : defaultValues,
  })

  const createOrUpdate = (data: FormValues) => {
    setIsConfirmationLoading(true)

    if (config.isUpdate && config.organization) {
      singleDoc
        .organization(config.organization.id)
        .update(data)
        .then(() => {
          onClose({ ...data, id: config.organization!.id })
        })
        .catch((e) => console.log("error :", e))
    } else {
      createDoc(COLLECTIONS.ORGANIZATIONS, data)
        .then((docs) => {
          onClose({ ...data, id: docs.id })
        })
        .catch((e) => {
          console.log("error", e)
          alert(`Erreur : ${e.message}`)
        })
        .finally(() => {
          setIsConfirmationLoading(false)
          onClose()
        })
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    console.log("data", data)
    createOrUpdate(data)
  }

  return (
    <>
      {isConfirmationLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          open={config.isOpen}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {config.isUpdate
                  ? "Editer une organisation"
                  : "Nouvelle organisation"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => onClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Nom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
