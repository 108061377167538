import React from "react"
import { Cell } from "../../../../components/table/TableCell"
import { TableHead, TableRow } from "@mui/material"

export const ProtectionHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <Cell>Libellé de la protection</Cell>
        <Cell>Statut</Cell>
        <Cell props={{ align: "center" }}>Document</Cell>
        <Cell>Date de mise à jour</Cell>
        <Cell props={{ align: "center" }}>Mise à jour</Cell>
        <Cell props={{ align: "center" }}>Edition</Cell>
      </TableRow>
    </TableHead>
  )
}
