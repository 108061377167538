import React, { useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiTextField } from "../../../components/TextField"
import { UserDoc } from "../../../domain/User"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../components/Button"
import { HexColorPicker } from "react-colorful"
import { AvatarUploader } from "../../../components/AvatarUploader"
import { useWorkContext } from "../../../contexts/work.context"
import { COLLECTIONS, createDoc, singleDoc } from "../../../core/firebase"
import { omitNils } from "../../../core/lang"
import { IconButton } from "../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { IskusSpinner } from "../../../components/IskusSpinner"
import {
  Resident,
  ResidentDoc,
  checkRoomAvailability,
} from "../../../domain/Resident"
import { SelectZone } from "../../../components/select/SelectZone"
import { DialogConfirm } from "../../../components/DialogConfirm"
import { SelectFacility } from "../../../components/select/SelectFacility"

type FormValues = {
  lastname: string
  firstname: string
  zoneId: string
  room: number
  color: string
  avatarUrl: string
  facilityId: string
}

export interface ModalConfig {
  isOpen: boolean
  isUpdate?: boolean
}

interface ResidentModalProps {
  residents: ResidentDoc[]
  admin: UserDoc
  config: ModalConfig
  onClose: () => void
}

export const defaultValues: FormValues = {
  lastname: "",
  firstname: "",
  zoneId: "",
  room: 0,
  color: "#841818",
  avatarUrl: "",
  facilityId: "",
}

export const ResidentModal: React.FC<ResidentModalProps> = ({
  residents,
  admin,
  config,
  onClose,
}) => {
  const { facility, facilities } = useWorkContext()
  const { control, handleSubmit, getValues, watch } = useForm<Resident>({
    defaultValues: { ...defaultValues, facilityId: admin.facilityId },
  })
  const watchFacilityId = watch("facilityId")
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const [dialogConfig, setDialogConfig] = useState<{
    isOpen: boolean
    message: string
    values?: FormValues
    roomTakenBy?: ResidentDoc | undefined
  }>({
    isOpen: false,
    message: "",
  })

  const createOrUpdate = (
    values: FormValues,
    roomTakenBy: ResidentDoc | undefined
  ): Promise<ResidentDoc> => {
    const full: Resident = omitNils({
      ...values,
      constants: {},
      alerts: {},
      isArchived: false,
      // facilityId: admin.facilityId,
      organizationId: admin.organizationId,
      initials: `${values.lastname.charAt(0).toUpperCase()}${values.firstname
        .charAt(0)
        .toUpperCase()}`,
    })
    if (roomTakenBy) {
      singleDoc.resident(roomTakenBy.id).update({
        ...roomTakenBy,
        isArchived: true,
      })
    }
    return createDoc(COLLECTIONS.RESIDENT, full).then((ref) => ({
      ...full,
      id: ref.id,
    }))
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    console.log("Values", values)
    // Testing if room is already taken
    const roomTakenBy: ResidentDoc | undefined =
      residents &&
      checkRoomAvailability(admin.facilityId, values.room, residents)
    if (roomTakenBy) {
      console.log("Room already taken by", roomTakenBy)
      setDialogConfig({
        isOpen: true,
        message: `La chambre ${values.room} est déjà occupée par ${roomTakenBy.firstname} ${roomTakenBy.lastname}. Ce résident va être archivé. Voulez-vous continuer ?`,
        values: values,
        roomTakenBy: roomTakenBy,
      })
    } else {
      setIsConfirmationLoading(true)
      createOrUpdate(values, roomTakenBy)
        .then(() => {
          setIsConfirmationLoading(false)
          onClose()
        })
        .catch((e) => {
          console.log("Error", e)
          setIsConfirmationLoading(false)
        })
    }
  }

  const onDialogConfirm = () => {
    setIsConfirmationLoading(true)
    if (!dialogConfig.values || !dialogConfig.roomTakenBy) {
      return
    } else {
      createOrUpdate(dialogConfig.values, dialogConfig.roomTakenBy)
        .then(() => {
          setIsConfirmationLoading(false)
          onClose()
        })
        .catch((e) => {
          console.log("Error", e)
          setIsConfirmationLoading(false)
        })
    }
    setIsConfirmationLoading(false)
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          open={config.isOpen}
          onClose={onClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {"Nouveau résident"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => onClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="lastname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Nom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="firstname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Prénom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {admin.roleId === "admin" && admin.isMultiFacilities === true && (
                  <Grid item xs={12}>
                    <Controller
                      name="facilityId"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <SelectFacility
                          {...field}
                          selectProps={{ style: { width: 250 } }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Controller
                    name="zoneId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectZone
                        {...field}
                        facility={facilities?.[watchFacilityId]}
                        selectProps={{ style: { width: 250 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="room"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="number"
                        label={"Chambre"}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          field.onChange(e.target.valueAsNumber)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                    {"Couleur: "}
                  </Typography>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => <HexColorPicker {...field} />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                    {"Avatar: "}
                  </Typography>
                  <Controller
                    name="avatarUrl"
                    control={control}
                    render={({ field }) => (
                      <AvatarUploader
                        {...field}
                        filenamePrefix={`${facility?.name}-${getValues(
                          "lastname"
                        )}-${getValues("firstname")}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
      {dialogConfig.isOpen && (
        <DialogConfirm
          isDialogOpen={dialogConfig.isOpen}
          setIsDialogOpen={() =>
            setDialogConfig({ isOpen: false, message: "" })
          }
          onConfirm={() => {
            onDialogConfirm()
          }}
          cancelText={"Annuler"}
          title={dialogConfig.message}
        />
      )}
    </>
  )
}
