import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useWorkContext } from "../../contexts/work.context"
import { bem } from "../../styles/bem"
import { Page } from "../../components/page/Page"
import { Panel } from "../../components/panel/Panel"
import { PanelTitle } from "../../components/panel/PanelTitle"
import { Flex } from "../../components/Flex"
import { IncidentsSumChart } from "./components/IncidentsSumChart"
import { Message, MessageDoc } from "../../domain/Message"
import { getIncidents, getLatestIncident } from "./query"
import { dayjsOf } from "../../core/firebase"
import { IncidentsTimelineChart } from "./components/IncidentsTimelineChart"
import { IncidentsOfResidentChart } from "./components/IncidentsOfResidentChart"
import { Filters, HeaderToolbar } from "./components/HeaderToolbar"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import "./analytics.less"
import { Box } from "@material-ui/core"

const b = "analytics"

const monthsBefore = (month: number, message?: Message): Dayjs => {
  const date = message?.creationDate ? dayjsOf(message.creationDate)! : dayjs()
  return date.subtract(month, "month")
}
const oneYearBefore = (message?: Message) => monthsBefore(12, message)
const oneMonthBefore = (message?: Message) => monthsBefore(1, message)

export const Analytics: React.FC = () => {
  usePageTitle(WebScreen.DASHBOARD_KPI)
  const { facility } = useWorkContext()

  const [messages, setMessages] = useState<MessageDoc[]>([])
  const [filters, setFilters] = useState<Filters>({
    profile: "",
    zone: "",
  })

  const filteredMessages = useMemo(() => {
    return messages
      .filter((m) => !filters.zone || m.zoneId === filters.zone)
      .filter((m) => !filters.profile || m.profiles.includes(filters.profile))
  }, [messages, filters.zone, filters.profile])

  const lastMonthMessages = useMemo(() => {
    const date = oneMonthBefore(filteredMessages?.[0])
    return filteredMessages.filter((m) =>
      date.isBefore(dayjsOf(m.creationDate))
    )
  }, [filteredMessages])

  useEffect(() => {
    if (!facility) {
      return
    }
    setMessages([])

    console.log("Load latest incident")
    getLatestIncident(facility.id)
      .then((message) => {
        console.log(
          `Load all incidents since ${oneYearBefore(message).toISOString()}`
        )
        return getIncidents(facility.id, oneYearBefore(message))
      })
      .then((messages) => {
        console.log("Loaded")
        setMessages(messages)
      })
  }, [facility])

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <HeaderToolbar filters={filters} onFiltersChange={setFilters} />
      <Page>
        <div className={bem(b, "page")}>
          <Row gutter={[20, 20]}>
            <Col xs={24} xl={10}>
              <Panel>
                <Flex>
                  <PanelTitle description={"sur 1 mois glissant"}>
                    Répartitions des incidents
                  </PanelTitle>
                </Flex>
                <IncidentsSumChart messages={lastMonthMessages} />
              </Panel>
            </Col>
            <Col xs={24} xl={14}>
              <IncidentsOfResidentChart
                zone={filters.zone}
                messages={lastMonthMessages}
              />
            </Col>
          </Row>
          <IncidentsTimelineChart
            latestMessage={messages[0]}
            messages={filteredMessages}
          />
        </div>
      </Page>
    </Box>
  )
}
