import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { useWorkContext } from "../../../../contexts/work.context"
import { CarePlanActivityLibraryItem } from "../../../../domain/CarePlan"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

interface ActivitiesLibraryProps {
  applyActivityItem: (care: CarePlanActivityLibraryItem) => void
  searchText?: string
}

export const ActivitiesLibrary: React.FC<ActivitiesLibraryProps> = ({
  applyActivityItem,
  searchText,
}) => {
  const [openedCategories, setOpenedCategories] = useState<
    Record<string, boolean>
  >({})
  const { cares } = useWorkContext()
  const categories = useMemo(
    () =>
      cares
        .map((care) => {
          return care.category
        })
        .filter((category, index, self) => self.indexOf(category) === index)
        .sort((a, b) => a.localeCompare(b)),
    [cares]
  )

  // Open categories that contain cares matching the search text, if no searchText, close all categories
  useEffect(() => {
    if (!searchText || searchText === "") {
      setOpenedCategories({})
      return
    }
    const categoriesToOpen = categories.filter((category) => {
      return cares.some((care) => {
        return (
          care.category === category &&
          care.label.toLowerCase().includes(searchText?.toLowerCase() || "")
        )
      })
    })
    setOpenedCategories(
      categories.reduce((acc, category) => {
        acc[category] = categoriesToOpen.includes(category)
        return acc
      }, {} as Record<string, boolean>)
    )
  }, [searchText, cares, categories])

  const caresLibrary: { [category: string]: CarePlanActivityLibraryItem[] } = {}
  categories.forEach((category) => {
    caresLibrary[category] = cares
      .filter((care) => care.category === category)
      .sort((a, b) => a.label.localeCompare(b.label))
  })

  const filteredCaresLibrary = Object.keys(caresLibrary).reduce(
    (acc, category) => {
      if (caresLibrary[category].length > 0) {
        acc[category] = caresLibrary[category].filter((care) =>
          care.label.toLowerCase().includes(searchText?.toLowerCase() || "")
        )
      }
      return acc
    },
    {} as { [category: string]: CarePlanActivityLibraryItem[] }
  )

  const handleLibraryItemClick = (care: CarePlanActivityLibraryItem) => {
    applyActivityItem(care)
  }

  const handleCategoryClick = (category: string) => {
    setOpenedCategories((prev) => ({ ...prev, [category]: !prev[category] }))
  }
  return (
    <Box>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          overflow: "auto",
          maxHeight: 450,
        }}
        component="div"
      >
        {categories.map((category) => {
          return (
            <>
              <ListItemButton
                onClick={() => handleCategoryClick(category)}
                sx={{ height: 25, color: "#FFFFFF" }}
                key={category}
              >
                {openedCategories[category] ? <ExpandLess /> : <ExpandMore />}
                <ListItemText
                  primary={category}
                  primaryTypographyProps={{
                    sx: { fontWeight: 700, textTransform: "capitalize" },
                  }}
                />
              </ListItemButton>
              <Collapse
                in={openedCategories[category]}
                timeout="auto"
                unmountOnExit
              >
                {filteredCaresLibrary[category].map((care) => {
                  return (
                    <List key={care.id} component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          pl: 8,
                          maxHeight: 25,
                          color: "#FFFFFF",
                          fontSize: 16,
                        }}
                        onClick={() => handleLibraryItemClick(care)}
                      >
                        <ListItemText
                          primary={care.label}
                          sx={{ whiteSpace: "nowrap" }}
                        />
                      </ListItemButton>
                    </List>
                  )
                })}
              </Collapse>
            </>
          )
        })}
      </List>
    </Box>
  )
}
