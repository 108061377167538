import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { singleDoc } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { ModalConfig } from "./DailyLife"
import { arrayUnion } from "firebase/firestore"
import { Daylife, MedicalFileDoc } from "../../../../domain/MedicalFile"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface DaylifeModalProps {
  medicalFile: MedicalFileDoc
  config: ModalConfig
  onClose: (config: ModalConfig) => void
  onSubmit: (medicalFile: MedicalFileDoc, config: ModalConfig) => void
}

export const defaultValues: Daylife = {
  comment: "",
}

export const DaylifeModal: React.FC<DaylifeModalProps> = ({
  medicalFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset } = useForm<Daylife>({
    defaultValues: { ...config.daylife } || defaultValues,
  })
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)

  useEffect(() => {
    if (config.daylife) {
      reset({
        ...config.daylife,
      })
    } else {
      reset(defaultValues)
    }
  }, [config.daylife, reset])

  const createOrUpdate = (daylife: Daylife): Promise<MedicalFileDoc> => {
    if (config.isUpdate && config.daylife) {
      const updatedDailyLife = medicalFile.dailyLife.map((d, i) =>
        i === config.index ? daylife : d
      )
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          dailyLife: updatedDailyLife,
        })
        .then(() => ({
          ...medicalFile,
          dailyLife: updatedDailyLife,
        }))
    } else {
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          dailyLife: arrayUnion(daylife),
        })
        .then(() => ({
          ...medicalFile,
          dailyLife: [...medicalFile.dailyLife, daylife],
        }))
    }
  }

  const onFormSubmit: SubmitHandler<Daylife> = (daylife: Daylife) => {
    setIsConfirmationLoading(true)
    createOrUpdate(daylife)
      .then((medicalFile) => {
        onSubmit(medicalFile, { isOpen: false, isUpdate: false })
        setIsConfirmationLoading(false)
        handleClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(defaultValues)
    onClose({ ...config, isOpen: false })
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {config.isUpdate
                    ? "Editer une information"
                    : "Ajouter une information sur la vie quotidienne"}
                </Typography>
                <IconButton
                  buttonProps={{
                    onClick: () => handleClose(),
                  }}
                  path={mdiWindowClose}
                  color={"#FFFFFF"}
                />
              </Box>
              {/* FORM FIELDS */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="comment"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        multiline={true}
                        required={true}
                        rows={2}
                        fullWidth
                        type="text"
                        label={"Commentaire"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {/* BUTTONS */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: 2,
                    width: "100%",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <MuiButton
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Annuler
                  </MuiButton>
                  <MuiButton type="submit">Ok</MuiButton>
                </Box>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
