import React from "react"
import { Cell } from "../../../../components/table/TableCell"
import { TableHead, TableRow } from "@mui/material"

export const VaccinesHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <Cell>Libellé des Vaccins</Cell>
        <Cell>Date cible</Cell>
        <Cell>Date vaccin</Cell>
        <Cell>Commentaires</Cell>
        <Cell props={{ align: "center" }}>Edition</Cell>
      </TableRow>
    </TableHead>
  )
}
