import React from "react"
import { Box, Typography } from "@mui/material"
import { SquareIcon } from "../../../../../components/Icon"

interface ChecklistSelectedItemProps {
  color: string
  path: string
  label: string
}

export const ChecklistSelectedItem: React.FC<ChecklistSelectedItemProps> = ({
  color,
  path,
  label,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 2,
      }}
    >
      <SquareIcon path={path} color={color} />
      <Typography color={"#FFFFFF"} fontSize={14}>
        {label}
      </Typography>
    </Box>
  )
}
