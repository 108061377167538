import React from "react"
import { Col, Row } from "antd"
import { useWorkContext } from "../../../contexts/work.context"
import { ProfileId } from "../../../domain/Profile"
import { SelectProfile } from "../../../components/select/SelectProfile"
import { SelectZone } from "../../../components/select/SelectZone"
import { ZoneId } from "../../../domain/Zone"
import { Flex } from "../../../components/Flex"
import "./headerToolbar.less"
import { HeaderNew } from "../../../components/header/HeaderNew"

const b = "headerToolbar"

export interface Filters {
  profile?: ProfileId
  zone?: ZoneId
}

interface Props {
  filters: Filters
  onFiltersChange: (filters: Filters) => void
}

export const HeaderToolbar: React.FC<Props> = ({
  filters,
  onFiltersChange,
}) => {
  const { facility } = useWorkContext()

  if (!facility) {
    return null
  }

  return (
    <HeaderNew title="Analyses">
      <Col>
        <Flex gap={20}>
          <SelectProfile
            value={filters.profile}
            onChange={(profile) =>
              onFiltersChange({
                ...filters,
                profile: profile,
              })
            }
          />
          <SelectZone
            value={filters.zone}
            onChange={(zone) =>
              onFiltersChange({
                ...filters,
                zone: zone,
              })
            }
          />
        </Flex>
      </Col>
      <Col></Col>
    </HeaderNew>
  )
}
