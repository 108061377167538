import { Select } from "antd"
import React, { useEffect } from "react"
import { useResidents } from "../../contexts/residents.context"
import { ResidentDoc } from "../../domain/Resident"
import { ZoneId } from "../../domain/Zone"

interface Props {
  autoSelect?: boolean
  filterZone?: ZoneId
  value?: ResidentDoc
  onChange?: (value: ResidentDoc) => void
}

export const SelectResident: React.FC<Props> = ({
  filterZone,
  autoSelect,
  value,
  onChange,
}) => {
  const { residents } = useResidents()
  const zoneResidents = residents?.filter(
    (r) => !filterZone || r.zoneId === filterZone
  )
  const zonesSelectOptions = zoneResidents
    ?.sort((a, b) => a.room - b.room)
    .map((resident) => ({
      label: `${resident.room} - ${resident.lastname} ${resident.firstname}`,
      value: resident.id,
    }))

  useEffect(() => {
    if (
      (!value || (filterZone && value.zoneId !== filterZone)) &&
      autoSelect &&
      zoneResidents &&
      zoneResidents[0] &&
      onChange
    ) {
      onChange(zoneResidents[0])
    }
  }, [value, autoSelect, zoneResidents, onChange, filterZone])

  const onSelectChange = (id: string) => {
    const resident = residents?.find((r) => r.id === id)
    resident && onChange && onChange(resident)
  }

  return (
    <Select
      showSearch
      placeholder={"Résident"}
      size={"large"}
      style={{ minWidth: "20rem" }}
      value={value?.id}
      onChange={onSelectChange}
      options={zonesSelectOptions}
      filterOption={(input, option) => {
        return (
          (option as { label: string })?.label
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        )
      }}
    />
  )
}
