import React, { useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiButton } from "../../../components/Button"
import { IconButton } from "../../../components/Icon"
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { MuiTextField } from "../../../components/TextField"
import { FacilityDoc } from "../../../domain/Facility"
import { mdiDelete, mdiPlus, mdiWindowClose } from "@mdi/js"
import { SelectOrganization } from "../../../components/select/SelectOrganization"
import { COLLECTIONS, createDoc, singleDoc } from "../../../core/firebase"
import { defaultFacility } from "../defaultFacility"
import createUser from "../../../core/cloudFunctions"
import { User } from "../../../domain/User"
import firebase from "firebase/compat"
import { IskusSpinner } from "../../../components/IskusSpinner"

type FormValues = {
  name: string
  pin: string
  organizationId: string
  adminEmail?: string
  adminPassword?: string
  adminFirstname?: string
  adminLastname?: string
  zones: { id: string; label: string }[]
}

export interface ModalConfig {
  isOpen: boolean
  facility?: FacilityDoc
  isUpdate?: boolean
}

interface FacilityModalProps {
  config: ModalConfig
  onClose: (newFacility?: FacilityDoc) => void
}

export const defaultValues: FormValues = {
  name: "",
  pin: Math.floor(
    Math.random() * (99999999 - 10000000 + 1) + 10000000
  ).toString(),
  organizationId: "",
  adminEmail: "",
  adminPassword: "",
  adminFirstname: "",
  adminLastname: "",
  zones: [],
}

export const FacilityModal: React.FC<FacilityModalProps> = ({
  config,
  onClose,
}) => {
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: config.isUpdate ? config.facility : defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "zones",
    rules: { required: true },
  })

  const createOrUpdate = (data: FormValues) => {
    const adminPassword = data.adminPassword!
    const adminEmail = data.adminEmail!
    const adminFirstname = data.adminFirstname!
    const adminLastname = data.adminLastname!

    delete data.adminPassword
    delete data.adminEmail
    delete data.adminFirstname
    delete data.adminLastname

    setIsConfirmationLoading(true)
    const full: FacilityDoc = {
      ...(config.facility || defaultFacility),
      ...data,
      zones: data.zones.map((zone) => ({
        id: zone.id,
        label: zone.label,
        index: 0,
        image: "",
        rooms: [],
      })),
      mailNotifications: {},
    }

    if (config.isUpdate && config.facility) {
      singleDoc
        .facility(config.facility.id)
        .update(full)
        .then(() => {
          onClose(full)
        })
        .catch((e) => console.log("error :", e))
    } else {
      Promise.all([
        createUser(adminEmail, adminPassword),
        createDoc(COLLECTIONS.FACILITY, full),
      ])
        .then((docs) => {
          const facilityId = docs[1].id
          const admin: User = {
            email: adminEmail,
            firstname: adminFirstname,
            lastname: adminLastname,
            avatarUrl: "",
            initials: `${adminLastname.charAt(0).toUpperCase()}${adminFirstname
              .charAt(0)
              .toUpperCase()}`,
            color: "#000000",
            facilityId,
            profileId: "",
            contractId: "",
            roleId: "admin",
            isActive: true,
            isArchived: false,
            lastLogin: firebase.firestore.Timestamp.now(),
            currentRoom: 0,
            organizationId: data.organizationId,
            isMultiFacilities: false,
          }
          createDoc(COLLECTIONS.USERS, admin)
            .then((ref) => {
              createDoc(COLLECTIONS.TOKEN, {
                userId: ref.id,
                email: adminEmail,
              })
            })
            .then(() => {
              alert("Facility created")
              onClose({ ...full, id: facilityId })
            })
            .catch((e) => {
              console.log("error", e)
              alert(`Erreur : ${e.message}`)
            })
            .finally(() => {
              setIsConfirmationLoading(false)
              onClose()
            })
        })
        .catch((e) => {
          console.log("error", e)
          alert(`Erreur : ${e.message}`)
        })
        .finally(() => setIsConfirmationLoading(false))
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    console.log("data", data)
    createOrUpdate(data)
  }

  return (
    <>
      {isConfirmationLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          open={config.isOpen}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {config.isUpdate
                  ? "Editer un établissement"
                  : "Nouvel établissement"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => onClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Nom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="pin"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 10000000,
                            max: 99999999,
                          },
                        }}
                        label={"Code pin"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                {!config.isUpdate && (
                  <Grid item xs={6}>
                    <Controller
                      name="organizationId"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <SelectOrganization
                          {...field}
                          selectProps={{ style: { width: 250 } }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!config.isUpdate && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          marginTop: 2,
                          color: "#FFFFFF",
                          fontSize: 16,
                        }}
                      >
                        Administrateur
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="adminEmail"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="email"
                            label={"Email"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="adminPassword"
                        rules={{
                          required: true,
                          minLength: 6,
                          maxLength: 6,
                          max: 999999,
                          pattern: /^[0-9]*$/,
                        }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="number"
                            helperText={"doit inclure 6 chiffres"}
                            label={"Mot de passe"}
                            {...field}
                            FormHelperTextProps={{
                              sx: { color: "#FFFFFF99" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="adminFirstname"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="text"
                            label={"Prénom"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="adminLastname"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="text"
                            label={"Nom"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      marginTop: 2,
                      color: "#FFFFFF",
                      fontSize: 16,
                    }}
                  >
                    Zones
                  </Typography>
                  {fields.map((field, index) => (
                    <Box
                      key={field.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 2,
                      }}
                    >
                      <Controller
                        name={`zones.${index}.id`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField {...field} label={"Id"} type="text" />
                        )}
                      />
                      <Controller
                        name={`zones.${index}.label`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField {...field} label={"Nom"} type="text" />
                        )}
                      />
                      <IconButton
                        buttonProps={{
                          onClick: () => remove(index),
                        }}
                        path={mdiDelete}
                        color={"#FFFFFF"}
                      />
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <IconButton
                    buttonProps={{
                      onClick: () =>
                        append({
                          id: "",
                          label: "",
                        }),
                    }}
                    path={mdiPlus}
                    color={"#FFFFFF"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
