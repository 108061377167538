import React, { useEffect, useState } from "react"
import { Modal } from "@material-ui/core"
import {
  AlertColors,
  CommentCategories,
  Message,
  colorOfLevel,
} from "../../../domain/Message"
import { Box, Chip, Divider, Typography } from "@mui/material"
import { NotificationInfos } from "./NotificationInfos"
import { capitalize } from "lodash"
import { mdiWindowClose } from "@mdi/js"
import { IconButton } from "../../../components/Icon"
import { Constants } from "../../../domain/Constants"
import { singleDoc } from "../../../core/firebase"
import { NotifConstant } from "./NotifConstant"

interface NotificationModalProps {
  data: Message
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MessageChip: React.FC<{
  text: string
  backgroundColor: string
  textColor: string
}> = ({ text, backgroundColor, textColor }) => {
  return (
    <Box sx={{ alignSelf: "center", height: 32, marginRight: 1 }}>
      <Chip
        label={text}
        sx={{
          color: textColor,
          backgroundColor: backgroundColor,
          height: 32,
          fontSize: 16,
        }}
      />
    </Box>
  )
}

const NotificationRow: React.FC<{
  category: string
  comment: string
  tags?: string[]
}> = ({ category, comment, tags }) => {
  if (!comment && !tags) {
    return null
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          alignItems: "center",
          minHeight: 50,
        }}
      >
        <Typography
          sx={{
            color: CommentCategories[category].color,
            fontSize: 24,
            fontWeight: "500",
            marginRight: 5,
            fontVariant: "small-caps",
          }}
        >
          {CommentCategories[category].header}
        </Typography>
        {tags &&
          tags.map((tag) => (
            <MessageChip
              key={tag}
              text={tag}
              backgroundColor={"#FFFFFFE6"}
              textColor={"#001C41"}
            />
          ))}
      </Box>
      {comment && (
        <Typography sx={{ fontSize: 16, color: "#FFFFFF" }}>
          {comment}
        </Typography>
      )}
    </Box>
  )
}

export const NotificationModal: React.FC<NotificationModalProps> = ({
  data,
  isOpen,
  setIsOpen,
}) => {
  const [constants, setConstants] = useState<undefined | Constants>()
  const categoryKeys = CommentCategories && Object.keys(CommentCategories)
  const hasCategory = categoryKeys.filter(
    (category) =>
      data.comments[category] ||
      (data.selectedTags && data.selectedTags[category])
  )
  const alertColor = colorOfLevel(data.alertLevel).end
  const painLevelColor = data.painLevel && AlertColors[data.alertLevel].end

  useEffect(() => {
    async function getConstants() {
      if (!data || !data.constantsDocId) return
      const newConstants = await singleDoc
        .constants(data.constantsDocId)
        .fetch()
        .then((res) => res.data())
      setConstants(newConstants)
    }
    getConstants()
    return () => setConstants(undefined)
  }, [data])

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80%",
          width: 800,
          backgroundColor: "#374D70",
          padding: 2,
          borderWidth: 1,
          borderColor: "#FFFFFF",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: 60,
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <NotificationInfos
              user={data.user}
              creationDate={data.creationDate}
            />
            <Chip
              label={capitalize(data.theme)}
              sx={{
                color: "#FFFFFFDE",
                backgroundColor: alertColor,
                height: 32,
                fontSize: 15,
              }}
            />
          </Box>
          <IconButton
            buttonProps={{
              onClick: () => setIsOpen(false),
            }}
            path={mdiWindowClose}
            color={"#FFFFFF"}
          />
        </Box>
        <Box sx={{ maxWidth: "100%", paddingX: 2 }}>
          {constants && (
            <Box>
              <NotifConstant constants={constants} isModal={true} />
              <Divider
                sx={{
                  marginY: 1,
                  borderColor: "#FFFFFF4D",
                }}
              />
            </Box>
          )}
          {data.painLevel && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: 50,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: "500",
                    fontVariant: ["small-caps"],
                    marginRight: 5,
                  }}
                >
                  Intensité
                </Typography>
                <MessageChip
                  text={`${data.painLevel.toString()}/10`}
                  backgroundColor={painLevelColor || "#FFFFFFE6"}
                  textColor={"#FFFFFFDE"}
                />
              </Box>
              <Divider
                sx={{
                  marginY: 1,
                  borderColor: "#FFFFFF4D",
                }}
              />
            </Box>
          )}
          {hasCategory.map((category, index) => (
            <Box key={category}>
              <NotificationRow
                category={category}
                comment={data.comments[category]}
                tags={data.selectedTags && data.selectedTags[category]}
              />
              {index + 1 !== hasCategory.length && (
                <Divider
                  sx={{
                    marginY: 1,
                    borderColor: "#FFFFFF4D",
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}
