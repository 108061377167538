import { Select } from "antd"
import React, { useEffect, useState } from "react"
import { sortBy } from "lodash"
import { useWorkContext } from "../../contexts/work.context"
import { I18 } from "../../core/i18n"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  restrictToProfile?: string
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectContract: React.FC<Props> = ({
  onChange,
  restrictToProfile,
  value,
  selectProps,
  formControlProps,
}) => {
  const [restricted, setRestricted] = useState<boolean>(false)
  const { contracts } = useWorkContext()
  const contractsSelectOptions = sortBy(
    contracts?.map(({ id, fullName }) => ({
      label: fullName,
      value: id,
    })),
    (option) => option.label
  )

  useEffect(() => {
    if (!restricted && restrictToProfile) {
      onChange && onChange(restrictToProfile)
      setRestricted(true)
    }
  }, [onChange, restricted, restrictToProfile])

  return (
    <CustomSelect
      label="Contrat"
      value={value}
      options={contractsSelectOptions}
      allOptionsLabel="Sélectionnez un contrat"
      onChange={onChange}
      restricted={restricted}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
