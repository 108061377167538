import { useEffect } from "react"
import { CONF } from "../core/config"
import { Screens } from "./screen"
import { WebScreen } from "../domain/Role"

export const usePageTitle = (screen: WebScreen) => {
  const title = Screens[screen].name || ""
  useEffect(() => {
    document.title = `${CONF.APP_NAME} ${title}`
  }, [title])
}
