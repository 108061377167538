import { ResidentConstants } from "./Constants"
import { Document } from "./Document"
import { ResidentAlerts } from "./ResidentAlert"

export interface Resident {
  firstname: string
  lastname: string
  avatarUrl: string
  initials: string
  color: string
  facilityId: string
  zoneId: string
  room: number
  isArchived: boolean
  alerts: ResidentAlerts
  constants: ResidentConstants
  organizationId: string
  medicalFileId?: string
  administrativeFileId?: string
}

export type ResidentDoc = Document & Resident

export const checkRoomAvailability = (
  facilityId: string,
  room: number,
  residents: ResidentDoc[],
  currentResidentId?: string
) => {
  const currentFacilityResidents = residents.filter((resident) => {
    if (!currentResidentId) {
      return resident.facilityId === facilityId && !resident.isArchived
    } else {
      return (
        resident.facilityId === facilityId &&
        !resident.isArchived &&
        resident.id !== currentResidentId
      )
    }
  })
  console.log("testing current facility residents", currentFacilityResidents)
  const residentInRoom = currentFacilityResidents.find(
    (resident) => resident.room === room && !resident.isArchived
  )
  return residentInRoom
}
