import React from "react"
import FormControl, { FormControlProps } from "@mui/material/FormControl"
import Select, { SelectProps } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"

interface Props {
  label: string
  options: { label: string; value: string }[]
  allOptionsLabel: string
  onChange?: (value: string) => void
  restricted?: boolean
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const CustomSelect: React.FC<Props> = ({
  label,
  options,
  allOptionsLabel,
  onChange,
  restricted,
  value,
  selectProps,
  formControlProps,
}) => {
  return (
    <FormControl sx={{ minWidth: 200 }} {...formControlProps}>
      <InputLabel
        id="select-label"
        shrink={true}
        sx={{
          "&.MuiFormLabel-root": {
            color: "#FFFFFF99",
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...selectProps}
        disabled={restricted}
        labelId="select-label"
        label={label}
        notched={true}
        displayEmpty={true}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          "&.MuiOutlinedInput-root": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFFFFF99",
          },
        }}
      >
        <MenuItem value={""}>
          <em>{allOptionsLabel}</em>
        </MenuItem>
        {options.map((profil) => (
          <MenuItem key={profil.value} value={profil.value}>
            {profil.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
