import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  Checkbox,
  Chip,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import {
  ActivityIconId,
  CarePlanActivityDoc,
} from "../../../../domain/CarePlan"
import { Icon, IconButton } from "../../../../components/Icon"
import {
  mdiBed,
  mdiPencil,
  mdiPill,
  mdiRun,
  mdiShower,
  mdiToilet,
  mdiWalk,
  mdiCommentText,
  mdiBroom,
  mdiPlus,
  mdiFoodAppleOutline,
  mdiHanger,
  mdiAccountMultiple,
} from "@mdi/js"
import {
  Timestamp,
  dayjsOf,
  getResidentCarePlan,
} from "../../../../core/firebase"
import { ResidentDoc } from "../../../../domain/Resident"
import { useWorkContext } from "../../../../contexts/work.context"
import { SelectTeam } from "../../../../components/select/SelectTeam"
import { chain, debounce } from "lodash"
import { SelectProfile } from "../../../../components/select/SelectProfile"
import { CustomTextSearch } from "../../../../components/CustomTextSearch"
import dayjs from "dayjs"
import { ActivityModal } from "./ActivityModal"
import { MuiToggleButton } from "../../../../components/Button"

export const ActivityIcons: Record<ActivityIconId, string> = {
  bed: mdiBed,
  shower: mdiShower,
  toilet: mdiToilet,
  "food-apple-outline": mdiFoodAppleOutline,
  hanger: mdiHanger,
  pill: mdiPill,
  run: mdiRun,
  people: mdiAccountMultiple,
  walk: mdiWalk,
  broom: mdiBroom,
}

const formatDate = (date: Timestamp) => {
  return dayjsOf(date)!.format("DD/MM/YYYY")
}

const formatTime = (date: Timestamp) => {
  return dayjsOf(date)!.format("HH:mm")
}

interface CarePlanProps {
  resident: ResidentDoc
}

interface Filters {
  teamId: string
  profileId: string
  archived: boolean
  isNursing: boolean
}

function filter(
  activities: CarePlanActivityDoc[],
  filters: Filters,
  searchText: string
) {
  if (!activities) {
    return undefined
  }
  const now = dayjs()
  return chain(activities)
    .thru((activities) =>
      filters.archived
        ? activities
        : activities.filter((a) =>
            a.endDate ? a.endDate.toDate() >= now.toDate() : a
          )
    )
    .thru((activities) =>
      !filters.teamId
        ? activities
        : activities.filter((a) => a.teamId === filters.teamId)
    )
    .thru((activities) =>
      !filters.profileId
        ? activities
        : activities.filter((a) => a.profileId === filters.profileId)
    )
    .thru((activities) =>
      !filters.isNursing
        ? activities.filter((a) => !a.isNursing)
        : activities.filter((a) => a.isNursing === filters.isNursing)
    )
    .thru((activities) =>
      activities.filter((a) =>
        `${a.label}`.toLowerCase().includes(searchText.toLowerCase())
      )
    )
    .value()
}

const StatusChip: React.FC<{ status: string }> = ({ status }) => {
  const isStandard = status === "standard"
  return (
    <Box sx={{ alignSelf: "center", height: 32, marginRight: 1 }}>
      <Chip
        label={isStandard ? "Standard" : "Temporaire"}
        sx={{
          color: isStandard ? "#FFFFFF" : "#000000DE",
          backgroundColor: isStandard ? "#FFFFFF29" : "#FFA726",
          height: 32,
          fontSize: 13,
        }}
      />
    </Box>
  )
}

export const CarePlan: React.FC<CarePlanProps> = ({ resident }) => {
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [activities, setActivities] = useState<CarePlanActivityDoc[]>([])
  const [filters, setFilters] = useState<Filters>({
    teamId: "",
    profileId: "",
    archived: false,
    isNursing: false,
  })
  const [activityToEdit, setActivityToEdit] = useState<
    CarePlanActivityDoc | undefined
  >()
  const [editModal, setEditModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState("")
  const { facilities, profiles } = useWorkContext()
  const facilityTeams = facilities && facilities[resident.facilityId].teams

  useEffect(() => {
    if (resident) {
      getResidentCarePlan(resident.id).then((activities) => {
        if (activities) {
          setActivities(
            activities.docs.map((activity) =>
              activity.data()
            ) as CarePlanActivityDoc[]
          )
        }
      })
    }
  }, [resident])

  const debouncedSetSearchText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  )

  const filteredActivities = filter(activities, filters, searchText)?.sort(
    (a, b) => {
      const aDate = a.executionTime.toDate()
      const bDate = b.executionTime.toDate()
      const aTimeElapsed = aDate.getTime() - aDate.setHours(0, 0, 0, 0)
      const bTimeElapsed = bDate.getTime() - bDate.setHours(0, 0, 0, 0)
      return aTimeElapsed - bTimeElapsed
    }
  )

  const handleEditActivity = (activity: CarePlanActivityDoc) => {
    setActivityToEdit(activity)
    setEditModal(true)
    setIsActivityModalOpen(true)
  }

  const handleCloseModal = () => {
    setEditModal(false)
    setActivityToEdit(undefined)
    setIsActivityModalOpen(false)
  }

  return (
    <>
      <Box sx={{ backgroundColor: "#FFFFFF33", marginTop: 5, borderRadius: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <CustomTextSearch
              placeholder={"Rechercher soin / tâche"}
              onChange={(text) => debouncedSetSearchText(text)}
              minWidth={200}
              textFieldProps={{ size: "small" }}
            />
            <SelectTeam
              facilityId={resident.facilityId}
              selectProps={{ size: "small" }}
              value={filters.teamId}
              onChange={(teamId) => setFilters({ ...filters, teamId: teamId })}
            />
            <SelectProfile
              value={filters.profileId}
              selectProps={{ size: "small" }}
              onChange={(profileId) =>
                setFilters({ ...filters, profileId: profileId })
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={filters.isNursing}
                size="small"
                exclusive
                onChange={(e, value) => {
                  if (value !== null) {
                    setFilters({ ...filters, isNursing: value })
                  }
                }}
              >
                <MuiToggleButton value={true}>SOINS</MuiToggleButton>
                <MuiToggleButton value={false}>TACHES</MuiToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                defaultChecked={filters.archived}
                onChange={(e) =>
                  setFilters({ ...filters, archived: e.target.checked })
                }
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
              />
              <Typography sx={{ color: "#FFFFFF" }}>Archivées</Typography>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
              sx={{
                backgroundColor: "#90CAF9",
                color: "#000000DE",
                fontWeight: 500,
              }}
              onClick={() => {
                setIsActivityModalOpen(true)
              }}
            >
              Nouveau
            </Button>
          </Box>
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Soin / Tâche
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Equipe
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Heure
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Durée estimée
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Métier
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Consignes
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Commentaires
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Début - Fin
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: "#FFFFFF",
                  fontSize: 14,
                  fontWeight: 500,
                  borderBottom: "1px solid #FFFFFF1F",
                }}
              >
                Edition
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredActivities &&
              filteredActivities.map((activity) => (
                <TableRow
                  key={activity.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        path={ActivityIcons[activity.iconId]}
                        style={{ marginRight: 10 }}
                      />{" "}
                      {activity.label}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {
                      facilityTeams?.filter(
                        (team) => team.id === activity.teamId
                      )[0].name
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {formatTime(activity.executionTime)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {activity.executionDuration} min
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {
                      profiles?.filter(
                        (profile) => profile.id === activity.profileId
                      )[0].name
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    <StatusChip status={activity.status} />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {activity.instructions && (
                      <Tooltip title={activity.instructions} arrow={true}>
                        <div>
                          <Icon path={mdiCommentText} color={"#FFFFFF8F"} />
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {activity.comment && (
                      <Tooltip title={activity.comment} arrow={true}>
                        <div>
                          <Icon path={mdiCommentText} color={"#FFA726"} />
                        </div>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    {formatDate(activity.startDate)} -{" "}
                    {activity.endDate
                      ? formatDate(activity.endDate)
                      : "indéfini"}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                    }}
                  >
                    <IconButton
                      path={mdiPencil}
                      color={"#FFFFFF"}
                      buttonProps={{
                        onClick: () => {
                          console.log("edit activity with id", activity.id),
                            handleEditActivity(activity)
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <ActivityModal
        resident={resident}
        isOpen={isActivityModalOpen}
        editModal={editModal}
        handleCloseModal={handleCloseModal}
        activities={activities}
        setActivities={setActivities}
        activityToEdit={activityToEdit}
      />
    </>
  )
}
