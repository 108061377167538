import MdiIcon from "@mdi/react"
import React from "react"
import { IconProps } from "@mdi/react/dist/IconProps"
import { Button, ButtonProps } from "antd"
import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material"
import { mdiPlus } from "@mdi/js"

export const Icon: React.FC<IconProps> = ({ className, ...props }) => (
  <MdiIcon className={className} size={1} {...props} />
)

export const IconButton: React.FC<IconProps & { buttonProps?: ButtonProps }> =
  ({ buttonProps, ...props }) => {
    return (
      <Button
        type={"ghost"}
        style={{ border: 0, backgroundColor: "transparent" }}
        {...buttonProps}
        icon={<Icon {...props} />}
      ></Button>
    )
  }

export const ButtonAddIcon: React.FC<{ buttonProps?: MuiButtonProps }> = ({
  buttonProps,
}) => {
  return (
    <MuiButton
      {...buttonProps}
      sx={{
        padding: 0,
        border: 0,
        backgroundColor: "#FFFFFF",
        minWidth: 44,
        width: 44,
        height: 36,
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        "&.MuiButton-root:hover": {
          backgroundColor: "#FFFFFF99",
        },
      }}
    >
      <Icon path={mdiPlus} color={"#001C41"} size={0.7} />
    </MuiButton>
  )
}

export const SquareIcon: React.FC<IconProps & { color: string }> = ({
  color,
  ...props
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: 0,
        backgroundColor: color,
        width: 40,
        height: 40,
        borderRadius: 4,
      }}
    >
      <Icon {...props} color={"#FFFFFF"} size={1} />
    </Box>
  )
}
