import React, { useEffect, useMemo, useRef, useState } from "react"
import { Page } from "../../components/page/Page"
import { onSnapshot, query, where } from "firebase/firestore"
import { COLLECTIONS } from "../../core/firebase"
import { useAuthUser } from "../../core/auth"
import { Filters, HeaderToolbar } from "./components/HeaderToolbar"
import { ModalConfig, ResidentModal } from "./components/ResidentModal"
import { ResidentDoc } from "../../domain/Resident"
import "./residents.less"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import { Box } from "@material-ui/core"
import { ResidentCard } from "./components/ResidentCard"
import { Grid } from "@mui/material"
import { chain } from "lodash"
import { IskusSpinner } from "../../components/IskusSpinner"

function filter(
  residents: ResidentDoc[] | undefined,
  filters: Filters,
  searchText: string
) {
  if (!residents) {
    return undefined
  }
  return chain(residents)
    .thru((residents) =>
      !filters.zone
        ? residents
        : residents.filter((r) => r.zoneId === filters.zone)
    )
    .thru((residents) =>
      !filters.facility
        ? residents
        : residents.filter((r) => r.facilityId === filters.facility)
    )
    .thru((residents) =>
      residents.filter((r) =>
        `${r.firstname}-${r.lastname}-${r.room}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    )
    .value()
}

export const Residents: React.FC = () => {
  usePageTitle(WebScreen.ADMIN_RESIDENTS)
  const { user } = useAuthUser()
  const [filters, setFilters] = useState<Filters>({
    facility: "",
    zone: "",
  })
  const [searchText, setSearchText] = useState<string>("")
  const [residents, setResidents] = useState<ResidentDoc[]>()
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })
  const watchUnsubscribe = useRef<Function>(() => {})

  const filteredResidents = useMemo(() => {
    return filter(residents, filters, searchText)?.sort(
      (a, b) => a.zoneId.localeCompare(b.zoneId) || a.room - b.room
    )
  }, [residents, filters, searchText])

  useEffect(() => {
    if (!user) {
      return
    }
    setResidents(undefined)

    const multiFacilityQuery = user.isMultiFacilities
      ? where("organizationId", "==", user.organizationId)
      : where("facilityId", "==", user.facilityId)

    watchUnsubscribe.current()
    watchUnsubscribe.current = onSnapshot(
      query<ResidentDoc>(
        COLLECTIONS.RESIDENT_DOC,
        multiFacilityQuery,
        where("isArchived", "==", false)
      ),
      (snapshot) => {
        setResidents(
          snapshot.docs.map((d) => {
            return { ...d.data(), id: d.id }
          })
        )
      }
    )
    return () => watchUnsubscribe.current()
  }, [user])

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderToolbar
        filters={filters}
        onFiltersChange={setFilters}
        onSearchTextChange={setSearchText}
        onCreate={() => setModalConfig({ isOpen: true, isUpdate: false })}
      />
      <Page>
        <Box
          sx={{
            marginTop: 50,
            maxWidth: "100%",
          }}
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            alignItems="center"
            justifyContent={"center"}
          >
            {!filteredResidents ? (
              <IskusSpinner />
            ) : (
              filteredResidents.map((resident) => (
                <Grid key={resident.id} item xs="auto">
                  <ResidentCard resident={resident} />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        {user && residents && modalConfig.isOpen && (
          <ResidentModal
            residents={residents}
            admin={user}
            onClose={() => setModalConfig({ isOpen: false })}
            config={modalConfig}
          />
        )}
      </Page>
    </Box>
  )
}
