import React from "react"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import Box from "@mui/material/Box"

interface Props {
  placeholder: string
  onChange: (text: string) => void
  minWidth?: number
  textFieldProps?: TextFieldProps
}

export const CustomTextSearch: React.FC<Props> = ({
  placeholder,
  onChange,
  minWidth,
  textFieldProps,
}: Props) => {
  return (
    <Box component="form" autoComplete="off" sx={{ minWidth: minWidth || 220 }}>
      <TextField
        {...textFieldProps}
        id="text-field"
        label={placeholder}
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            color: "#FFFFFF99",
            "&.Mui-focused": {
              color: "#FFFFFF99",
            },
          },
          "& .MuiOutlinedInput-root": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
        }}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  )
}
