import { styled } from "@mui/material/styles"
import { Button, ToggleButton } from "@mui/material"

export const MuiButton = styled(Button)({
  fontSize: 18,
  backgroundColor: "#FFFFFF33",
  color: "#FFFFFF",
  textTransform: "none",
  border: "1px solid",
  borderColor: "#FFFFFF",
  "&:hover": {
    borderColor: "#FFFFFF",
    backgroundColor: "#FFFFFF1A",
  },
})

export const MuiContainedButton = styled(Button)({
  fontSize: 14,
  fontWeight: 500,
  backgroundColor: "#FFFFFF",
  color: "#001C41",
  textTransform: "uppercase",
  border: "1px solid",
  borderColor: "#FFFFFF",
  "&:hover": {
    borderColor: "#FFFFFFCC",
    backgroundColor: "#FFFFFFCC",
  },
})

export const MuiToggleButton = styled(ToggleButton)({
  "&.MuiToggleButton-root": {
    color: "#FFFFFF99",
    "&.Mui-selected": {
      backgroundColor: "#FFFFFF1A",
      color: "#FFFFFF",
    },
  },
})
