import React, { CSSProperties } from "react"

type Props = {
  style?: CSSProperties | undefined
}

export const Center: React.FC<Props> = ({ style, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {children}
    </div>
  )
}
