import React, { useState } from "react"
import { countBy, groupBy, range } from "lodash"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { Message, MessageTheme } from "../../../domain/Message"
import { dayjsOf, Timestamp } from "../../../core/firebase"
import { length } from "../../../core/lang"
import { useWorkContext } from "../../../contexts/work.context"
import { Flex } from "../../../components/Flex"
import { PanelTitle } from "../../../components/panel/PanelTitle"
import { SelectMessageTheme } from "../../../components/select/SelectMessageTheme"
import { Panel } from "../../../components/panel/Panel"
import { I18 } from "../../../core/i18n"
import { IskusSpinner } from "../../../components/IskusSpinner"

interface Props {
  latestMessage: Message
  messages: Message[]
}

function monthsLabel(from: Timestamp): string[] {
  return range(11, -1, -1).map((index) =>
    dayjsOf(from)!.subtract(index, "month").format("MMMM")
  )
}

export const IncidentsTimelineChart: React.FC<Props> = ({
  latestMessage,
  messages,
}) => {
  const { messageButtons } = useWorkContext()
  const [theme, setTheme] = useState<MessageTheme>()

  const filteredMessages = !theme
    ? messages
    : messages.filter((m) => m.theme === theme)
  const messagesByMonth = groupBy(filteredMessages, (m) =>
    dayjsOf(m.creationDate)?.format("MMMM")
  )

  if (!latestMessage) {
    return <IskusSpinner />
  }

  const data = monthsLabel(latestMessage.creationDate).map((label) => ({
    name: label,
    color: "#33F",
    sum: length(messagesByMonth[label]),
    ...(theme ? {} : countBy(messagesByMonth[label], (m) => m.theme)),
  }))

  return (
    <Panel>
      <Flex justifyContent={"space-between"}>
        <PanelTitle description={"consolidé, sur 12 mois glissants"}>
          Evolution du nombre d’incidents
        </PanelTitle>
        <SelectMessageTheme
          style={{ minWidth: "20rem" }}
          allowClear
          size={"large"}
          value={theme}
          onChange={setTheme}
        />
      </Flex>
      <ResponsiveContainer width="100%" height={180}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            allowEscapeViewBox={{ x: false, y: true }}
            position={{ x: "auto" as any, y: -200 }} // eslint-disable-line @typescript-eslint/no-explicit-any
          />
          <Line
            type="monotone"
            name={I18.t(`sum`)}
            dataKey="sum"
            stroke="#4BACF2"
            strokeWidth={2}
            dot={{ r: 5 }}
            activeDot={{ r: 10 }}
          />
          {!theme &&
            messageButtons?.map(({ color, theme }) => (
              <Line
                key={theme}
                type="monotone"
                dataKey={theme}
                stroke={color}
                strokeWidth={1}
                dot={{ r: 2 }}
                activeDot={{ r: 4 }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </Panel>
  )
}
