import React from "react"
import { AlertLevel, colorOfLevel } from "../../../domain/Message"
import { Box, Chip } from "@mui/material"
import { Timestamp } from "../../../core/firebase"
import { Icon } from "../../../components/Icon"
// import { mdiWindowClose } from "@mdi/js"

interface AlertChipProps {
  alert: {
    theme: string
    alertLevel: AlertLevel
    creationDate: Timestamp
    userId: string
    comment: string
    icon: string
  }
  iconPath: string
}

export const AlertChip: React.FC<AlertChipProps> = ({ alert, iconPath }) => {
  const alertColor = colorOfLevel(alert.alertLevel).end
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: alertColor,
        width: 30,
        height: 30,
        borderRadius: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon path={iconPath} color={"#FFFFFF"} />
    </Box>
  )
}
