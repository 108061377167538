import React, { useEffect, useState } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import {
  COLLECTIONS,
  createDoc,
  dayjsOf,
  singleDoc,
  timestampOf,
} from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import dayjs, { Dayjs } from "dayjs"
import { ModalConfig } from "./Treatments"
import { IskusSpinner } from "../../../../components/IskusSpinner"
import { Route, Treatment, TreatmentDoc } from "../../../../domain/Treatment"
import { SelectRoute } from "../../../../components/select/SelectRoute"
import { MuiDateField } from "../../../../components/DateField"
import { MedicalFileDoc } from "../../../../domain/MedicalFile"
import { omitNils } from "../../../../core/lang"
import Papa from "papaparse"
import firebase from "firebase/compat"

interface TreatmentModalProps {
  medicalFile: MedicalFileDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (treatmentDocs: TreatmentDoc[], config: ModalConfig) => void
  treatments?: TreatmentDoc[]
}

type FormValues = {
  name: string
  drugId: string
  route: Route
  instructions: string
  morning: string
  noon: string
  evening: string
  night: string
  ald: boolean
  renewable: boolean
  treatmentStartDate: Dayjs
  treatmentEndDate: Dayjs
  renewalDate?: Dayjs
}

export const defaultValues: FormValues = {
  name: "",
  drugId: "625",
  route: "buccale",
  instructions: "",
  morning: "",
  noon: "",
  evening: "",
  night: "",
  ald: false,
  renewable: false,
  treatmentStartDate: dayjs(),
  treatmentEndDate: dayjs(),
  renewalDate: undefined,
}

const filter = (treatments: [string, string][], searchText: string) => {
  if (
    !treatments ||
    treatments.length < 1 ||
    !searchText ||
    searchText.length < 3
  ) {
    return undefined
  }
  return treatments.filter((t) =>
    t[1].toLowerCase().includes(searchText.toLowerCase())
  )
}

export const TreatmentModal: React.FC<TreatmentModalProps> = ({
  treatments,
  medicalFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormValues>(
    {
      defaultValues:
        {
          ...config.treatment,
          morning: config.treatment?.dosage.morning || "",
          noon: config.treatment?.dosage.noon || "",
          evening: config.treatment?.dosage.evening || "",
          night: config.treatment?.dosage.night || "",
          treatmentStartDate:
            config.treatment?.treatmentStartDate &&
            dayjsOf(config.treatment.treatmentStartDate),
          treatmentEndDate:
            config.treatment?.treatmentEndDate &&
            dayjsOf(config.treatment.treatmentEndDate),
          renewalDate:
            config.treatment?.renewalDate &&
            dayjsOf(config.treatment.renewalDate),
        } || defaultValues,
    }
  )
  const [isConfirmLoading, setIsConfirmationLoading] = useState(true)
  const [treatmentsLibrary, setTreatmentsLibrary] = useState<
    [string, string][]
  >([])
  const [textSearch, setTextSearch] = useState("")

  useEffect(() => {
    const subscription = watch((value) => {
      console.log(value)
      if (value.name) {
        setTextSearch(value.name)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const fetchTreatmentsLibrary = async () => {
    try {
      console.log("fetching treatments library")
      // Note: text file should be saved with UTF-8 encoding
      const response = await fetch("/treatments.txt")
      const decoder = new TextDecoder("utf-8")
      const buffer = await response.arrayBuffer()
      const text = decoder.decode(buffer)
      const parsed = Papa.parse(text, {
        delimiter: "\t",
      })
      console.log(parsed.data)
      setTreatmentsLibrary(parsed.data)
    } catch (error) {
      console.error(error)
    }
  }

  const filteredTreatments = filter(treatmentsLibrary, textSearch)

  useEffect(() => {
    fetchTreatmentsLibrary().then(() => {
      setIsConfirmationLoading(false)
    })
  }, [])

  useEffect(() => {
    if (config.treatment) {
      reset({
        ...config.treatment,
        morning: config.treatment?.dosage.morning || "",
        noon: config.treatment?.dosage.noon || "",
        evening: config.treatment?.dosage.evening || "",
        night: config.treatment?.dosage.night || "",
        treatmentStartDate:
          config.treatment?.treatmentStartDate &&
          dayjsOf(config.treatment.treatmentStartDate),
        treatmentEndDate:
          config.treatment?.treatmentEndDate &&
          dayjsOf(config.treatment.treatmentEndDate),
        renewalDate:
          config.treatment?.renewalDate &&
          dayjsOf(config.treatment.renewalDate),
      })
    } else {
      reset(defaultValues)
    }
  }, [config.treatment, reset])

  const createOrUpdate = (treatment: Treatment): Promise<TreatmentDoc[]> => {
    if (config.isUpdate && config.treatment) {
      return singleDoc
        .treatment(config.treatment.id)
        .update(treatment)
        .then(() =>
          treatments
            ? treatments.map((t) => {
                if (t.id === config.treatment!.id) {
                  return {
                    ...treatment,
                    id: config.treatment!.id,
                  }
                } else {
                  return t
                }
              })
            : [
                {
                  ...treatment,
                  id: config.treatment!.id,
                },
              ]
        )
    } else {
      return createDoc(COLLECTIONS.TREATMENT, treatment).then((ref) => {
        if (!treatments) {
          return [
            {
              ...treatment,
              id: ref.id,
            },
          ]
        }
        treatments.push({
          ...treatment,
          id: ref.id,
        })
        return treatments
      })
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    // setIsConfirmationLoading(true)
    console.log("values", values)
    if (!config.user) return
    const updates = {
      updatedAt: firebase.firestore.Timestamp.now(),
      updatedBy: omitNils({
        id: config.user.id,
        avatarUrl: config.user.avatarUrl,
        initials: config.user.initials,
        color: config.user.color,
        firstname: config.user.firstname,
        lastname: config.user.lastname,
        profileId: config.user.profileId,
      }),
    }
    const treatment: Treatment = omitNils({
      ...values,
      ...updates,
      residentId: medicalFile.residentId,
      facilityId: medicalFile.facilityId,
      organizationId: medicalFile.organizationId,
      dosage: {
        morning: values.morning,
        noon: values.noon,
        evening: values.evening,
        night: values.night,
      },
      treatmentStartDate: timestampOf(values.treatmentStartDate),
      treatmentEndDate: timestampOf(values.treatmentEndDate),
      renewalDate: values.renewalDate
        ? timestampOf(values.renewalDate)
        : undefined,
    })

    createOrUpdate(treatment)
      .then((treatmentDoc) => {
        onSubmit(treatmentDoc, config)
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(config.treatment || defaultValues)
    onClose()
  }

  const handleLibraryItemClick = (name: string, drugId: string) => {
    setValue("name", name)
    setValue("drugId", drugId)
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="md"
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {config.isUpdate
                    ? "Editer le traitement"
                    : "Ajouter un traitement"}
                </Typography>
                <IconButton
                  buttonProps={{
                    onClick: () => handleClose(),
                  }}
                  path={mdiWindowClose}
                  color={"#FFFFFF"}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* FORM FIELDS */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="text"
                            label={"Traitement"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="instructions"
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            multiline={true}
                            rows={2}
                            fullWidth
                            type="text"
                            label={"Consignes optionnelles"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="route"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <SelectRoute
                            {...field}
                            selectProps={{ style: { width: 396 } }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="morning"
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            type="text"
                            label={"Matin"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="noon"
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            type="text"
                            label={"Midi"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="evening"
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            type="text"
                            label={"Soir"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="night"
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            type="text"
                            label={"Coucher"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="treatmentStartDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            required={true}
                            label="Applicable à partir du"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="treatmentEndDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            required={true}
                            label="Applicable jusqu'au"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="renewalDate"
                        control={control}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            label="Renouvellement"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Controller
                          name="ald"
                          defaultValue={false}
                          control={control}
                          render={({ field: { value, ...field } }) => (
                            <Switch {...field} checked={value} value={value} />
                          )}
                        />
                        <Typography
                          sx={{
                            color: "#FFFFFFB2",
                            marginLeft: 4,
                            marginRight: 6,
                          }}
                        >
                          ALD
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Controller
                          name="renewable"
                          defaultValue={false}
                          control={control}
                          render={({ field: { value, ...field } }) => (
                            <Switch {...field} checked={value} value={value} />
                          )}
                        />
                        <Typography
                          sx={{
                            color: "#FFFFFFB2",
                            marginLeft: 4,
                            marginRight: 6,
                          }}
                        >
                          Renouvelable
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {/* LIST SELECTION */}
                <Grid item xs={6}>
                  <Box sx={{ height: "100%", backgroundColor: "#FFFFFF1A" }}>
                    <List
                      sx={{
                        width: "100%",
                        overflow: "auto",
                        maxHeight: 450,
                      }}
                      component="div"
                    >
                      {filteredTreatments &&
                        filteredTreatments.map((treatmentLibItem) => {
                          return (
                            <List
                              key={treatmentLibItem[0]}
                              component="div"
                              disablePadding
                            >
                              <ListItemButton
                                sx={{
                                  maxHeight: 25,
                                  color: "#FFFFFF",
                                  fontSize: 16,
                                }}
                                onClick={() =>
                                  handleLibraryItemClick(
                                    treatmentLibItem[1],
                                    treatmentLibItem[0]
                                  )
                                }
                              >
                                <ListItemText
                                  primary={treatmentLibItem[1]}
                                  sx={{ whiteSpace: "nowrap" }}
                                />
                              </ListItemButton>
                            </List>
                          )
                        })}
                    </List>
                  </Box>
                </Grid>
                {/* BUTTONS */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
