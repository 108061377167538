import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material"
import { MuiButton } from "./Button"

interface DialogConfirmProps {
  isDialogOpen: boolean
  setIsDialogOpen: (value: boolean) => void
  onConfirm: () => void
  title: string
  confirmText?: string
  cancelText?: string
}

export const DialogConfirm: React.FC<DialogConfirmProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  onConfirm,
  title,
  confirmText,
  cancelText,
}) => {
  const handleCancel = () => {
    setIsDialogOpen(false)
  }
  const handleConfirm = () => {
    onConfirm()
    setIsDialogOpen(false)
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      PaperProps={{ sx: { backgroundColor: "#374D70" } }}
    >
      <DialogContent>
        <DialogContentText sx={{ color: "#FFFFFF" }}>{title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={() => handleCancel()}>
          {cancelText || "Annuler"}
        </MuiButton>
        <MuiButton onClick={() => handleConfirm()}>
          {confirmText || "Ok"}
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}
