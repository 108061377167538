import React from "react"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectProtectionDocStatus: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  formControlProps,
}) => {
  const protectionDocStatusSelectOptions = [
    { label: "En cours", value: "En cours" },
    { label: "Demande en cours", value: "Demande en cours" },
    { label: "Demande refusée", value: "Demande refusée" },
    { label: "Validé", value: "Validé" },
  ]

  return (
    <CustomSelect
      label="Statut"
      value={value}
      options={protectionDocStatusSelectOptions}
      allOptionsLabel="Sélectionnez un statut"
      onChange={onChange}
      restricted={false}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
