import React, { useEffect, useState } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { dayjsOf, singleDoc, timestampOf } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { ModalConfig } from "./Vaccines"
import { arrayUnion } from "firebase/firestore"
import { MedicalFileDoc, Vaccine } from "../../../../domain/MedicalFile"
import dayjs, { Dayjs } from "dayjs"
import { MuiDateField } from "../../../../components/DateField"
import { VaccineLibrary } from "../../../../domain/Medical"
import { omitNils } from "../../../../core/lang"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface VaccineModalProps {
  medicalFile: MedicalFileDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (medicalFile: MedicalFileDoc, config: ModalConfig) => void
}

type FormValues = {
  name: string
  vaccineDate?: Dayjs
  targetDate?: Dayjs
  comment?: string
}

export const defaultValues: FormValues = {
  name: "",
  vaccineDate: undefined,
  targetDate: undefined,
  comment: "",
}

export const VaccineModal: React.FC<VaccineModalProps> = ({
  medicalFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues:
      {
        ...config.vaccine,
        vaccineDate:
          config.vaccine?.vaccineDate && dayjsOf(config.vaccine.vaccineDate),
        targetDate:
          config.vaccine?.targetDate && dayjsOf(config.vaccine.targetDate),
      } || defaultValues,
  })
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const [vaccinesLibrary, setVaccinesLibrary] = useState<VaccineLibrary>()
  const sortedVaccinesLibrary = vaccinesLibrary?.vaccines.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  useEffect(() => {
    singleDoc
      .medical<VaccineLibrary>("vaccines")
      .fetch()
      .then((vaccs) => {
        setVaccinesLibrary(vaccs.data())
      })
  }, [])

  useEffect(() => {
    if (config.vaccine) {
      reset({
        ...config.vaccine,
        vaccineDate:
          config.vaccine?.vaccineDate && dayjsOf(config.vaccine.vaccineDate),
        targetDate:
          config.vaccine?.targetDate && dayjsOf(config.vaccine.targetDate),
      })
    } else {
      reset(defaultValues)
    }
  }, [config.vaccine, reset])

  const createOrUpdate = (vaccine: Vaccine): Promise<MedicalFileDoc> => {
    if (config.isUpdate && config.vaccine) {
      const updatedVaccines = medicalFile.vaccines.map((v, i) =>
        i === config.index ? vaccine : v
      )
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          vaccines: updatedVaccines,
        })
        .then(() => ({
          ...medicalFile,
          vaccines: updatedVaccines,
        }))
    } else {
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          vaccines: arrayUnion(vaccine),
        })
        .then(() => ({
          ...medicalFile,
          vaccines: [...medicalFile.vaccines, vaccine],
        }))
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    console.log("values", values)
    setIsConfirmationLoading(true)
    const vaccine: Vaccine = omitNils({
      name: values.name,
      vaccineDate: values.vaccineDate
        ? timestampOf(values.vaccineDate)
        : undefined,
      targetDate: values.targetDate
        ? timestampOf(values.targetDate)
        : undefined,
      comment: values.comment,
    })
    createOrUpdate(vaccine)
      .then((medicalFile) => {
        onSubmit(medicalFile, { isOpen: false, isUpdate: false })
        setIsConfirmationLoading(false)
        handleClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(defaultValues)
    onClose()
  }

  const handleLibraryItemClick = (name: string) => {
    setValue("name", name)
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="md"
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {config.isUpdate ? "Editer le vaccin" : "Ajouter un vaccin"}
                </Typography>
                <IconButton
                  buttonProps={{
                    onClick: () => handleClose(),
                  }}
                  path={mdiWindowClose}
                  color={"#FFFFFF"}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* FORM FIELDS */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="text"
                            label={"Vaccin"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="comment"
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            multiline={true}
                            rows={2}
                            fullWidth
                            type="text"
                            label={"Commentaire optionnel"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="targetDate"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            required={false}
                            label="Date cible"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="vaccineDate"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            required={false}
                            label="Date vaccin"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* LIST SELECTION */}
                <Grid item xs={6}>
                  <Box sx={{ height: "100%", backgroundColor: "#FFFFFF1A" }}>
                    <List
                      sx={{
                        width: "100%",
                        overflow: "auto",
                        maxHeight: 450,
                      }}
                      component="div"
                    >
                      {sortedVaccinesLibrary &&
                        sortedVaccinesLibrary.map((vaccine) => {
                          return (
                            <List
                              key={vaccine.name}
                              component="div"
                              disablePadding
                            >
                              <ListItemButton
                                sx={{
                                  maxHeight: 25,
                                  color: "#FFFFFF",
                                  fontSize: 16,
                                }}
                                onClick={() =>
                                  handleLibraryItemClick(vaccine.name)
                                }
                              >
                                <ListItemText
                                  primary={vaccine.name}
                                  sx={{ whiteSpace: "nowrap" }}
                                />
                              </ListItemButton>
                            </List>
                          )
                        })}
                    </List>
                  </Box>
                </Grid>
                {/* BUTTONS */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
