import React, { useMemo } from "react"
import { useWorkContext } from "../../../contexts/work.context"
import { debounce } from "lodash"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { CustomTextSearch } from "../../../components/CustomTextSearch"
import { Box } from "@mui/material"
import { mdiPlus } from "@mdi/js"
import { IconButton } from "../../../components/Icon"
import { SelectFacility } from "../../../components/select/SelectFacility"
import { FacilityDoc } from "../../../domain/Facility"

export interface Filters {
  facility?: string
}

interface Props {
  filters: Filters
  onFiltersChange: (filters: Filters) => void
  onSearchTextChange: (search: string) => void
  onCreate: () => void
  selectedFacilities?: Record<string, FacilityDoc>
}

export const HeaderToolbar: React.FC<Props> = ({
  filters,
  onFiltersChange,
  onCreate,
  onSearchTextChange,
  selectedFacilities,
}) => {
  const { facility } = useWorkContext()
  const debouncedSetSearchText = useMemo(
    () => debounce(onSearchTextChange, 500),
    [onSearchTextChange]
  )

  if (!facility) {
    return null
  }

  return (
    <HeaderNew title="Personnel">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <CustomTextSearch
            placeholder={"Recherche par nom, identifiant, profil..."}
            onChange={(text) => debouncedSetSearchText(text)}
            minWidth={400}
            textFieldProps={{ size: "small" }}
          />
          <SelectFacility
            value={filters.facility}
            selectProps={{ size: "small" }}
            onChange={(facility) =>
              onFiltersChange({ ...filters, facility: facility })
            }
            selectedFacilities={selectedFacilities}
          />
        </Box>
        <Box sx={{ marginLeft: 3 }}>
          <IconButton
            path={mdiPlus}
            buttonProps={{
              onClick: onCreate,
              style: { backgroundColor: "#FFFFFF" },
            }}
            color={"#000000"}
          />
        </Box>
      </Box>
    </HeaderNew>
  )
}
