import React, { useState } from "react"
import { Box, Table, TableBody, Typography } from "@mui/material"
import { Icon } from "../../../../components/Icon"
import { IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiHospitalBox } from "@mdi/js"
import { Center } from "../../../../components/Center"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { FormCell } from "../../../../components/form/FormCell"
import { FormRow } from "../../../../components/form/FormRow"
import { RowSeparator } from "../../../../components/form/RowSeparator"
import { EditHealthCare } from "./EditHealthCare"
import { dependencyLevelByValue } from "../../../../domain/AdministrativeFile"

interface HealthCareProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export const HealthCare: React.FC<HealthCareProps> = ({
  adminFile,
  setAdminFile,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const healthCare = adminFile.healthCare

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiHospitalBox} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"Protection Santé"}
          </Typography>
        </Box>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          {!isEditing && (
            <IconButton
              path={mdiPencil}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  setIsEditing(true)
                },
              }}
            />
          )}
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        {!isEditing ? (
          <Table
            sx={{ minWidth: 400, tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableBody>
              <FormRow title={"N° Sécurité sociale"}>
                <FormCell content={healthCare.socialSecurityNumber} />
              </FormRow>
              <FormRow title={"Régime générale"}>
                <FormCell content={healthCare.socialSecurityScheme} />
              </FormRow>
              <FormRow title={"Caisse d'affiliation"}>
                <FormCell content={healthCare.healthFund} />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Mutuelle"}>
                <FormCell content={healthCare.mutualInsuranceCompany} />
              </FormRow>
              <FormRow title={"N° adhérent mutuelle"}>
                <FormCell content={healthCare.mutualInsuranceNumber} />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Classement GIR"}>
                <FormCell
                  content={
                    healthCare.dependencyLevel &&
                    dependencyLevelByValue[healthCare.dependencyLevel]
                  }
                />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Médecin traitant"}>
                <FormCell content={healthCare.gpName} />
              </FormRow>
              <FormRow title={"Contact mail"}>
                <FormCell content={healthCare.gpEmail} />
              </FormRow>
              <FormRow title={"Contact téléphone"}>
                <FormCell content={healthCare.gpPhoneNumber} />
              </FormRow>
            </TableBody>
          </Table>
        ) : (
          <EditHealthCare
            adminFile={adminFile}
            setAdminFile={setAdminFile}
            setIsEditing={setIsEditing}
          />
        )}
      </Box>
    </>
  )
}
