import React, { useState } from "react"
import { TableRow, TableCell, Box, Tooltip } from "@mui/material"
import { Icon, IconButton } from "../../../../components/Icon"
import { mdiCheckCircleOutline, mdiPencil, mdiTrashCanOutline } from "@mdi/js"

import { Acquaintance } from "../../../../domain/AdministrativeFile"
import { DialogConfirm } from "../../../../components/DialogConfirm"

interface AcquaintanceRowProps {
  acquaintance: Acquaintance
  index: number
  onEditClick: (index: number) => void
  onDeleteClick: (index: number) => void
}

export const AcquaintanceRow: React.FC<AcquaintanceRowProps> = ({
  acquaintance,
  index,
  onEditClick,
  onDeleteClick,
}) => {
  const { name, ties, phoneNumber, dayContact, nightContact } = acquaintance
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  return (
    <>
      <TableRow
        hover={true}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {name}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {ties}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {phoneNumber}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {dayContact && <Icon path={mdiCheckCircleOutline} />}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {nightContact && <Icon path={mdiCheckCircleOutline} />}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip title={"Editer"}>
              <div>
                <IconButton
                  path={mdiPencil}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      onEditClick(index)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Supprimer"}>
              <div>
                <IconButton
                  path={mdiTrashCanOutline}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsDeleteDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <DialogConfirm
        isDialogOpen={isDeleteDialogOpen}
        setIsDialogOpen={setIsDeleteDialogOpen}
        title={"Supprimer ce contact ?"}
        onConfirm={() => onDeleteClick(index)}
        cancelText="Annuler"
        confirmText="Supprimer"
      />
    </>
  )
}
