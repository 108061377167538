import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import { styled, Theme, CSSObject } from "@mui/material/styles"
import { useState } from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import List from "@mui/material/List"
import { isRoutedAllowed, Screens } from "../../pages/screen"
import { WebScreen } from "../../domain/Role"
import { ListItemLink } from "./ListItemLink"
import { useAuthUser } from "../../core/auth"
import { useWorkContext } from "../../contexts/work.context"
import ListAltIcon from "@mui/icons-material/ListAlt"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import DescriptionIcon from "@mui/icons-material/Description"
import AssessmentIcon from "@mui/icons-material/Assessment"
import SettingsIcon from "@mui/icons-material/Settings"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { mdiChefHat } from "@mdi/js"
import { DrawerHeader } from "./DrawerHeader"
import { logout } from "../../core/firebase"
import { Icon } from "../Icon"
import { SelectOrganization } from "../select/SelectOrganization"

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#001C41",
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#001C41",
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

export const NavigationDrawer: React.FC = () => {
  const [open, setOpen] = useState(true)
  const { user } = useAuthUser()
  const { facility, role, setCurrentOrganizationId } = useWorkContext()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  if (!user) {
    return null
  }
  return (
    <div>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ backgroundColor: "#001C41" }}
      >
        <DrawerHeader drawerOpen={open} />
        <List>
          {/* {isRoutedAllowed(WebScreen.DASHBOARD_LIST, user, facility) && (
            <NestedList
              drawerOpen={open}
              text={"Tableau de bord"}
              icon={<ListAltIcon />}
            >
              <ListItemLink
                drawerOpen={open}
                text={"Global"}
                to={Screens[WebScreen.DASHBOARD_LIST].path}
              />
              {facility?.zones.map((zone) => (
                <ListItemLink
                  key={zone.id}
                  drawerOpen={open}
                  text={zone.label}
                  to={Screens[WebScreen.DASHBOARD_LIST].path}
                />
              ))}
            </NestedList>
          )} */}
          {isRoutedAllowed(WebScreen.DASHBOARD_LIST, role) && (
            <ListItemLink
              drawerOpen={open}
              text={"Tableau de bord"}
              icon={<ListAltIcon />}
              to={Screens[WebScreen.DASHBOARD_LIST].path}
            />
          )}
          {isRoutedAllowed(WebScreen.ADMIN_RESIDENTS, role) && (
            <ListItemLink
              drawerOpen={open}
              text={"Résidents"}
              icon={<AccountBoxIcon />}
              to={Screens[WebScreen.ADMIN_RESIDENTS].path}
            />
          )}
          {isRoutedAllowed(WebScreen.PROTOCOLS, role) && (
            <ListItemLink
              drawerOpen={open}
              text={"Protocoles"}
              icon={<DescriptionIcon />}
              to={Screens[WebScreen.PROTOCOLS].path}
            />
          )}
          <ListItemLink
            drawerOpen={open}
            text={"Cuisine"}
            icon={<Icon path={mdiChefHat} />}
            to={Screens[WebScreen.KITCHEN].path}
          />
          {isRoutedAllowed(WebScreen.DASHBOARD_KPI, role) &&
            user.isSuperAdmin && (
              <ListItemLink
                drawerOpen={open}
                text={"Analyses"}
                icon={<AssessmentIcon />}
                to={Screens[WebScreen.DASHBOARD_KPI].path}
              />
            )}
          {isRoutedAllowed(WebScreen.ADMIN_USERS, role) && (
            <ListItemLink
              drawerOpen={open}
              text={"Personnel"}
              icon={<ListAltIcon />}
              to={Screens[WebScreen.ADMIN_USERS].path}
            />
          )}
          {isRoutedAllowed(WebScreen.ADMIN_FACILITY, role) && (
            <ListItemLink
              drawerOpen={open}
              text={"Administration"}
              icon={<SettingsIcon />}
              to={Screens[WebScreen.ADMIN_FACILITY].path}
            />
          )}
          {/* {isRoutedAllowed(WebScreen.ADMIN_FACILITY, user, facility) && (
            <NestedList
              drawerOpen={open}
              text={"Administration"}
              icon={<SettingsIcon />}
            >
              <ListItemLink
                drawerOpen={open}
                text={"Fiches de postes"}
                to={Screens[WebScreen.ADMIN_FACILITY].path}
              />
              <ListItemLink
                drawerOpen={open}
                text={"Tâches par poste"}
                to={Screens[WebScreen.ADMIN_FACILITY].path}
              />
              <ListItemLink
                drawerOpen={open}
                text={"Traçabilité"}
                to={Screens[WebScreen.ADMIN_FACILITY].path}
              />
              {user?.isSuperAdmin && (
                <ListItemLink
                  drawerOpen={open}
                  text={"Etablissement"}
                  to={Screens[WebScreen.ADMIN_FACILITY].path}
                />
              )}
            </NestedList>
          )} */}
          <ListItemButton
            onClick={logout}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              "&.Mui-selected": { backgroundColor: "#ffffff1a" },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#ffffff8f",
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Se déconnecter"}
              sx={{ color: "#ffffffde", opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </List>
        {open && user.isSuperAdmin && (
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              justifyContent: "center",
              maxWidth: "90%",
            }}
          >
            <SelectOrganization
              onChange={function (value: string): void {
                console.log("value", value)
                setCurrentOrganizationId(value)
              }}
              selectProps={{
                defaultValue: user.organizationId,
                style: { maxWidth: "100%" },
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flex: 1,
            mt: 2,
            pr: 2,
          }}
        >
          <Box sx={{ mb: 2 }}>
            {open ? (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon sx={{ color: "#ffffff8f" }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen}>
                <ChevronRightIcon sx={{ color: "#ffffff8f" }} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}
