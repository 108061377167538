import React from "react"
import { Box, Typography } from "@mui/material"
import { Icon } from "./Icon"

export const SubHeaderIcon: React.FC<{
  text: string
  color: string
  path: string
}> = ({ text, color, path }) => {
  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: 2,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 24,
      }}
    >
      <Icon path={path} color={color} />
      <Typography
        sx={{
          marginLeft: 1,
          verticalAlign: "top",
          lineHeight: "100%",
          height: "100%",
          fontSize: 24,
          color: color,
          fontVariant: "small-caps",
          paddingRight: 2,
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          height: "100%",
          flex: 1,
          width: "100%",
          borderBottomWidth: 1,
          borderBottomColor: color,
          borderBottomStyle: "solid",
        }}
      />
    </Box>
  )
}
