import React from "react"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectRoute: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  formControlProps,
}) => {
  const routeSelectOptions = [
    { label: "Buccale", value: "buccale" },
    { label: "Nasale", value: "nasale" },
    { label: "Oculaire", value: "oculaire" },
    { label: "Rectale", value: "rectale" },
    { label: "Sublinguale", value: "sublinguale" },
    { label: "Vaginale", value: "vaginale" },
    { label: "Intra-veineuse", value: "intra-veineuse" },
    { label: "Intra-musculaire", value: "intra-musculaire" },
    { label: "Sous cutanée", value: "sous cutanée" },
  ]

  return (
    <CustomSelect
      label="Voie"
      value={value}
      options={routeSelectOptions}
      allOptionsLabel="Sélectionnez une voie"
      onChange={onChange}
      restricted={false}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
