import React from "react"
import { useWorkContext } from "../../contexts/work.context"
import { MessageTheme } from "../../domain/Message"
import { CustomSelect } from "./CustomSelect"
import { SelectProps } from "@mui/material"

interface Props {
  value: MessageTheme
  onChange: (value: MessageTheme) => void
  selectProps?: SelectProps
}

export const SelectMessageTheme: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
}) => {
  const { messageLabelByTheme } = useWorkContext()
  const options = Object.entries(messageLabelByTheme).map(([id, name]) => ({
    label: name,
    value: id,
  }))

  return (
    <CustomSelect
      label="Type d’alerte"
      value={value}
      options={options}
      allOptionsLabel="Tous les types d’alerte"
      onChange={onChange}
      selectProps={selectProps}
    />
  )
}
