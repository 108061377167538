import React, { useState } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material"
import { FormCell } from "../../../../components/form/FormCell"
import { FormRow } from "../../../../components/form/FormRow"
import { RowSeparator } from "../../../../components/form/RowSeparator"
import { MuiDatePicker } from "../../../../components/DateField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import {
  AdministrativeFileDoc,
  FamilyStatus,
  Gender,
  QualifiedIdentity,
} from "../../../../domain/AdministrativeFile"
import { MuiTextField } from "../../../../components/TextField"
import { FormSelectUncontrolled } from "../../../../components/select/FormSelect"
import { MuiButton } from "../../../../components/Button"
import { FacilityDoc } from "../../../../domain/Facility"
import { dayjsOf, singleDoc, timestampOf } from "../../../../core/firebase"
import { Dayjs } from "dayjs"
import { omitNils } from "../../../../core/lang"
import { DialogConfirm } from "../../../../components/DialogConfirm"
import { ResidentDoc, checkRoomAvailability } from "../../../../domain/Resident"
import { useResidents } from "../../../../contexts/residents.context"

type FormValues = {
  firstname: string
  lastname: string
  birthname?: string
  birthdate?: Dayjs
  birthplace?: string
  familyStatus?: FamilyStatus | undefined
  gender?: Gender | undefined
  nationality?: string
  postalAddress?: string
  phoneNumber?: string
  identityDocumentNumber?: string
  INSEECode?: string
  qualifiedIdentity?: QualifiedIdentity | undefined
  administrativeIdentification?: string
  zoneId: string
  room: number
  height?: number
  weight?: number
  arrivalDate?: Dayjs
}

const defaultValues: FormValues = {
  firstname: "",
  lastname: "",
  birthname: "",
  birthdate: undefined,
  birthplace: "",
  familyStatus: undefined,
  gender: undefined,
  nationality: "",
  postalAddress: "",
  phoneNumber: "",
  identityDocumentNumber: "",
  INSEECode: "",
  qualifiedIdentity: undefined,
  administrativeIdentification: "",
  zoneId: "",
  room: 0,
  height: 0,
  weight: 0,
  arrivalDate: undefined,
}

interface EditIdentityProps {
  facility?: FacilityDoc
  adminFile?: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
  setIsEditing: (isEditing: boolean) => void
}

export const EditIdentity: React.FC<EditIdentityProps> = ({
  facility,
  adminFile,
  setAdminFile,
  setIsEditing,
}) => {
  const { residents } = useResidents()
  const [dialogConfig, setDialogConfig] = useState<{
    isOpen: boolean
    message: string
    roomTakenBy?: ResidentDoc | undefined
  }>({ isOpen: false, message: "" })

  const { control, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues:
      {
        ...adminFile?.identity,
        birthdate:
          adminFile?.identity.birthdate &&
          dayjsOf(adminFile?.identity.birthdate),
        arrivalDate:
          adminFile?.identity.arrivalDate &&
          dayjsOf(adminFile?.identity.arrivalDate),
      } || defaultValues,
  })

  const zonesOptions = facility?.zones.map((zone) => ({
    id: zone.id,
    label: zone.label,
  }))

  const handleUpdateAdminFile = () => {
    const values = getValues()
    if (!adminFile) return
    const full = omitNils({
      ...values,
      birthdate: values.birthdate && timestampOf(values.birthdate),
      arrivalDate: values.arrivalDate && timestampOf(values.arrivalDate),
    })
    singleDoc
      .administrativeFile(adminFile?.id)
      .update({ identity: full })
      .then(() => {
        setAdminFile({ ...adminFile, identity: full })
        singleDoc
          .resident(adminFile.residentId)
          .update({
            firstname: full.firstname,
            lastname: full.lastname,
            room: full.room,
            zoneId: full.zoneId,
            initials: `${full.lastname[0]}${full.firstname[0]}`,
          })
          .then(() => {
            if (dialogConfig.roomTakenBy) {
              singleDoc.resident(dialogConfig.roomTakenBy.id).update({
                ...dialogConfig.roomTakenBy,
                isArchived: true,
              })
            }
            setIsEditing(false)
          })
      })
  }

  const onFormSubmit: SubmitHandler<FormValues> = (value: FormValues) => {
    console.log("Values: ", value)
    if (!adminFile) return
    const roomTakenBy: ResidentDoc | undefined =
      residents &&
      facility &&
      checkRoomAvailability(
        facility.id,
        value.room,
        residents,
        adminFile.residentId
      )
    console.log("RoomTakenBy: ", roomTakenBy)
    setDialogConfig({
      isOpen: true,
      message: `Mettre à jour la fiche administrative ? ${
        roomTakenBy
          ? `Attention, la chambre ${value.room} est déjà occupée par ${roomTakenBy.lastname} ${roomTakenBy.firstname}. Ce résident va être archivé. Voulez-vous continuer ?`
          : ""
      }`,
      roomTakenBy: roomTakenBy,
    })
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Table
        sx={{ minWidth: 400, tableLayout: "fixed" }}
        aria-label="simple table"
      >
        <TableBody>
          <FormRow title={"Nom"}>
            <FormCell
              content={
                <Controller
                  name="lastname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      required={true}
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Prénom"}>
            <FormCell
              content={
                <Controller
                  name="firstname"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      required={true}
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Nom de naissance"}>
            <FormCell
              content={
                <Controller
                  name="birthname"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Date de naissance"}>
            <FormCell
              content={
                <Controller
                  name="birthdate"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <MuiDatePicker
                      {...field}
                      format="DD-MM-YYYY"
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: 0,
                        },
                      }}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Lieu de naissance"}>
            <FormCell
              content={
                <Controller
                  name="birthplace"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Situation de famille"}>
            <FormCell
              content={
                <Controller
                  name="familyStatus"
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={[
                        { id: "Célibataire", label: "Célibataire" },
                        { id: "marié.e", label: "Marié.e" },
                        { id: "pacsé.e", label: "Pacsé.e" },
                        { id: "divorcé.e", label: "Divorcé.e" },
                        { id: "veuf.ve", label: "Veuf.ve" },
                        { id: "concubinage", label: "Concubinage" },
                        { id: "séparé.e.s", label: "Séparé.e.s" },
                        { id: "autre", label: "Autre" },
                      ]}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Genre"}>
            <FormCell
              content={
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={[
                        { id: "femme", label: "Femme" },
                        { id: "homme", label: "Homme" },
                        { id: "autre", label: "Autre" },
                      ]}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Nationalité"}>
            <FormCell
              content={
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Adresse"}>
            <FormCell
              content={
                <Controller
                  name="postalAddress"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Numéro de téléphone"}>
            <FormCell
              content={
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Numéro carte d'identité"}>
            <FormCell
              content={
                <Controller
                  name="identityDocumentNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"code Insee (lieu Inc)"}>
            <FormCell
              content={
                <Controller
                  name="INSEECode"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Identité Qualifiée"}>
            <FormCell
              content={
                <Controller
                  name="qualifiedIdentity"
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={[
                        { id: "oui", label: "Oui" },
                        { id: "non", label: "Non" },
                        { id: "en cours", label: "En cours" },
                      ]}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Identification administrative"}>
            <FormCell
              content={
                <Controller
                  name="administrativeIdentification"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Numéro de chambre"}>
            <FormCell
              content={
                <Controller
                  name="room"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      required={true}
                      type="number"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        field.onChange(e.target.valueAsNumber)
                      }
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Zone"}>
            <FormCell
              content={
                <Controller
                  name="zoneId"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={zonesOptions || []}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Date d'entrée"}>
            <FormCell
              content={
                <Controller
                  name="arrivalDate"
                  rules={{ required: false }}
                  control={control}
                  render={({ field }) => (
                    <MuiDatePicker
                      {...field}
                      format="DD-MM-YYYY"
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: 0,
                        },
                      }}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Taille (cm)"}>
            <FormCell
              content={
                <Controller
                  name="height"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="number"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        field.onChange(e.target.valueAsNumber)
                      }
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Poids initial (kg)"}>
            <FormCell
              content={
                <Controller
                  name="weight"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="number"
                      {...field}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        field.onChange(e.target.valueAsNumber)
                      }
                    />
                  )}
                />
              }
            />
          </FormRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={1} sx={{ border: 0 }} />
            <TableCell colSpan={1} sx={{ border: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <MuiButton
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  Annuler
                </MuiButton>
                <MuiButton type="submit">Ok</MuiButton>
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DialogConfirm
        isDialogOpen={dialogConfig.isOpen}
        setIsDialogOpen={() =>
          setDialogConfig({
            isOpen: false,
            message: "",
          })
        }
        title={dialogConfig.message}
        onConfirm={() => handleUpdateAdminFile()}
        cancelText="Annuler"
        confirmText="Mettre à jour"
      />
    </form>
  )
}
