import { Role, WebScreen } from "../domain/Role"
import { useWorkContext } from "../contexts/work.context"

export const Screens: Record<
  WebScreen,
  { name: string; path: string; rootPath?: string }
> = {
  [WebScreen.CONFIDENTIALITY]: {
    name: "confidentiality",
    path: "/politique-de-confidentialite",
  },
  [WebScreen.ADMIN_FACILITY]: { name: "Établissement", path: "/facility" },
  [WebScreen.ADMIN_RESIDENTS]: { name: "Résidents", path: "/residents" },
  [WebScreen.ADMIN_RESIDENT]: {
    name: "Résident",
    path: "/resident/:id",
    rootPath: "/resident",
  },
  [WebScreen.ADMIN_USERS]: { name: "Personnel", path: "/staff" },
  [WebScreen.DASHBOARD_LIST]: { name: "Dashboard", path: "/dashboard" },
  [WebScreen.DASHBOARD_KPI]: { name: "Analyses", path: "/analyses" },
  [WebScreen.HOME]: { name: "ISKUS", path: "/" },
  [WebScreen.LOGIN]: { name: "Connexion", path: "/login" },
  [WebScreen.LOGIN_RESET_PWD]: {
    name: "Connexion",
    path: "/login/reset-password",
  },
  [WebScreen.PLANNING]: { name: "Organisation", path: "/organisation" },
  [WebScreen.PROTOCOLS]: { name: "Protocoles", path: "/protocols" },
  [WebScreen.KITCHEN]: { name: "Cuisine", path: "/cuisine" },
}

export const isRoutedAllowed = (screen: WebScreen, role?: Role): boolean => {
  return Boolean(role?.screens.includes(screen))
}

export const useRoutedAllowed = (screen: WebScreen): boolean => {
  const { role } = useWorkContext()
  return Boolean(isRoutedAllowed(screen, role))
}
