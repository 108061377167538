import React, { useState } from "react"
import { TableRow, TableCell, Avatar, Box, Tooltip } from "@mui/material"
import { UserDoc } from "../../../domain/User"
import { useWorkContext } from "../../../contexts/work.context"
import { Icon, IconButton } from "../../../components/Icon"
import {
  mdiCheck,
  mdiClose,
  mdiLockQuestion,
  mdiPencil,
  mdiQrcode,
  mdiTrashCanOutline,
} from "@mdi/js"
import { dayjsOf } from "../../../core/firebase"
import { DialogConfirm } from "../../../components/DialogConfirm"
import { I18 } from "../../../core/i18n"
import QRCode from "qrcode"
import { createQRBadge } from "./CreateQRBadge"

interface TableRowProps {
  user: UserDoc
  onEditClick: (user: UserDoc) => void
  onRemove: (user: UserDoc) => void
  onResetPassword: (user: UserDoc) => void
}

export const Row: React.FC<TableRowProps> = ({
  user,
  onEditClick,
  onRemove,
  onResetPassword,
}) => {
  const { roleLabelById, profiles, contractLabelById } = useWorkContext()
  const {
    id,
    avatarUrl,
    initials,
    color,
    lastname,
    firstname,
    email,
    profileId,
    contractId,
    roleId,
    isActive,
    lastLogin,
  } = user
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false)

  const createQRCode = async () => {
    try {
      await QRCode.toDataURL(id, {
        scale: 10,
      }).then((url) => {
        createQRBadge(url, `${firstname} ${lastname}`)
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <TableRow
        hover={true}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Avatar
            variant={"rounded"}
            src={avatarUrl}
            sx={{
              backgroundColor: color,
              height: 35,
              width: 35,
              marginRight: 1,
            }}
          >
            {initials}
          </Avatar>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {lastname}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {firstname}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {email}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {profiles?.filter((p) => p.id === profileId)[0]?.fullName}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {contractLabelById[contractId]}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {roleLabelById[roleId]}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Icon path={isActive ? mdiCheck : mdiClose} />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {dayjsOf(lastLogin)?.format("DD/MM/YYYY HH:mm")}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={"Editer"}>
              <div>
                <IconButton
                  path={mdiPencil}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      onEditClick(user)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Obtenir le QRcode d'accès"}>
              <div>
                <IconButton
                  path={mdiQrcode}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      createQRCode()
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Réinitialiser le code PIN"}>
              <div>
                <IconButton
                  path={mdiLockQuestion}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsResetPasswordDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Supprimer"}>
              <div>
                <IconButton
                  path={mdiTrashCanOutline}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsDeleteDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <DialogConfirm
        isDialogOpen={isDeleteDialogOpen}
        setIsDialogOpen={setIsDeleteDialogOpen}
        title={I18.t("staffTableArchiveUser")}
        onConfirm={() => onRemove(user)}
        cancelText="Annuler"
        confirmText="Supprimer"
      />
      <DialogConfirm
        isDialogOpen={isResetPasswordDialogOpen}
        setIsDialogOpen={setIsResetPasswordDialogOpen}
        title={I18.t("staffTableResetPassword")}
        onConfirm={() => onResetPassword(user)}
        cancelText="Annuler"
        confirmText="Changer"
      />
    </>
  )
}
