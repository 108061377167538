import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Center } from "../../../../components/Center"
import { mdiPencil } from "@mdi/js"
import { IconButton } from "../../../../components/Icon"
import {
  RespiratoryAllergy,
  MedicalFileDoc,
  MedicalFileIcons,
} from "../../../../domain/MedicalFile"
import { MuiContainedButton } from "../../../../components/Button"
import { SubmitHandler, useForm } from "react-hook-form"
import { RHFCheckboxes } from "../../../../components/checklist/CustomChecklist"
import { singleDoc } from "../../../../core/firebase"
import { RespiratoryAllergiesLibrary } from "../../../../domain/Medical"
import { ChecklistSelectedItem } from "./components/ChecklistSelectedItem"

interface RespiratoryAllergiesProps {
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

interface FormValues {
  respiratoryAllergies: RespiratoryAllergy[]
}

const defaultValues: FormValues = {
  respiratoryAllergies: [],
}

export const RespiratoryAllergies: React.FC<RespiratoryAllergiesProps> = ({
  medicalFile,
  setMedicalFile,
}) => {
  const [editData, setEditData] = React.useState(false)
  const [respiratoryAllergiesOptions, setRespiratoryAllergiesOptions] =
    useState<RespiratoryAllergiesLibrary>()
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues:
      { respiratoryAllergies: medicalFile.respiratoryAllergies } ||
      defaultValues,
  })

  useEffect(() => {
    singleDoc
      .medical<RespiratoryAllergiesLibrary>("respiratoryAllergies")
      .fetch()
      .then((respiratoryAllergiesOpt) => {
        setRespiratoryAllergiesOptions(respiratoryAllergiesOpt.data())
      })
  }, [])

  useEffect(() => {
    reset(
      { respiratoryAllergies: medicalFile.respiratoryAllergies } ||
        defaultValues
    )
  }, [medicalFile.respiratoryAllergies, reset])

  const createOrUpdate = (respiratoryAllergies: RespiratoryAllergy[]) => {
    return singleDoc
      .medicalFile(medicalFile.id)
      .update({
        respiratoryAllergies,
      })
      .then(() => setMedicalFile({ ...medicalFile, respiratoryAllergies }))
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    createOrUpdate(data.respiratoryAllergies)
      .then(() => setEditData(false))
      .catch((e) => {
        console.log(e)
        setEditData(false)
      })
  }

  const respiratoryAllergies = medicalFile.respiratoryAllergies

  return (
    <Grid item xs={6}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            marginLeft: 3,
            alignItems: "center",
            display: "flex",
            height: 50,
            fontSize: 15,
            fontWeight: 500,
            color: "#FFFFFFB2",
            fontVariant: "small-caps",
          }}
        >
          {"Allergies respiratoires"}
        </Typography>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <IconButton
            path={mdiPencil}
            color={"#FFFFFF8F"}
            buttonProps={{
              onClick: () => {
                console.log("edit")
                setEditData(true)
              },
            }}
          />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          padding: !editData ? 2 : 0,
        }}
      >
        {!editData ? (
          // READ MODE
          <Grid container spacing={2}>
            {respiratoryAllergies.length > 0 ? (
              respiratoryAllergies.map((respiratoryAllergy) => {
                return (
                  <Grid item xs={6} key={respiratoryAllergy.id}>
                    <ChecklistSelectedItem
                      color={"#EF4135"}
                      path={MedicalFileIcons[respiratoryAllergy.iconId]}
                      label={respiratoryAllergy.label}
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontStyle: "italic",
                    fontSize: 14,
                  }}
                >
                  Aucune allergie respiratoire
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          // EDIT MODE
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Table sx={{ minWidth: 480 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: "#FFFFFF",
                      fontSize: 14,
                      borderBottom: "1px solid #FFFFFF1F",
                      verticalAlign: "top",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {respiratoryAllergiesOptions?.respiratoryAllergies && (
                        <RHFCheckboxes
                          options={
                            respiratoryAllergiesOptions?.respiratoryAllergies
                          }
                          name="respiratoryAllergies"
                          control={control}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={1} align="center" sx={{ border: 0 }}>
                    <MuiContainedButton
                      sx={{
                        marginRight: 2,
                        height: 36,
                        backgroundColor: "transparent",
                        color: "#FFFFFF",
                        borderColor: "#90CAF980",
                        "&:hover": {
                          borderColor: "#90CAF980",
                          backgroundColor: "#90CAF980",
                        },
                      }}
                      onClick={() => {
                        console.log("cancel edit")
                        setEditData(false)
                        reset(
                          {
                            respiratoryAllergies:
                              medicalFile.respiratoryAllergies,
                          } || defaultValues
                        )
                      }}
                    >
                      {"Annuler"}
                    </MuiContainedButton>
                    <MuiContainedButton
                      sx={{ marginLeft: 2, height: 36 }}
                      type="submit"
                    >
                      {"Enregistrer"}
                    </MuiContainedButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </form>
        )}
      </Box>
    </Grid>
  )
}
