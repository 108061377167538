import React, { useState } from "react"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { Box, Button, ButtonGroup, Tab, Tabs, Typography } from "@mui/material"
import { Icon } from "../../../components/Icon"
import { mdiApps, mdiInformationOutline } from "@mdi/js"
import dayjs from "dayjs"

interface Props {
  activeTab: number
  setActiveTab: (tab: number) => void
}

export const HeaderToolbar: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const [selectedMeal, setSelectedMeal] = useState<"lunch" | "diner">("lunch")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }
  return (
    <HeaderNew title="Cuisine">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            marginLeft: 18,
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 24,
              color: "#FFFFFF",
              fontWeight: "500",
              textTransform: "capitalize",
            }}
          >
            {dayjs().format("dddd DD MMMM")}
          </Typography>
          <ButtonGroup
            variant="outlined"
            sx={{
              ".MuiButtonGroup-grouped": {
                borderColor: "#FFFFFF",
                "&:hover": {
                  color: "#364D74",
                  borderColor: "#FFFFFF",
                  backgroundColor: "#FFFFFF80",
                },
              },
            }}
          >
            <Button
              sx={{
                color: selectedMeal === "lunch" ? "#364D74" : "#FFFFFF",
                backgroundColor: selectedMeal === "lunch" ? "#FFFFFF" : "",
              }}
              onClick={() => setSelectedMeal("lunch")}
            >
              MIDI
            </Button>
            <Button
              sx={{
                color: selectedMeal === "diner" ? "#364D74" : "#FFFFFF",
                backgroundColor: selectedMeal === "diner" ? "#FFFFFF" : "",
              }}
              onClick={() => setSelectedMeal("diner")}
            >
              SOIR
            </Button>
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            height: 60,
            marginRight: 5,
          }}
        >
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab
              icon={<Icon path={mdiApps} />}
              label="Synthèse"
              iconPosition="start"
              value={0}
            />
            <Tab
              icon={<Icon path={mdiInformationOutline} />}
              label="Transmissions"
              iconPosition="start"
              value={1}
            />
          </Tabs>
        </Box>
      </Box>
    </HeaderNew>
  )
}
