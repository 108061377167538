import { FacilityDoc } from "../../domain/Facility"
import { v4 as uuid } from "uuid"

export const defaultFacility: FacilityDoc = {
  id: uuid(),
  name: "",
  pin: "",
  zones: [],
  jobs: [],
  mailNotifications: {
    douleurs: ["test@test.com"],
    elimination: [],
    lingerie: [],
    maintenance: [],
    psychisme: [],
    restauration: [],
    soins: [],
    technique: [],
    ttt: [],
  },
  dashboardProfiles: ["ide", "as", "ash"],
  failSoftModes: {
    degrade1: "Mode dégradé 1",
    standard: "Standard",
  },
  teams: [
    {
      startHour: 7,
      name: "Matin",
      id: "morning",
      stopHour: 14,
    },
    {
      startHour: 14,
      id: "afternoon",
      name: "Après-midi",
      stopHour: 21,
    },
    {
      startHour: 21,
      id: "night",
      name: "Nuit",
      stopHour: 7,
    },
  ],
  organizationId: "",
  // tasks: [],
  // quickButtons: [],
  // availableProfiles: {
  //   direction: {
  //     fullName: "Direction",
  //     name: "Direction",
  //   },
  //   cuisinier: {
  //     fullName: "Cuisinier",
  //     name: "Cuisinier",
  //   },
  //   tech: {
  //     fullName: "Technicien Maintenance",
  //     name: "Maintenance",
  //   },
  //   medecinCo: {
  //     name: "MEDCO",
  //     fullName: "Médecin coordinateur",
  //   },
  //   animateur: {
  //     fullName: "Animateur",
  //     name: "Animateur",
  //   },
  //   as: {
  //     name: "AS",
  //     fullName: "AS",
  //   },
  //   medecin: {
  //     fullName: "Médecin",
  //     name: "Médecin",
  //   },
  //   diet: {
  //     name: "Diététicienne",
  //     fullName: "Diététicienne",
  //   },
  //   cs: {
  //     fullName: "Cadre de santé",
  //     name: "CS",
  //   },
  //   ide: {
  //     fullName: "Infirmières",
  //     name: "IDE",
  //   },
  //   mdm: {
  //     fullName: "Maîtresse de maison",
  //     name: "MDM",
  //   },
  //   kine: {
  //     name: "Kiné",
  //     fullName: "Kinesitherapeute",
  //   },
  //   ash: {
  //     fullName: "ASH",
  //     name: "ASH",
  //   },
  //   psy: {
  //     name: "Psychologue",
  //     fullName: "Psychologue",
  //   },
  // },
  // contracts: {
  //   titulaire: {
  //     name: "Titulaire",
  //     fullName: "Titulaire",
  //   },
  //   snt: {
  //     name: "SNT",
  //     fullName: "Salarié non titulaire",
  //   },
  //   interimaire: {
  //     name: "Intérimaire",
  //     fullName: "Intérimaire",
  //   },
  // },
  // roles: [
  //   {
  //     id: "admin",
  //     isRestrictedAccess: false,
  //     screens: [
  //       WebScreen.HOME,
  //       WebScreen.DASHBOARD_LIST,
  //       WebScreen.DASHBOARD_KPI,
  //       WebScreen.PLANNING,
  //       WebScreen.ADMIN_USERS,
  //       WebScreen.ADMIN_RESIDENTS,
  //       WebScreen.ADMIN_FACILITY,
  //       WebScreen.PROTOCOLS,
  //     ],
  //     name: "Administrateur",
  //   },
  //   {
  //     isRestrictedAccess: false,
  //     name: "Utilisateur transverse",
  //     screens: [
  //       WebScreen.HOME,
  //       WebScreen.DASHBOARD_LIST,
  //       WebScreen.DASHBOARD_KPI,
  //       WebScreen.PLANNING,
  //       WebScreen.PROTOCOLS,
  //     ],
  //     id: "interdepartmental",
  //   },
  //   {
  //     id: "user",
  //     screens: [
  //       WebScreen.HOME,
  //       WebScreen.DASHBOARD_LIST,
  //       WebScreen.DASHBOARD_KPI,
  //       WebScreen.PLANNING,
  //       WebScreen.PROTOCOLS,
  //     ],
  //     name: "Utilisateur",
  //     isRestrictedAccess: true,
  //   },
  //   {
  //     screens: [WebScreen.HOME, WebScreen.DASHBOARD_LIST, WebScreen.PROTOCOLS],
  //     id: "support",
  //     isRestrictedAccess: true,
  //     name: "Fonction support",
  //   },
  // ],
}
