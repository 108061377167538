import React from "react"
import { useRive } from "@rive-app/react-canvas"
import { Box } from "@mui/material"
import { Center } from "./Center"

export const IskusSpinner: React.FC = () => {
  const { RiveComponent } = useRive({
    src: "./iskusLoader.riv",
    autoplay: true,
  })
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF59",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "500vh",
        zIndex: 99999999,
      }}
    >
      <Center style={{ height: "100vh" }}>
        <Box
          sx={{
            width: 300,
            height: 300,
          }}
        >
          <RiveComponent />
        </Box>
      </Center>
    </Box>
  )
}
