import React from "react"
import { CustomSelect } from "./CustomSelect"
import { SelectProps } from "@mui/material"
import { useWorkContext } from "../../contexts/work.context"

interface Props {
  value?: string
  onChange: (value: string) => void
  selectProps?: SelectProps
}

export const SelectOrganization: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
}) => {
  const { organizations } = useWorkContext()

  const organizationsSelectOptions =
    (organizations &&
      organizations.map((organization) => ({
        label: organization.name,
        value: organization.id,
      }))) ||
    []

  return (
    <CustomSelect
      label="Organisation"
      value={value}
      options={organizationsSelectOptions}
      allOptionsLabel="Toutes les organisations"
      onChange={onChange}
      selectProps={selectProps}
    />
  )
}
