import React from "react"
import { Box, Typography } from "@mui/material"

interface Props {
  title?: string
}

export const HeaderNew: React.FC<Props> = ({ title, children }) => {
  return (
    <Box
      sx={{
        height: "80px",
        pl: 3,
        width: "100%",
        backgroundColor: "#364D74",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {title && (
        <Typography
          sx={{
            color: "#FFFFFF",
            width: "180px",
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  )
}
