import React, { useEffect, useState } from "react"
import { DialogContent, Modal } from "@material-ui/core"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material"
import { Icon, IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import {
  COLLECTIONS,
  createDoc,
  dayjsOf,
  singleDoc,
  timestampOf,
} from "../../../../core/firebase"
import { MuiButton } from "../../../../components/Button"
import {
  ActivityIconId,
  CarePlanActivity,
  CarePlanActivityDoc,
  CarePlanActivityLibraryItem,
} from "../../../../domain/CarePlan"
import dayjs, { Dayjs } from "dayjs"
import { ResidentDoc } from "../../../../domain/Resident"
import { omitNils } from "../../../../core/lang"
import { ActivitiesLibrary } from "./ActivitiesLibrary"
import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { SelectTeam } from "../../../../components/select/SelectTeam"
import { MuiTimeField } from "../../../../components/TimeField"
import { SelectProfile } from "../../../../components/select/SelectProfile"
import { MuiTextField } from "../../../../components/TextField"
import { MuiDateField } from "../../../../components/DateField"
import { ActivityIcons } from "./CarePlan"
import { useWorkContext } from "../../../../contexts/work.context"

export type FormValues = {
  label: string
  instructions: string
  comment: string
  teamId: string
  executionTime: Dayjs
  profileId: string
  executionDuration: number
  startDate: Dayjs
  endDate?: Dayjs
}

export const defaultValues: FormValues = {
  label: "",
  instructions: "",
  comment: "",
  teamId: "",
  executionTime: dayjs(),
  profileId: "",
  executionDuration: 5,
  startDate: dayjs(),
  endDate: undefined,
}

interface ActivityModalProps {
  resident: ResidentDoc
  isOpen: boolean
  editModal: boolean
  handleCloseModal: () => void
  activities: CarePlanActivityDoc[]
  setActivities: (activities: CarePlanActivityDoc[]) => void
  activityToEdit?: CarePlanActivityDoc
}

export const ActivityModal: React.FC<ActivityModalProps> = ({
  resident,
  isOpen,
  editModal,
  handleCloseModal,
  activities,
  setActivities,
  activityToEdit,
}) => {
  const { profiles } = useWorkContext()
  const [searchText, setSearchText] = useState<string>("")
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedIcon, setSelectedIcon] = useState<ActivityIconId>("bed")
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormValues>(
    {
      defaultValues:
        {
          ...activityToEdit,
          executionTime:
            activityToEdit?.executionTime &&
            dayjsOf(activityToEdit.executionTime),
          startDate:
            activityToEdit?.startDate && dayjsOf(activityToEdit.startDate),
          endDate: activityToEdit?.endDate && dayjsOf(activityToEdit.endDate),
        } || defaultValues,
    }
  )

  useEffect(() => {
    const subscription = watch((value) => {
      console.log(value)
      if (value.label || value.label === "") {
        setSearchText(value.label)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const activityIcons = Object.keys(ActivityIcons) as ActivityIconId[]

  const closeModal = () => {
    setSelectedIcon("bed")
    reset(defaultValues)
    handleCloseModal()
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const createOrEditActivity = (carePlanActivity: CarePlanActivity) => {
    if (editModal && activityToEdit) {
      return singleDoc
        .carePlan(activityToEdit.id)
        .update(carePlanActivity)
        .then(() => {
          setActivities(
            activities.map((activity) => {
              if (activity.id === activityToEdit.id) {
                return {
                  ...carePlanActivity,
                  id: activityToEdit.id,
                }
              }
              return activity
            })
          )
          closeModal()
        })
    }
    return createDoc(COLLECTIONS.CARE_PLAN, carePlanActivity).then((ref) => {
      setActivities([
        ...activities,
        {
          ...carePlanActivity,
          id: ref.id,
        },
      ])
      closeModal()
    })
  }

  const handleDeleteActivity = () => {
    if (activityToEdit) {
      return singleDoc
        .carePlan(activityToEdit.id)
        .remove()
        .then(() => {
          setActivities(
            activities.filter((activity) => activity.id !== activityToEdit.id)
          )
          handleCloseDialog()
          closeModal()
        })
    }
  }

  const applyActivityItem = (care: CarePlanActivityLibraryItem) => {
    setSelectedIcon(care.iconId)
    setValue("label", care.label)
    setValue("teamId", care.teamId)
    setValue("profileId", care.profileId)
    setValue("executionDuration", care.executionDuration)
    setValue("executionTime", dayjsOf(care.executionTime)!)
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values) => {
    console.log(values)
    const carePlanActivity = omitNils({
      ...values,
      executionTime: timestampOf(values.executionTime),
      executionDuration: values.executionDuration,
      status: values.endDate ? "temporary" : "standard",
      startDate: timestampOf(values.startDate),
      endDate: values.endDate ? timestampOf(values.endDate) : null,
      iconId: selectedIcon,
      residentId: resident.id,
      facilityId: resident.facilityId,
      organizationId: resident.organizationId,
      isNursing:
        (profiles &&
          profiles.find((profil) => profil.id === values.profileId)
            ?.isNursing) ||
        false,
    })
    console.log("carePlanActivity :", carePlanActivity)
    createOrEditActivity(carePlanActivity)
  }

  useEffect(() => {
    if (editModal && activityToEdit) {
      setSelectedIcon(activityToEdit.iconId)
      reset({
        ...activityToEdit,
        executionTime:
          activityToEdit?.executionTime &&
          dayjsOf(activityToEdit.executionTime),
        startDate:
          activityToEdit?.startDate && dayjsOf(activityToEdit.startDate),
        endDate: activityToEdit?.endDate && dayjsOf(activityToEdit.endDate),
      })
    } else {
      reset(defaultValues)
    }
  }, [editModal, activityToEdit, reset])

  return (
    <Modal open={isOpen} disableEnforceFocus>
      <>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: 660,
            width: editModal ? 550 : 950,
            backgroundColor: "#374D70",
            padding: 3,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: 24,
                fontWeight: 500,
                fontVariant: "small-caps",
              }}
            >
              {editModal
                ? "Editer soin / tâche"
                : "Ajouter un soin ou une tâche"}
            </Typography>
            <IconButton
              buttonProps={{
                onClick: () => closeModal(),
              }}
              path={mdiWindowClose}
              color={"#FFFFFF"}
            />
          </Box>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={editModal ? 12 : 7}>
                {/* ############## NEW ACTIVITY FORM ################### */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="label"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <MuiTextField
                          fullWidth
                          required={true}
                          type="text"
                          label={
                            editModal
                              ? "Libellé de l'activité"
                              : "Commencez à saisir, puis sélectionnez l'activité souhaitée"
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="instructions"
                      control={control}
                      render={({ field }) => (
                        <MuiTextField
                          fullWidth
                          multiline
                          rows={2}
                          type="text"
                          label="Consignes optionnelles"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="teamId"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <SelectTeam
                          facilityId={resident.facilityId}
                          {...field}
                          selectProps={{
                            style: { width: "100%" },
                            required: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="executionTime"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiTimeField
                          {...field}
                          required={true}
                          label="Heure cible"
                          format="HH:mm"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="profileId"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <SelectProfile
                          {...field}
                          selectProps={{
                            style: { width: "100%" },
                            required: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="executionDuration"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <MuiTextField
                          label="Durée estimée (min)"
                          required={true}
                          type="number"
                          {...field}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            field.onChange(e.target.valueAsNumber)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiDateField
                          {...field}
                          required={true}
                          label="Applicable à partir du"
                          format="DD-MM-YYYY"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <MuiDateField
                          {...field}
                          label="Applicable jusqu'au"
                          format="DD-MM-YYYY"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: "#FFFFFFB2",
                          marginBottom: 2,
                        }}
                      >
                        {"Pictogramme représentant le soin / la tâche"}
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        {activityIcons.map((iconId) => (
                          <Button
                            key={iconId}
                            sx={{
                              display: "flex",
                              width: 40,
                              minWidth: 40,
                              height: 40,
                              borderRadius: 1,
                              border: "1px solid",
                              borderColor:
                                selectedIcon === iconId
                                  ? "#FFFFFF"
                                  : "#FFFFFF33",
                              alignItems: "center",
                              justifyContent: "center",
                              "&:hover": {
                                borderColor: "#FFFFFF",
                                backgroundColor: "#FFFFFF33",
                              },
                            }}
                            onClick={() => setSelectedIcon(iconId)}
                          >
                            <Icon
                              path={ActivityIcons[iconId]}
                              color={
                                selectedIcon === iconId
                                  ? "#FFFFFF"
                                  : "#FFFFFF33"
                              }
                              size={1}
                            />
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {/* ############## NEW ACTIVITY FORM ################### */}
              </Grid>
              {!editModal && (
                <Grid item xs={5}>
                  <Box sx={{ height: "100%", backgroundColor: "#FFFFFF1A" }}>
                    <ActivitiesLibrary
                      applyActivityItem={applyActivityItem}
                      searchText={searchText}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: 2,
                width: "100%",
                alignItems: "center",
                marginTop: 4,
              }}
            >
              {editModal && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: 24,
                    cursor: "pointer",
                    color: "#FF4B4B",
                    textDecoration: "underline",
                  }}
                  onClick={() => setIsDialogOpen(true)}
                >
                  {"Supprimer soin / tâche"}
                </Typography>
              )}
              <MuiButton
                sx={{
                  height: 56,
                  width: 95,
                }}
                onClick={() => {
                  closeModal()
                }}
              >
                Annuler
              </MuiButton>
              <MuiButton
                sx={{
                  height: 56,
                  width: 112,
                }}
                type="submit"
              >
                {editModal ? "Enregistrer" : "Ajouter"}
              </MuiButton>
            </Box>
          </form>
        </Box>
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{ sx: { backgroundColor: "#374D70" } }}
        >
          <DialogTitle sx={{ color: "#FFFFFF" }}>{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "#FFFFFF" }}>
              {`Confirmez-vous la suppression de l'activité "${activityToEdit?.label}" pour ${resident.firstname} ${resident.lastname} ?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={() => handleCloseDialog()}>Annuler</MuiButton>
            <MuiButton onClick={() => handleDeleteActivity()}>
              Supprimer
            </MuiButton>
          </DialogActions>
        </Dialog>
      </>
    </Modal>
  )
}
