import React, { ReactElement } from "react"
import { Box, Chip, Tooltip, Typography } from "@mui/material"
import { useWorkContext } from "../../../../contexts/work.context"
import { dayjsOf } from "../../../../core/firebase"
import { DietEntry, MedicalFileDoc } from "../../../../domain/MedicalFile"
import dayjs from "dayjs"

const today = dayjs()

const countDiets = (medicalFiles: MedicalFileDoc[] | undefined) => {
  if (!medicalFiles) return 0
  return medicalFiles.length
}

const ChipTooltip: React.FC<{
  medicalFiles: MedicalFileDoc[]
  children: ReactElement
}> = ({ medicalFiles, children }) => {
  const { diets } = useWorkContext()
  // calculate the number of for each combination of diet and allergies, taking the temporaryDiet when it exist, the standardDiet otherwise
  const countDietsCombinaison = (
    medicalFiles: MedicalFileDoc[]
  ): { [key: string]: number } => {
    if (!diets) return {}
    const dietsCombinaison = medicalFiles.map((m) => {
      const diet =
        (m.temporaryDiet &&
          dayjsOf(m.temporaryDiet.endDate)! >= today &&
          m.temporaryDiet.diet &&
          m.temporaryDiet.diet?.length > 0 &&
          m.temporaryDiet?.diet) ||
        m.standardDiet?.diet
      const allergiesRestrictions =
        (m.temporaryDiet &&
          dayjsOf(m.temporaryDiet.endDate)! >= today &&
          m.temporaryDiet.allergiesRestrictions &&
          m.temporaryDiet?.allergiesRestrictions) ||
        m.standardDiet?.allergiesRestrictions
      return { diet, allergiesRestrictions }
    })
    const dietsCombinaisonCount = dietsCombinaison.reduce(
      (acc: { [key: string]: number }, curr) => {
        const key = `${curr.diet?.map(
          (diet: DietEntry) =>
            diets.diets.filter((d) => d.id === diet.id)[0].label
        )}-${curr.allergiesRestrictions?.map(
          (allergie: DietEntry) =>
            diets.allergiesRestrictions.filter((d) => d.id === allergie.id)[0]
              .label
        )}` as keyof typeof dietsCombinaisonCount
        acc[key] = (acc[key] || 0) + 1
        return acc as { [key: string]: number }
      },
      {}
    )
    return dietsCombinaisonCount
  }
  const dietsCount = countDietsCombinaison(medicalFiles)
  const combinationKeys = Object.keys(dietsCount)

  return (
    <Box
      style={{
        position: "relative",
      }}
    >
      <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#374D70",
              color: "#FFFFFF",
              fontSize: 16,
              maxWidth: 350,
              paddingRight: 2,
              paddingLeft: 2,
            },
          },
        }}
        title={
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: "100" }}>
            {combinationKeys.map((key) => {
              const [diet, allergiesRestrictions] = key.split("-")

              return (
                <Box
                  key={diet}
                  sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ marginRight: 2 }}>
                    {dietsCount[key]}
                  </Typography>
                  <Box>
                    {diet !== "undefined" && <Typography>{diet}</Typography>}
                    {allergiesRestrictions !== "undefined" && (
                      <Typography>{allergiesRestrictions}</Typography>
                    )}
                  </Box>
                </Box>
              )
            })}
          </Box>
        }
      >
        {children}
      </Tooltip>
      <Typography
        style={{
          position: "absolute",
          left: 60,
          top: 0,
          color: "#FFFFFF",
          fontSize: 24,
        }}
      >
        *
      </Typography>
    </Box>
  )
}

const BaseChip: React.FC<{
  isTotal?: boolean
  number: number
  color: string
}> = ({ isTotal, number, color }) => {
  const isEmpty = number === 0
  const bgColor = isEmpty ? "#FFFFFF00" : color
  const borderColor = isEmpty ? color : "transparent"
  return (
    <Chip
      label={isEmpty ? "" : number}
      sx={{
        ".MuiChip-label": {
          color: isTotal ? "#364D74" : "#FFFFFFDE",
          fontSize: 16,
        },
        backgroundColor: bgColor,
        borderWidth: isEmpty ? 1 : 0,
        borderStyle: "solid",
        borderColor: borderColor,
        borderRadius: 100,
        width: 54,
        height: 37,
      }}
    />
  )
}

export const NumberChip: React.FC<{
  medicalFiles?: MedicalFileDoc[]
  color?: string | undefined
  alpha?: number
  isTotal?: boolean
}> = ({ medicalFiles, color, alpha, isTotal }) => {
  // https://stackoverflow.com/questions/50890241/programmatically-add-opacity-to-a-color-in-typescript
  function addAlpha(color: string, opacity: number): string {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + _opacity.toString(16).toUpperCase()
  }

  const newColor =
    color && alpha ? addAlpha(color, alpha) : color ? color : "#FFFFFFB2"

  const number = countDiets(medicalFiles)

  const specialDiets = medicalFiles?.filter(
    (m) =>
      (m.temporaryDiet?.allergiesRestrictions &&
        m.temporaryDiet?.allergiesRestrictions.length > 0) ||
      (m.standardDiet?.allergiesRestrictions &&
        m.standardDiet.allergiesRestrictions.length > 0) ||
      (m.temporaryDiet?.diet && m.temporaryDiet.diet.length > 0) ||
      (m.standardDiet?.diet && m.standardDiet.diet.length > 0)
  )

  console.log("specialDiets", specialDiets)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isTotal && specialDiets && specialDiets.length > 0 ? (
        <ChipTooltip medicalFiles={specialDiets}>
          <Chip
            label={number}
            sx={{
              ".MuiChip-label": {
                color: isTotal ? "#364D74" : "#FFFFFFDE",
                fontSize: 16,
              },
              backgroundColor: color,
              borderRadius: 100,
              width: 54,
              height: 37,
            }}
          />
        </ChipTooltip>
      ) : (
        <BaseChip isTotal={isTotal} number={number} color={newColor} />
      )}
    </Box>
  )
}
