import { QueryDocumentSnapshot } from "firebase/firestore"
import React, { useEffect, useMemo } from "react"
import {
  CommentCategories,
  Message,
  MessageDoc,
  colorOfLevel,
} from "../../../domain/Message"
import { useResidents } from "../../../contexts/residents.context"
import { Box, Typography, Chip } from "@mui/material"
import { Avatar } from "@mui/material"
import { capitalize } from "lodash"
import { NotificationInfos } from "./NotificationInfos"
import { singleDoc } from "../../../core/firebase"
import { Constants } from "../../../domain/Constants"
import { NotifConstant } from "./NotifConstant"
import { Card } from "../../../components/Card"

interface NewMessageRowProps {
  message: QueryDocumentSnapshot<MessageDoc>
  setActiveMessage: (message: Message) => void
  setIsNotificationModalOpen: (isOpen: boolean) => void
}

const NotifRow: React.FC<{
  category: string
  comment: string
  tags?: string[]
}> = ({ category, comment, tags }) => {
  if (!comment && !tags) {
    return null
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        maxWidth: 500,
      }}
    >
      <Typography
        sx={{
          color: CommentCategories[category].color,
          fontSize: 16,
          textOverflow: "ellipsis",
        }}
      >
        {CommentCategories[category].shortHeader}
      </Typography>
      {tags && tags.length > 0 ? (
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: 16,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          • {tags.map((tag) => `${tag}. `)}
        </Typography>
      ) : (
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: 16,
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          • {comment}
        </Typography>
      )}
    </Box>
  )
}

export const NewMessageRow: React.FC<NewMessageRowProps> = ({
  message,
  setActiveMessage,
  setIsNotificationModalOpen,
}) => {
  const [constants, setConstants] = React.useState<undefined | Constants>()

  const messageData = useMemo(() => message.data(), [message])
  const { residentById } = useResidents()
  const resident = residentById[messageData.residentId]
  const alertColor = colorOfLevel(messageData.alertLevel).end
  const categoryKeys = CommentCategories && Object.keys(CommentCategories)

  useEffect(() => {
    async function getConstants() {
      if (!messageData || !messageData.constantsDocId) return
      const newConstants = await singleDoc
        .constants(messageData.constantsDocId)
        .fetch()
        .then((res) => res.data())
      setConstants(newConstants)
    }
    getConstants()
  }, [messageData])

  const handleClick = () => {
    setActiveMessage(messageData)
    setIsNotificationModalOpen(true)
  }

  if (!resident) return null

  return (
    <Card
      boxProps={{ onClick: () => handleClick(), style: { cursor: "pointer" } }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 340,
          width: 340,
          marginRight: 2,
        }}
      >
        <Avatar
          variant={"rounded"}
          src={resident.avatarUrl}
          sx={{
            backgroundColor: resident.color,
            height: 65,
            width: 65,
            marginRight: 1,
          }}
        >
          {resident.initials}
        </Avatar>
        <Box sx={{ width: 125 }}>
          <Typography sx={{ color: "#FFFFFF", fontSize: 16 }}>
            {resident.firstname}
            <br />
            {resident.lastname}
          </Typography>
          <Typography sx={{ color: "#FFFFFF8A", fontSize: 16 }}>
            {resident.room}
          </Typography>
        </Box>
        <Box>
          <Chip
            label={capitalize(messageData.theme)}
            sx={{
              color: "#FFFFFFDE",
              backgroundColor: alertColor,
              height: 32,
              fontSize: 15,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          alignContent: "flex-start",
          alignSelf: "flex-start",
          overflow: "hidden",
        }}
      >
        {constants ? (
          <NotifConstant constants={constants} />
        ) : (
          categoryKeys.map((category) => (
            <NotifRow
              key={category}
              category={category}
              comment={messageData.comments[category]}
              tags={
                messageData.selectedTags && messageData.selectedTags[category]
              }
            />
          ))
        )}
      </Box>
      <NotificationInfos
        user={messageData.user}
        creationDate={messageData.creationDate}
      />
    </Card>
  )
}
