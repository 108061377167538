import React, { useState } from "react"
import {
  TableRow,
  TableCell,
  Box,
  Tooltip,
  Avatar,
  Typography,
  CardActionArea,
} from "@mui/material"
import { Icon, IconButton } from "../../../../components/Icon"
import { mdiFile, mdiPencil, mdiTrashCanOutline, mdiUpload } from "@mdi/js"
import {
  AdministrativeFileDoc,
  LegalProtection,
} from "../../../../domain/AdministrativeFile"
import { DialogConfirm } from "../../../../components/DialogConfirm"
import { Timestamp, dayjsOf, singleDoc } from "../../../../core/firebase"
import { FileUploader } from "../../../../components/FileUploader"
import { useWorkContext } from "../../../../contexts/work.context"
import firebase from "firebase/compat"
import { useAuthUser } from "../../../../core/auth"
import { omitNils } from "../../../../core/lang"

interface ProtectionRowProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
  legalProtection: LegalProtection
  index: number
  onEditClick: (index: number) => void
  onDeleteClick: (index: number) => void
}

const formatDate = (date: Timestamp) => {
  return `Le ${dayjsOf(date)!.format("DD/MM/YYYY à HH:mm")}`
}

export const ProtectionRow: React.FC<ProtectionRowProps> = ({
  adminFile,
  setAdminFile,
  legalProtection,
  index,
  onEditClick,
  onDeleteClick,
}) => {
  const { label, status, fileUrl, updatedAt, updatedBy } = legalProtection
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const date = formatDate(updatedAt)
  const { facility, facilities } = useWorkContext()
  const { user } = useAuthUser()
  const residentName = `${adminFile.identity.lastname}-${adminFile.identity.firstname}`
  const facilityName = facilities
    ? facilities[adminFile.facilityId].name
    : facility?.name
  const prefix = `${facilityName}-${residentName}`

  const handleFileChange = (fileUrl: string) => {
    if (!user) return
    const updates = {
      updatedBy: omitNils({
        id: user.id,
        avatarUrl: user.avatarUrl,
        initials: user.initials,
        color: user.color,
        firstname: user.firstname,
        lastname: user.lastname,
        profileId: user.profileId,
      }),
      updatedAt: firebase.firestore.Timestamp.now(),
    }
    singleDoc.administrativeFile(adminFile.id).update({
      legalProtections: adminFile.legalProtections?.map((protection, i) =>
        i === index ? { ...protection, ...updates, fileUrl } : protection
      ),
    })
    setAdminFile({
      ...adminFile,
      legalProtections: adminFile.legalProtections?.map((protection, i) =>
        i === index ? { ...protection, ...updates, fileUrl } : protection
      ),
    })
  }

  return (
    <>
      <TableRow
        hover={true}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {label}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {status}
        </TableCell>

        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {fileUrl && (
            <CardActionArea
              href={fileUrl || ""}
              target="_blank"
              sx={{
                cursor: "pointer",
                width: 20,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon path={mdiFile} />
            </CardActionArea>
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {date}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Avatar
              src={updatedBy.avatarUrl}
              sx={{ backgroundColor: updatedBy.color }}
            >
              {updatedBy.initials}
            </Avatar>
            <Typography sx={{ color: "#FFFFFF", fontSize: 14 }}>
              {updatedBy.firstname} {updatedBy.lastname}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip title={"Editer"}>
              <div>
                <IconButton
                  path={mdiPencil}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      onEditClick(index)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Charger document"}>
              <div>
                <FileUploader
                  filenamePrefix={`legalProtection-${prefix}`}
                  returnedOption="url"
                  onChange={(fileUrl) => handleFileChange(fileUrl)}
                >
                  <Icon path={mdiUpload} color={"#FFFFFF"} />
                </FileUploader>
              </div>
            </Tooltip>
            <Tooltip title={"Supprimer"}>
              <div>
                <IconButton
                  path={mdiTrashCanOutline}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsDeleteDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <DialogConfirm
        isDialogOpen={isDeleteDialogOpen}
        setIsDialogOpen={setIsDeleteDialogOpen}
        title={"Supprimer cette ligne ?"}
        onConfirm={() => onDeleteClick(index)}
        cancelText="Annuler"
        confirmText="Supprimer"
      />
    </>
  )
}
