import React from "react"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectConsentDocStatus: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  formControlProps,
}) => {
  const consentDocStatusSelectOptions = [
    { label: "Accordé", value: "Accordé" },
    { label: "Refusé", value: "Refusé" },
    { label: "Demandé", value: "Demandé" },
  ]

  return (
    <CustomSelect
      label="Statut"
      value={value}
      options={consentDocStatusSelectOptions}
      allOptionsLabel="Sélectionnez un statut"
      onChange={onChange}
      restricted={false}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
