import React, { useState } from "react"
import { Box } from "@material-ui/core"
import { Page } from "../../components/page/Page"
import { HeaderToolbar } from "./components/HeaderToolbar"
import { Typography } from "@mui/material"
import { KitchenSummary } from "./components/kitchenSummary/KitchenSummary"

const TabPanel: React.FC<{
  activeTab: number
  index: number
  children: React.ReactNode
}> = ({ activeTab, index, children }) => {
  return (
    <Box hidden={activeTab !== index} sx={{}}>
      {children}
    </Box>
  )
}

export const Kitchen: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0)
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderToolbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <Page>
        <TabPanel activeTab={activeTab} index={0}>
          <KitchenSummary />
        </TabPanel>
        <TabPanel activeTab={activeTab} index={1}>
          <Typography>Transmissions</Typography>
        </TabPanel>
      </Page>
    </Box>
  )
}
