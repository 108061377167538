import React from "react"
import { Box, Chip } from "@mui/material"

export const Badge: React.FC<{ color: string; size: number }> = ({
  color,
  size,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Chip
        sx={{
          backgroundColor: color,
          borderRadius: 100,
          width: size,
          height: size,
        }}
      />
    </Box>
  )
}
