import React from "react"
import { Cell } from "../../../../components/table/TableCell"
import { TableHead, TableRow } from "@mui/material"

export const TreatmentsHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <Cell>Produit</Cell>
        <Cell>Matin</Cell>
        <Cell>Midi</Cell>
        <Cell>Soir</Cell>
        <Cell>Coucher</Cell>
        <Cell>Voie & Consignes</Cell>
        <Cell props={{ align: "center" }}>ALD</Cell>
        <Cell props={{ align: "center" }}>Renouvelable</Cell>
        <Cell props={{ align: "center" }}>Edition</Cell>
      </TableRow>
    </TableHead>
  )
}
