import React, { useEffect, useState } from "react"
import { ModalConfig } from "./ResidentPlanning"
import { IskusSpinner } from "../../../../components/IskusSpinner"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { IconButton } from "../../../../components/Icon"
import { Event, EventDoc } from "../../../../domain/Event"
import dayjs, { Dayjs } from "dayjs"
import { Controller, useForm, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { mdiWindowClose } from "@mdi/js"
import { MuiTextField } from "../../../../components/TextField"
import { SelectEventCategory } from "../../../../components/select/SelectEventCategory"
import { MuiDateTimePicker } from "../../../../components/DateField"
import { renderTimeViewClock } from "@mui/x-date-pickers"
import {
  COLLECTIONS,
  createDoc,
  dayjsOf,
  singleDoc,
  timestampOf,
} from "../../../../core/firebase"
import { omitNils } from "../../../../core/lang"
import { ResidentDoc } from "../../../../domain/Resident"
import { DialogConfirm } from "../../../../components/DialogConfirm"

interface EventModalProps {
  resident: ResidentDoc
  config: ModalConfig
  events: EventDoc[]
  onClose: (config: ModalConfig) => void
  onSubmit: (event: EventDoc[], config: ModalConfig) => void
}

interface FormValues {
  label: string
  category: string
  startDate: Dayjs
  endDate: Dayjs
  description: string
}

const defaultValues: FormValues = {
  label: "",
  category: "",
  startDate: dayjs(),
  endDate: dayjs(),
  description: "",
}

export const EventModal: React.FC<EventModalProps> = ({
  resident,
  config,
  events,
  onClose,
  onSubmit,
}) => {
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormValues>(
    {
      defaultValues:
        {
          ...config.event,
          startDate:
            config.event?.startDate && dayjsOf(config.event?.startDate),
          endDate: config.event?.endDate && dayjsOf(config.event?.endDate),
        } || defaultValues,
    }
  )
  const watchStartDate = watch("startDate")

  useEffect(() => {
    if (config.event) {
      reset({
        ...config.event,
        startDate: config.event?.startDate && dayjsOf(config.event?.startDate),
        endDate: config.event?.endDate && dayjsOf(config.event?.endDate),
      })
    } else {
      reset(defaultValues)
    }
  }, [config.event, reset])

  const createOrUpdate = (event: Event): Promise<EventDoc[]> => {
    console.log("event", event)
    console.log("config", config)
    if (config.isUpdate && config.event) {
      return singleDoc
        .event(config.event.id)
        .update(event)
        .then(() =>
          events
            ? events.map((e) => {
                if (e.id === config.event!.id) {
                  return {
                    ...event,
                    id: config.event!.id,
                  }
                } else {
                  return e
                }
              })
            : [
                {
                  ...event,
                  id: config.event!.id,
                },
              ]
        )
    } else {
      console.log("creating doc in collection Events")
      return createDoc(COLLECTIONS.EVENTS, event).then((ref) => {
        if (!events) {
          return [
            {
              ...event,
              id: ref.id,
            },
          ]
        }
        events.push({
          ...event,
          id: ref.id,
        })
        return events
      })
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    console.log("data", data)
    const newEvent: Event = omitNils({
      label: data.label,
      category: data.category,
      startDate: timestampOf(data.startDate),
      endDate: timestampOf(data.endDate),
      description: data.description,
      recipients: [resident.id],
    })
    setIsConfirmationLoading(true)
    createOrUpdate(newEvent)
      .then((eventDocs) => {
        onSubmit(eventDocs, config)
        setIsConfirmationLoading(false)
        handleClose()
      })
      .catch((error) => {
        console.log(error)
        setIsConfirmationLoading(false)
        handleClose()
      })
  }

  const handleClose = () => {
    reset(defaultValues)
    onClose({ ...config, isOpen: false })
  }

  const handleDelete = () => {
    if (!config.event) return
    setIsConfirmationLoading(true)
    singleDoc
      .event(config.event.id)
      .remove()
      .then(() => {
        setIsConfirmationLoading(false)
        onClose({ ...config, isOpen: false })
      })
      .catch((error) => {
        console.log(error)
        setIsConfirmationLoading(false)
      })
  }

  useEffect(() => {
    if (!watchStartDate || config.isUpdate) return
    setValue("endDate", watchStartDate.add(1, "hour"))
  }, [setValue, watchStartDate, config.isUpdate])

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <>
          <Dialog
            maxWidth="md"
            fullWidth
            open={config.isOpen}
            onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
          >
            <DialogContent
              sx={{
                backgroundColor: "#374D70",
                padding: 3,
                borderWidth: 1,
                borderColor: "#FFFFFF",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: 24,
                      fontWeight: 500,
                      fontVariant: "small-caps",
                    }}
                  >
                    {config.isUpdate
                      ? "Editer un évènement"
                      : "Nouvel évènement"}
                  </Typography>
                  <IconButton
                    buttonProps={{
                      onClick: () => handleClose(),
                    }}
                    path={mdiWindowClose}
                    color={"#FFFFFF"}
                  />
                </Box>
                {/* FORM FIELDS */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name="category"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <SelectEventCategory
                          {...field}
                          selectProps={{ style: { width: 400 } }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="label"
                      control={control}
                      render={({ field }) => (
                        <MuiTextField
                          multiline={true}
                          fullWidth
                          type="text"
                          label={"Intitulé (optionnel)"}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiDateTimePicker
                          {...field}
                          viewRenderers={{
                            hours: (props) =>
                              renderTimeViewClock({ ...props, ampm: false }),
                            minutes: (props) =>
                              renderTimeViewClock({ ...props, ampm: false }),
                          }}
                          label="Date et heure de début"
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiDateTimePicker
                          {...field}
                          viewRenderers={{
                            hours: (props) =>
                              renderTimeViewClock({ ...props, ampm: false }),
                            minutes: (props) =>
                              renderTimeViewClock({ ...props, ampm: false }),
                          }}
                          label="Date et heure de fin"
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <MuiTextField
                          multiline={true}
                          fullWidth
                          rows={2}
                          type="text"
                          label={"Informations complémentaires"}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* BUTTONS */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: config.isUpdate
                        ? "space-between"
                        : "flex-end",
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    {config.isUpdate && (
                      <Typography
                        sx={{
                          left: 24,
                          cursor: "pointer",
                          color: "#FF4B4B",
                          textDecoration: "underline",
                        }}
                        onClick={() => setDialogConfirm(true)}
                      >
                        {"Supprimer l'évènement"}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                      }}
                    >
                      <MuiButton
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        Annuler
                      </MuiButton>
                      <MuiButton type="submit">Ok</MuiButton>
                    </Box>
                  </Box>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
          <DialogConfirm
            isDialogOpen={dialogConfirm}
            setIsDialogOpen={() => setDialogConfirm(false)}
            title={"Supprimer l'évènement"}
            onConfirm={() => handleDelete()}
            cancelText="Annuler"
            confirmText="Supprimer"
          />
        </>
      )}
    </>
  )
}
