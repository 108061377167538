import React from "react"
import { CustomSelect } from "./CustomSelect"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  onChange?: (value: string) => void
  value?: string
  selectProps?: SelectProps
  formControlProps?: FormControlProps
}

export const SelectTies: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  formControlProps,
}) => {
  const tiesSelectOptions = [
    { label: "Fils", value: "Fils" },
    { label: "Fille", value: "Fille" },
    { label: "Conjoint(e )", value: "Conjoint(e )" },
    { label: "Sœur", value: "Sœur" },
    { label: "Frère", value: "Frère" },
    { label: "Famille proche", value: "Famille proche" },
    { label: "Famille éloignée", value: "Famille éloignée" },
    { label: "Ami(e )", value: "Ami(e )" },
    { label: "Autre", value: "Autre" },
  ]

  return (
    <CustomSelect
      label="Lien de parenté"
      value={value}
      options={tiesSelectOptions}
      allOptionsLabel="Sélectionnez un lien de parenté"
      onChange={onChange}
      restricted={false}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
