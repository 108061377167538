import React, { useState } from "react"
import { Box, TableCell, TableRow, Tooltip } from "@mui/material"
import { IconButton } from "../../../components/Icon"
import { mdiPencil, mdiTrashCanOutline } from "@mdi/js"
import { DialogConfirm } from "../../../components/DialogConfirm"
import { OrganizationDoc } from "../../../domain/Organization"

interface TableRowProps {
  organization: OrganizationDoc
  onEditClick: (organization: OrganizationDoc) => void
  onRemove: (organization: OrganizationDoc) => void
}

export const Row: React.FC<TableRowProps> = ({
  organization,
  onEditClick,
  onRemove,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  return (
    <>
      <TableRow
        hover={true}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {organization.id}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          {organization.name}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: "#FFFFFF",
            fontSize: 14,
            borderBottom: "1px solid #FFFFFF1F",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={"Editer"}>
              <div>
                <IconButton
                  path={mdiPencil}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      onEditClick(organization)
                    },
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip title={"Supprimer"}>
              <div>
                <IconButton
                  path={mdiTrashCanOutline}
                  color={"#FFFFFF"}
                  buttonProps={{
                    onClick: () => {
                      setIsDeleteDialogOpen(true)
                    },
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <DialogConfirm
        isDialogOpen={isDeleteDialogOpen}
        setIsDialogOpen={setIsDeleteDialogOpen}
        title={`Supprimer l'organisation ${organization.name} ?`}
        onConfirm={() => onRemove(organization)}
        cancelText="Annuler"
        confirmText="Supprimer"
      />
    </>
  )
}
