import React, { useEffect, useState } from "react"
import { ResidentDoc } from "../../../../domain/Resident"
import { MedicalFile, MedicalFileDoc } from "../../../../domain/MedicalFile"
import { COLLECTIONS, createDoc, singleDoc } from "../../../../core/firebase"
import { Box, Button } from "@mui/material"
import {
  mdiPlus,
  mdiPill,
  mdiSilverware,
  mdiBandage,
  mdiAlertRhombusOutline,
  mdiNeedle,
  mdiWheelchairAccessibility,
  mdiInformationOutline,
} from "@mdi/js"
import { Icon } from "../../../../components/Icon"
import { Diet } from "./Diet"
import { omitNils } from "../../../../core/lang"
import { SubHeaderIcon } from "../../../../components/SubHeaderIcon"
import { Treatments } from "./Treatments"
import { Pathologies } from "./Pathologies"
import { Vaccines } from "./Vaccines"
import { DailyLife } from "./DailyLife"
import { Dependencies } from "./Dependencies"
import { Allergies } from "./Allergies"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface MedicalFileProps {
  resident: ResidentDoc
}

export const MedFile: React.FC<MedicalFileProps> = ({ resident }) => {
  const [medicalFile, setMedicalFile] = useState<MedicalFileDoc>()
  const [editDiet, setEditDiet] = useState<boolean>(false)
  const [editTempDiet, setEditTempDiet] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const createMedicalFile = (medicalFile: MedicalFile) => {
    const full: MedicalFile = omitNils({
      ...medicalFile,
    })
    return createDoc(COLLECTIONS.MEDICAL_FILE, full).then((ref) => ({
      ...full,
      id: ref.id,
    }))
  }

  const handleCreateMedicalFile = () => {
    console.log("create medical file")
    const medicalFile: MedicalFile = omitNils({
      residentId: resident.id,
      facilityId: resident.facilityId,
      organizationId: resident.organizationId,
      zoneId: resident.zoneId,
      pathologies: [],
      vaccines: [],
      dailyLife: [],
      dependencies: [],
      skinAllergies: [],
      drugAllergies: [],
      respiratoryAllergies: [],
      // standardDiet: {
      //   diet: [],
      //   allergiesRestrictions: [],
      //   feedingMethod: undefined,
      //   texture: undefined,
      //   hydratation: [],
      // },
    })
    createMedicalFile(medicalFile).then((medicalFile) => {
      singleDoc
        .resident(resident.id)
        .update({ medicalFileId: medicalFile.id })
        .then(() => {
          setMedicalFile(medicalFile)
        })
    })
  }

  useEffect(() => {
    setIsLoading(true)
    if (resident.medicalFileId) {
      singleDoc
        .medicalFile(resident.medicalFileId)
        .fetch()
        .then((medFile) => {
          if (medFile) {
            setMedicalFile(medFile.data())
            setIsLoading(false)
          } else {
            console.log("no medical file")
            setIsLoading(false)
          }
        })
    } else {
      setMedicalFile(undefined)
      setIsLoading(false)
    }
  }, [editDiet, editTempDiet, resident]) // TODO : check if editDiet and editTempDiet are necessary

  return (
    <Box sx={{ marginTop: 5, marginBottom: 5 }}>
      {isLoading ? (
        <IskusSpinner />
      ) : medicalFile ? (
        <>
          <SubHeaderIcon
            text={"Traitements suivis"}
            color={"#90CAF9"}
            path={mdiPill}
          />
          <Treatments medicalFile={medicalFile} />
          <SubHeaderIcon
            text={"Pathologies"}
            color={"#F2932B"}
            path={mdiBandage}
          />
          <Pathologies
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
          />

          <SubHeaderIcon
            text={"Alimentation"}
            color={"#00B25C"}
            path={mdiSilverware}
          />
          <Diet
            resident={resident}
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
          />
          <SubHeaderIcon
            text={"Autres allergies"}
            color={"#F44336"}
            path={mdiAlertRhombusOutline}
          />
          <Allergies
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
          />
          <SubHeaderIcon
            text={"Vaccinations"}
            color={"#90CAF9"}
            path={mdiNeedle}
          />
          <Vaccines medicalFile={medicalFile} setMedicalFile={setMedicalFile} />
          <SubHeaderIcon
            text={"Dépendances"}
            color={"#F2932B"}
            path={mdiWheelchairAccessibility}
          />
          <Dependencies
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
          />
          <SubHeaderIcon
            text={"Vie quotidienne"}
            color={"#00B25C"}
            path={mdiInformationOutline}
          />
          <DailyLife
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
          />
        </>
      ) : (
        <Button
          variant="contained"
          startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
          style={{ marginTop: 50 }}
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#001C41",
            fontWeight: 500,
            fontSize: 14,
            textTransform: "capitalize",
          }}
          onClick={() => {
            handleCreateMedicalFile()
          }}
        >
          Créer dossier médical
        </Button>
      )}
    </Box>
  )
}
