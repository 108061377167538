import React from "react"
import { Box, TableCell, TableRow } from "@mui/material"

export const RowSeparator: React.FC = () => {
  return (
    <TableRow>
      <TableCell sx={{ border: 0, padding: 0 }}>
        <Box sx={{ borderBottom: "1px solid #FFFFFF1F", marginY: 0.5 }} />
      </TableCell>
      <TableCell sx={{ border: 0, padding: 0 }}>
        <Box sx={{ borderBottom: "1px solid #FFFFFF1F", marginY: 0.5 }} />
      </TableCell>
    </TableRow>
  )
}
