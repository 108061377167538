import React, { useEffect, useMemo, useState } from "react"
import dayjs from "dayjs"
import { Button, Collapse, message, Typography } from "antd"
import { onSnapshot } from "@firebase/firestore"
import { getDocs, query, where } from "firebase/firestore"
import { Header } from "../../components/header/Header"
import { Page } from "../../components/page/Page"
import { bem } from "../../styles/bem"
import { Panel } from "../../components/panel/Panel"
import {
  COLLECTIONS,
  converter,
  createDoc,
  FACILITY_COLLECTIONS,
  singleDoc,
  timestampOf,
} from "../../core/firebase"
import { HeaderToolbar } from "./components/HeaderToolbar"
import { useWorkContext } from "../../contexts/work.context"
import { Message, MessageDoc } from "../../domain/Message"
import { zonesProfilesAlerts, ZonesProfilesAlerts } from "./zonesProfilesAlerts"
import { ZoneProfile } from "./components/ZoneProfile"
import { Timesheet, TimesheetDoc } from "../../domain/Timesheet"
import { profilesPresenceFromTimesheets } from "./profilesPresenceFromTimesheets"
import { ProfilesAlerts, profilesAlerts } from "./profilesAlerts"
import "./organisation.less"
import { ProfileId } from "../../domain/Profile"
import { orEmpty } from "../../core/lang"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import { SelectProfile } from "../../components/select/SelectProfile"
import { SelectResident } from "../../components/select/SelectResident"
import { ResidentDoc } from "../../domain/Resident"
import { I18 } from "../../core/i18n"
import { useAuthUser } from "../../core/auth"
import firebase from "firebase/compat"

const b = "organisation"

export const Organisation: React.FC = () => {
  usePageTitle(WebScreen.PLANNING)
  const { facility, profiles } = useWorkContext()
  const { user } = useAuthUser()

  const [messages, setMessages] = useState<MessageDoc[]>()
  const [timesheets, setTimesheets] = useState<TimesheetDoc[]>()
  const [profile, setProfile] = useState<string>("")
  const [resident, setResident] = useState<ResidentDoc | undefined>()
  const [isTimesheetButtonLoading, setIsTimesheetButtonLoading] =
    useState<boolean>(false)

  const fifteenMinutes = 60000 * 15
  setInterval(() => {
    if (!facility) return
    getDocs<TimesheetDoc>(
      query<TimesheetDoc>(
        FACILITY_COLLECTIONS.TIMESHEET(facility!.id),
        where("loginDate", "==", timestampOf(dayjs.utc().startOf("day"))),
        where("stopDateTime", ">=", dayjs().toDate())
      ).withConverter(converter<TimesheetDoc>())
    ).then((timesheets) => {
      setTimesheets(timesheets.docs.map((doc) => doc.data()))
    })
  }, fifteenMinutes)

  const organisation = useMemo<OrganisationState>(() => {
    const profilesId = orEmpty(facility?.dashboardProfiles).sort()

    if (!timesheets || !messages) {
      return { profilesId }
    }

    const presences = profilesPresenceFromTimesheets(timesheets, profilesId)
    return {
      profilesId,
      profilesAlerts: profilesAlerts(messages, profilesId, presences),
      zonesAlerts: zonesProfilesAlerts(messages, profilesId, presences),
    }
  }, [facility?.dashboardProfiles, messages, timesheets])

  useEffect(() => {
    if (!facility?.id) {
      return
    }

    if (!user) {
      return
    }

    const multiFacilityQuery = user.isMultiFacilities
      ? where("organizationId", "==", user.organizationId)
      : where("facilityId", "==", user.facilityId)

    onSnapshot<MessageDoc>(
      query<Message>(
        COLLECTIONS.MESSAGE,
        multiFacilityQuery,
        where("isArchived", "==", false)
      ).withConverter(converter<MessageDoc>()),
      (snapshot) => {
        console.log("Organisation messages updated")
        setMessages(snapshot.docs.map((doc) => doc.data()))
      }
    )
    onSnapshot<TimesheetDoc>(
      query<TimesheetDoc>(
        FACILITY_COLLECTIONS.TIMESHEET(facility.id),
        where("loginDate", "==", timestampOf(dayjs.utc().startOf("day"))),
        where("stopDateTime", ">=", dayjs().toDate())
      ),
      (snapshot) => {
        setTimesheets(snapshot.docs.map((doc) => doc.data()))
      }
    )
  }, [facility?.id, user])

  if (!facility) {
    return null
  }

  const resetTimesheet = () => {
    getDocs<TimesheetDoc>(
      query<Timesheet>(
        FACILITY_COLLECTIONS.TIMESHEET(facility.id),
        where("loginDate", "==", timestampOf(dayjs.utc().startOf("day")))
      ).withConverter(converter<TimesheetDoc>())
    ).then((snapshot) => {
      snapshot.forEach((timesheet) => {
        singleDoc
          .timesheet(facility.id)
          .remove(timesheet.id)
          .then(() => {
            console.log(`Removed ${timesheet.id}`)
          })
      })
    })
  }

  const upsertTimesheet = () => {
    setIsTimesheetButtonLoading(true)
    if (!resident || !user) return

    const now = firebase.firestore.Timestamp.now()
    const userId = user!.id
    const roomId = resident?.room
    const startOfDay = firebase.firestore.Timestamp.fromDate(
      dayjs.utc().startOf("day").toDate()
    )

    const timesheet: Timesheet = {
      userId,
      profileId: profile,
      currentZoneId: resident.zoneId,
      currentRoom: roomId,
      loginDate: startOfDay,
      startDateTime: startOfDay,
      stopDateTime: firebase.firestore.Timestamp.fromDate(
        dayjs().endOf("day").toDate()
      ),
      roomsTracking: [{ room: roomId, loginDateTime: now }],
    }

    createDoc(FACILITY_COLLECTIONS.TIMESHEET(facility.id), timesheet)
      .then(() => {
        message.success(I18.t("updateSuccessMessage"))
      })
      .catch(() => message.error(I18.t("errorMessage")))
      .finally(() => setIsTimesheetButtonLoading(false))
  }

  return (
    <Page>
      {/* <Header page={"organisation"}>
      </Header> */}
      <HeaderToolbar />
      <div className={bem(b, "page")}>
        <div className={bem(b, "left")}>
          {facility?.zones.map((zone) => (
            <div className={bem(b, "zoneRow")} key={zone.id}>
              <Panel key={zone.id} className={bem(b, "zone")}>
                <Typography.Title level={5}>{zone.label}</Typography.Title>
              </Panel>
            </div>
          ))}
        </div>
        <div className={bem(b, "right")}>
          {facility?.zones.map((zone) => (
            <div className={bem(b, "zoneRow")} key={zone.id}>
              {organisation.profilesId.map((profile) => (
                <ZoneProfile
                  key={profile}
                  className={bem(b, "zoneProfile")}
                  profile={profile}
                  profiles={profiles || []}
                  profileAlerts={organisation.profilesAlerts?.[profile]}
                  zoneState={organisation.zonesAlerts?.[zone.id]?.[profile]}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      <Collapse ghost>
        <Collapse.Panel header="Démo - Outils" key="1">
          <Button onClick={resetTimesheet}>
            Réinitialiser les feuilles de présences
          </Button>

          <SelectProfile value={profile} onChange={setProfile} />
          <SelectResident value={resident} onChange={setResident} />

          <Button
            type={"primary"}
            style={{ minWidth: 120 }}
            onClick={upsertTimesheet}
            disabled={!resident || !profile}
            loading={isTimesheetButtonLoading}
          >
            {I18.t("createTimesheet")}
          </Button>
        </Collapse.Panel>
      </Collapse>
    </Page>
  )
}

interface OrganisationState {
  profilesId: ProfileId[]
  profilesAlerts?: ProfilesAlerts
  zonesAlerts?: ZonesProfilesAlerts
}
