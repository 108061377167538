import React, { useMemo } from "react"
import pull from "lodash/pull"
import { AlertLevel, AlertLevels, MessageTheme } from "../../../domain/Message"
import { Checkbox, Col } from "antd"
import { useWorkContext } from "../../../contexts/work.context"
import { bem } from "../../../styles/bem"
import { SelectProfile } from "../../../components/select/SelectProfile"
import { SelectMessageTheme } from "../../../components/select/SelectMessageTheme"
import "./headerToolbar.less"
import { useAuthUser } from "../../../core/auth"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { Box, FormControlLabel, Checkbox as MUICheckbox } from "@mui/material"
import { SelectZone } from "../../../components/select/SelectZone"
import { CustomTextSearch } from "../../../components/CustomTextSearch"
import { debounce } from "lodash"

const b = "headerToolbar"

export interface Filters {
  theme: MessageTheme
  levels: AlertLevel[]
  profile?: string
  zone?: string
}

interface Props {
  filters: Filters
  onSearchTextChange: (search: string) => void
  displayArchived: boolean
  onFiltersChange: (filters: Filters) => void
  onDisplayArchivedChange: (display: boolean) => void
}

export const HeaderToolbar: React.FC<Props> = ({
  filters,
  onSearchTextChange,
  displayArchived,
  onFiltersChange,
  onDisplayArchivedChange,
}) => {
  const { user } = useAuthUser()
  const { facility, role } = useWorkContext()
  const debouncedSetSearchText = useMemo(
    () => debounce(onSearchTextChange, 500),
    [onSearchTextChange]
  )

  if (!facility || !role) {
    return null
  }

  return (
    <HeaderNew title="Tableau de bord">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <CustomTextSearch
            placeholder={"Recherche par nom, chambre..."}
            onChange={(text) => debouncedSetSearchText(text)}
            minWidth={150}
            textFieldProps={{ size: "small" }}
          />
          <SelectZone
            value={filters.zone}
            selectProps={{ size: "small" }}
            onChange={(zone) =>
              onFiltersChange({
                ...filters,
                zone: zone,
              })
            }
          />
          <SelectProfile
            restrictToProfile={
              role.isRestrictedAccess ? user?.profileId : undefined
            }
            value={filters.profile}
            selectProps={{ size: "small" }}
            onChange={(profile) =>
              onFiltersChange({
                ...filters,
                profile: profile,
              })
            }
          />

          <SelectMessageTheme
            value={filters.theme}
            selectProps={{ size: "small" }}
            onChange={(theme: MessageTheme) =>
              onFiltersChange({
                ...filters,
                theme: theme,
              })
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            marginLeft: 1,
          }}
        >
          <Col flex="1 0 50%">
            {AlertLevels.map((level) => (
              <Checkbox
                key={level}
                className={bem(b, "checkbox", { [level]: true })}
                checked={filters.levels.includes(level)}
                onChange={(e) => {
                  const levels = e.target.checked
                    ? filters.levels.concat(level)
                    : pull(filters.levels, level)
                  onFiltersChange({ ...filters, levels })
                }}
              />
            ))}
          </Col>

          <Col>
            <FormControlLabel
              label="Archives"
              sx={{ color: "#fff" }}
              control={
                <MUICheckbox
                  checked={displayArchived}
                  onChange={(e) => onDisplayArchivedChange(e.target.checked)}
                  sx={{
                    color: "#fff",
                    "&.Mui-checked": {
                      color: "#fff",
                    },
                  }}
                />
              }
            />
          </Col>
        </Box>
      </Box>
    </HeaderNew>
  )
}
