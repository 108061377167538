import { Timestamp } from "firebase/firestore"
import { Document } from "./Document"
import {
  mdiAccountQuestion,
  mdiAlertRhombusOutline,
  mdiAllergy,
  mdiArrowCollapseHorizontal,
  mdiBeer,
  mdiCheckCircleOutline,
  mdiEarHearing,
  mdiEarHearingOff,
  mdiEyeRemoveOutline,
  mdiFence,
  mdiFoodApple,
  mdiGlassCocktailOff,
  mdiGlasses,
  mdiHandExtended,
  mdiHeartPulse,
  mdiHumanCane,
  mdiLeaf,
  mdiPill,
  mdiTextureBox,
  mdiToothOutline,
  mdiWeatherWindy,
  mdiWheelchairAccessibility,
} from "@mdi/js"

export type MedicalIconId =
  | "wheelchair"
  | "allergy"
  | "pill"
  | "weather-windy"
  | "food-apple"
  | "hand-extended"
  | "check-circle-outline"
  | "beer"
  | "glass-cocktail-off"
  | "alert-rhombus-outline"
  | "glasses"
  | "human-cane"
  | "arrow-collapse-horizontal"
  | "fence"
  | "heart-pulse"
  | "tooth-outline"
  | "ear-hearing"
  | "texture-box"
  | "eye-remove-outline"
  | "ear-hearing-off"
  | "account-question"
  | "leaf"

export const MedicalFileIcons: Record<MedicalIconId, string> = {
  wheelchair: mdiWheelchairAccessibility,
  allergy: mdiAllergy,
  pill: mdiPill,
  "weather-windy": mdiWeatherWindy,
  "food-apple": mdiFoodApple,
  "hand-extended": mdiHandExtended,
  "check-circle-outline": mdiCheckCircleOutline,
  beer: mdiBeer,
  "glass-cocktail-off": mdiGlassCocktailOff,
  "alert-rhombus-outline": mdiAlertRhombusOutline,
  glasses: mdiGlasses,
  "human-cane": mdiHumanCane,
  "arrow-collapse-horizontal": mdiArrowCollapseHorizontal,
  fence: mdiFence,
  "heart-pulse": mdiHeartPulse,
  "tooth-outline": mdiToothOutline,
  "ear-hearing": mdiEarHearing,
  "texture-box": mdiTextureBox,
  "eye-remove-outline": mdiEyeRemoveOutline,
  "ear-hearing-off": mdiEarHearingOff,
  "account-question": mdiAccountQuestion,
  leaf: mdiLeaf,
}

export interface Pathology {
  name: string
  startDate: Timestamp
  ald: boolean
  comment?: string
}

export interface Vaccine {
  name: string
  vaccineDate?: Timestamp
  targetDate?: Timestamp
  comment?: string
}

export interface Daylife {
  comment: string
}

export interface Dependency {
  id: string
  label: string
  iconId: MedicalIconId
}

export interface SkinAllergy {
  id: string
  label: string
  iconId: MedicalIconId
}

export interface DrugAllergy {
  id: string
  label: string
  iconId: MedicalIconId
}

export interface RespiratoryAllergy {
  id: string
  label: string
  iconId: MedicalIconId
}

export interface DietEntry {
  id: string
  label: string
  iconId: MedicalIconId
  color: string
}

export interface MedicalFile {
  residentId: string
  facilityId: string
  organizationId: string
  zoneId: string
  pathologies: Pathology[]
  vaccines: Vaccine[]
  dailyLife: Daylife[]
  dependencies: Dependency[]
  skinAllergies: SkinAllergy[]
  drugAllergies: DrugAllergy[]
  respiratoryAllergies: RespiratoryAllergy[]
  standardDiet?: {
    allergiesRestrictions?: DietEntry[]
    diet?: DietEntry[]
    feedingMethod: DietEntry
    texture: DietEntry
    hydratation?: DietEntry[]
  }
  temporaryDiet?: {
    startDate: Timestamp
    endDate: Timestamp
    allergiesRestrictions?: DietEntry[]
    diet?: DietEntry[]
    feedingMethod: DietEntry
    texture: DietEntry
    hydratation?: DietEntry[]
  }
}

export type MedicalFileDoc = Document & MedicalFile
