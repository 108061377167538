import React from "react"
import { Cell } from "../../../components/table/TableCell"
import { TableHead, TableRow } from "@mui/material"

export const Header: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <Cell>Picto</Cell>
        <Cell>Nom</Cell>
        <Cell>Prénom</Cell>
        <Cell>Identifiant</Cell>
        <Cell>Profil</Cell>
        <Cell>Contrat</Cell>
        <Cell>Rôle</Cell>
        <Cell>Actif</Cell>
        <Cell>Dernière connexion</Cell>
        <Cell>Actions</Cell>
      </TableRow>
    </TableHead>
  )
}
