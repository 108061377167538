/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react"
import { styled } from "@mui/material/styles"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"

interface Props {
  options: { id: string; label: string }[]
  values: string[] | undefined
  setValues: (values: string[]) => void
}

export const MuiCheckbox = styled(Checkbox)({
  color: "#FFFFFF",
  "&.Mui-checked": {
    color: "#90CAF9",
  },
})

export const CustomChecklist: React.FC<Props> = ({
  options,
  values,
  setValues,
}) => {
  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.id}
          label={option.label}
          sx={{
            height: 22,
            ".MuiFormControlLabel-label": {
              fontSize: 14,
              lineHeight: "20px",
              color: "#FFFFFF",
            },
          }}
          control={
            <MuiCheckbox
              checked={values?.includes(option.id)}
              onChange={(e) =>
                setValues(
                  e.target.checked
                    ? [...values, option.id]
                    : values?.filter((v) => v !== option.id)
                )
              }
              size="small"
            />
          }
        />
      ))}
    </FormGroup>
  )
}

interface RHFCheckboxesProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  options: { id: string; label: string }[]
  name: TName
  control: Control<TFieldValues>
}

export const RHFCheckboxes = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  options,
  control,
  name,
}: RHFCheckboxesProps<TFieldValues, TName>): ReactElement => {
  const { field } = useController({ control, name })
  const [value, setValue] = React.useState<{ id: string; label: string }[]>(
    field.value || []
  )
  return (
    <>
      {options.map((option) => (
        <div
          style={{ display: "flex", flexGrow: 1, flexBasis: "50%" }}
          key={option.id}
        >
          <FormControlLabel
            label={option.label}
            sx={{
              height: 22,
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                lineHeight: "20px",
                color: "#FFFFFF",
              },
            }}
            control={
              <MuiCheckbox
                {...field}
                value={option.id}
                checked={value.some((v: { id: string }) => v.id === option.id)}
                onChange={(event, checked) => {
                  if (checked) {
                    field.onChange([...value, option])
                    setValue([...value, option])
                  } else {
                    field.onChange(
                      value.filter((v: { id: string }) => v.id !== option.id)
                    )
                    setValue(
                      value.filter((v: { id: string }) => v.id !== option.id)
                    )
                  }
                }}
              />
            }
          />
        </div>
      ))}
    </>
  )
}
