import React from "react"
import { Col, Row } from "antd"
import { LoginForm } from "./LoginForm"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import "./login.less"
import logo from "../../components/header/ISKUS-PNG-4.png"

export const Login: React.FC = () => {
  usePageTitle(WebScreen.LOGIN)

  return (
    <Row className={"login"} style={{ width: "100%" }}>
      <Col
        className={"loginPage"}
        span={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" style={{ width: 400 }} />
        <LoginForm />
      </Col>

      {/* <Row className={"loginPicture"} /> */}
      <Col className={"loginPicture"} span={12} />
    </Row>
  )
}
