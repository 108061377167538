export default {
  // common
  back: "Retour",
  icon: "Icône",
  actions: "Actions",
  index: "Index",
  connect: "Connexion",
  email: "Adresse mail",
  error: "Erreur",
  noComment: "Aucun commentaire",
  no: "Non",
  none: "Aucun",
  messages: "Messages",
  password: "Mot de passe",
  sum: "Total",
  validate: "Valider",
  yes: "Oui",
  color: "Couleur",
  description: "Description",
  requiredEmail: "Email requis",
  requiredId: "Id requis",
  errorMessage: "Une erreur s'est produite",
  insertSuccessMessage: "Enregistré",
  updateSuccessMessage: "Modification enregistrée",
  image: "Image",
  id: "Id",
  pin: "Code pin",
  // by page
  loginError: `Les identifiants de connexion fournis sont incorrects. Merci de corriger votre saisie`,
  loginHeader: `Bienvenue sur votre plateforme`,
  loginPasswordForgotten: `Mot de passe oublié`,
  loginPasswordForgottenMessage: `Un email vous a été envoyé à cette adresse. Cliquez sur le lien fourni pour réinitialiser votre mot de passe.`,
  loginPasswordForgottenSentence: `Pour ré-initialiser votre mot de passe, saisissez votre adresse email ci-dessous :`,
  loginSentence: `Connectez-vous pour accéder à tous vos services.`,
  organisationHeaderTitle: `Organisation des équipes`,
  organisationStatus_availability: `Disponible`,
  organisationStatus_help: `Besoin d'aide`,
  organisationStatus_normal: `OK`,
  organisationManageFacilities: `Gérer les établissements`,
  organisationCreateFacility: `Créer un établissement`,
  organisationUpdateFacility: `Modifier un établissement`,
  staffCreateUserError: `Impossible de créer l'utilisateur`,
  staffNewPasswordTitle: `Code d'accès de %{firstname} %{lastname}`,
  staffNewPasswordDescription1: `Voici le code d'accès que %{firstname} %{lastname} devra utiliser pour se connecter sur l'app tablette et sur le portail web.`,
  staffNewPasswordDescription2: `Ce code ne pourra PAS être régénéré depuis l'écran de gestion des accès`,
  staffPasswordResetEmailDescription1: `Un email a été envoyé à %{email}.`,
  staffPasswordResetEmailDescription2: `Cet utilisateur doit obligatoirement générer un code à 6 chiffres pour pouvoir s'authentifier à ISKUS sur tablette.`,
  staffTableArchiveUser: `Êtes vous sûre de vouloir archiver cet utilisateur ?`,
  staffTableResetPassword: `Êtes vous sûre de vouloir changer le code d'accès de cet utilisateur ?`,
  deleteTaskConfirmation: `Êtes vous sûre de vouloir supprimer cet utilisateur ?`,
  iconPreview: `Prévisualisation de l'icône :`,
  addTask: `Ajouter une tâche`,
  taskLabel: `Label de la tâche`,
  label: "Label",
  theme: "theme",
  trackedAlert: "Alerte suivie",
  defaultAlertLevel: "Alerte par défaut",
  mailNotifications: "Notifications mail",
  addAnEmail: "Ajouter un email",
  addAProfile: "Ajouter un profil",
  requiredProfile: "Profil requis",
  profile: "Profil",
  profiles: "Profils",
  workProfile: "Profile métier",
  createTimesheet: "Créer un timesheet",
  addQuickButton: "Ajouter un bouton d'action rapide",
  deleteJobConfirmation: "Êtes vous sûr de vouloir supprimer ce job ?",
  deleteFacilityConfirmation:
    "Êtes vous sûr de vouloir supprimer cet établissement ?",
  deleteQuickButtonConfirmation:
    "Êtes vous sûr de vouloir supprimer ce bouton ?",
  quickButtonsAlreadyTakenIndex: "Cet ordre d'affichage est déjà utilisé",
  quickButtonsAlreadyTakenLabel: "Ce label est déjà utilisé",
  displayOrder: "Ordre d'affichage",
  failSoftMode: "Mode dégradé",
  team: "Équipe",
  defaultJobShort: "Job par défaut",
  defaultJob: "Job par défaut pour ce couple métier-équipe",
  addJob: "Ajouter un job",
  associatedTasks: "Tâches associées",
  noTaskWithId: "Pas de tâche associée à l'id ",
  requiredTask: "Tâches requise",
  requiredMessageTheme: "Theme requis",
  task: "Tâche",
  tasks: "Tâches",
  jobs: "Jobs",
  addATask: "Ajouter une tâche",
  zones: "Zones",
  requiredIndex: "Ordre requis",
  requiredLabel: "Label requis",
  requiredImage: "Image requise",
  requiredRoom: "Numéro de chambre requis",
  requiredPin: "Code pin requis",
  requiredName: "Nom requis",
  requiredPassword: "Mot de passe requis",
  minLength6: "6 caractères minimum",
  addAZone: "Ajouter une zone",
  addARoom: "Ajouter une chambre",
}
