import React from "react"
import { Box, Typography } from "@mui/material"

export const SubHeader: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: 2,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 24,
      }}
    >
      <Typography
        sx={{
          verticalAlign: "top",
          lineHeight: "100%",
          height: "100%",
          fontSize: 24,
          color: "#FFFFFF",
          fontVariant: "small-caps",
          textTransform: "capitalize",
          paddingRight: 2,
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          height: "100%",
          flex: 1,
          width: "100%",
          borderBottomWidth: 1,
          borderBottomColor: "#FFFFFF",
          borderBottomStyle: "solid",
        }}
      />
    </Box>
  )
}
