import React from "react"

const MobileAppConfidentiality = () => {
  return (
    <html>
      <head>
        <meta
          charSet="utf-8"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <style
          media="screen"
          dangerouslySetInnerHTML={{
            __html: `body { background: #ECEFF1; color: rgba(0,0,0,0.87); font-family: Roboto, Helvetica, Arial, sans-serif; margin: 0; padding: 0; }
              #message { background: white; max-width: 360px; margin: 100px auto 16px; padding: 32px 24px; border-radius: 3px; }
              #message h2 { color: #ffa100; font-weight: bold; font-size: 16px; margin: 0 0 8px; }
              #message h1 { font-size: 22px; font-weight: 300; color: rgba(0,0,0,0.6); margin: 0 0 16px;}
              #message p { line-height: 140%; margin: 16px 0 24px; font-size: 14px; }
              #message a { display: block; text-align: center; background: #039be5; text-transform: uppercase; text-decoration: none; color: white; padding: 16px; border-radius: 4px; }
              #message, #message a { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
              #load { color: rgba(0,0,0,0.4); text-align: center; font-size: 13px; }
              @media (max-width: 600px) {
              body, #message { margin-top: 0; background: white; box-shadow: none; }
              body { border-top: 16px solid #ffa100; }`,
          }}
        ></style>

        <title>ISKUS : Politique de confidentialité</title>
      </head>

      <body>
        <div id="message">
          <h1>DONNEES PERSONNELLES</h1>
          <h1>POLITIQUE DE CONFIDENTIALITE</h1>
          <h2>INTRODUCTION</h2>
          <p>
            ISKUS est la solution dédiée au développement de la bientraitance en
            EHPAD. Cette vocation nécessite l’échange d’informations à caractère
            personnel et médical, pour lesquelles il est essentiel d’établir les
            règles de protection et confidentialité. C’est pourquoi, nous nous
            engageons à respecter la confidentialité des renseignements
            personnels que nous collectons.
          </p>
          <h2>COLLECTE DES DONNEES PERSONNELLES</h2>
          Les données collectées et utilisées par la solution ISKUS sont à
          décliner en fonction de 2 profils utilisateurs:
          <h2>SOIGNANTS & COLLABORATEURS EN EHPAD</h2>
          Informations obligatoires pour la création d’un compte: • Nom • Prénom
          • Adresse électronique professionnelle: Utilisée comme identifiant sur
          l’application • Profil métier: Permet de personnaliser l’affichage
          Informations optionnelles: • Avatar: Permet d’ajouter une illustration
          ou une photo pour personnaliser son avatar. L’avatar est visible par
          les collaborateurs du même EHPAD. Informations tracées lors de
          l’utilisation de l’application: • Localisation dans l’EPHAD (chambre
          actuelle): pour permettre d’assurer une bonne répartition des équipes
          de l’EPHAD en fonction des besoins d’intervention • Heure d’arrivée et
          de départ: Les tâches à réaliser par les équipes dépendant du poste
          dans la journée, cela permet d’afficher les bonnes tâches à
          l’utilisateur
          <h2>RESIDENT EN EHPAD</h2>
          Le résident ne saisit pas directement d’information sur ISKUS, ce sont
          les soignants et collaborateurs en EHPAD qui seront amenés à saisir
          les informations suivantes sur les résidents, pour améliorer la
          continuité des soins et améliorer les soins par rapport aux besoins
          spécifiques du résident. Informations pouvant être saisies pour le
          suivi d’un résident: • Prénom et Nom: Permet d’identifier un résident.
          • Numéro de chambre et bâtiment: Permet de localiser le résident dans
          l’EHPAD. • Alertes sur un résident: Permet de saisir un type d’alerte
          (ex: température, chute…) et d’y associer un commentaire pour une
          prise en charge efficace par les équipes de l’EHPAD.
          <h2>AUTORISATIONS DE L’APPLICATION MOBILE</h2>
          Certaines fonctionnalités de l’application mobile requièrent des
          autorisations spéciales: • Accès au micro du téléphone: Cette
          autorisation est nécessaire pour que l’utilisateur puisse dicter les
          messages à transmettre aux équipes de l’EHPAD. L’utilisation du micro
          n’est déclenchée que lors d’une décision explicite de l’utilisateur,
          au clic sur une icône micro.
          <h2>DROIT D’OPPOSITION ET DE RETRAIT</h2>
          Nous nous engageons à vous offrir un droit d’opposition et de retrait
          quant à vos renseignements personnels. Le droit d’opposition s’entend
          comme étant la possibilité offerte aux internautes de refuser que
          leurs renseignements personnels soient utilisés à certaines fins
          mentionnées lors de la collecte. Le droit de retrait s’entend comme
          étant la possibilité offerte aux internautes de demander à ce que
          leurs renseignements personnels ne figurent plus, par exemple, dans
          une liste de diffusion. Pour pouvoir exercer ces droits, vous pouvez
          nous contacter à l’adresse contact.app@iskus.fr
          <h2>DROIT D’ACCÈS</h2>
          Nous nous engageons à reconnaître un droit d’accès et de rectification
          aux personnes concernées désireuses de consulter, modifier, voire
          radier les informations les concernant. Pour pouvoir exercer ces
          droits, vous pouvez nous contacter à l’adresse contact.app@iskus.fr
          <h2>DIVULGATION D’INFORMATIONS PERSONNELLES</h2>
          Il peut nous arriver de transmettre vos informations personnelles à
          nos employés ou équipes techniques afin de gérer les comptes
          utilisateur, l’application mobile et les services qui vous sont
          proposés. Toute divulgation de données personnelles sera strictement
          contrôlée et réalisée en accord avec les législations européennes et
          américaines, mais aussi des pays depuis lesquels notre application
          mobile est disponible. Nous ne divulguons pas vos données personnelles
          à une tierce partie sans votre consentement et conformément à la loi
          en vigueur. Nous vous encourageons à consulter nos termes et
          conditions d’utilisation
          <h2>SÉCURITÉ</h2>
          Les renseignements personnels que nous collectons sont conservés dans
          un environnement sécurisé. Les personnes travaillant pour nous sont
          tenues de respecter la confidentialité de vos informations. Pour
          assurer la sécurité de vos renseignements personnels, nous avons
          recours aux mesures suivantes : • Protocole SSL (Secure Sockets Layer)
          • Gestion des accès • Hébergement sécurisé certifié données médicales
          • Sauvegarde informatique • Développement de certificat numérique •
          Pare-feu (Firewalls) Nous nous engageons à maintenir un haut degré de
          confidentialité en intégrant les dernières innovations technologiques
          permettant d’assurer la confidentialité de vos données. Toutefois,
          comme aucun mécanisme n’offre une sécurité maximale, une part de
          risque est toujours présente lorsque l’on utilise Internet pour
          transmettre des renseignements personnels.
          <h2>LÉGISLATION</h2>
          Nous nous engageons à respecter les dispositions législatives énoncées
          dans : Loi 78-17 Informatique et Libertés.
          <h2>MISES À JOUR</h2>
          Les présentes Règles de confidentialité peuvent être amenées à
          changer, nous publierons toute modification des règles de
          confidentialité sur cette page et une notification vous sera envoyée
          afin de vous inciter à consulter les nouvelles règles en vigueur.
          <h2>ENGAGEMENT DE CONFIDENTIALITE</h2>
          Je soussigné Monsieur Sébastien ROYER, exerçant les fonctions de
          Dirigeant au sein de la société ISKUS (ci-après dénommée « la Société
          »), étant à ce titre amené/e à accéder à des données à caractère
          personnel, déclare reconnaître la confidentialité desdites données. Je
          m’engage par conséquent, conformément aux articles 34 et 35 de la loi
          du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et
          aux libertés ainsi qu’aux articles 32 à 35 du règlement général sur la
          protection des données du 27 avril 2016, à prendre toutes précautions
          conformes aux usages et à l’état de l’art dans le cadre de mes
          attributions afin de protéger la confidentialité des informations
          auxquelles j’ai accès, et en particulier d’empêcher qu’elles ne soient
          communiquées à des personnes non expressément autorisées à recevoir
          ces informations. Je m’engage en particulier à : • ne pas utiliser les
          données auxquelles je peux accéder à des fins autres que celles
          prévues par mes attributions; • ne divulguer ces données qu’aux
          personnes dûment autorisées, en raison de leurs fonctions, à en
          recevoir communication, qu’il s’agisse de personnes privées,
          publiques, physiques ou morales; • ne faire aucune copie de ces
          données sauf à ce que cela soit nécessaire à l’exécution de mes
          fonctions; • prendre toutes les mesures conformes aux usages et à
          l’état de l’art dans le cadre de mes attributions afin d’éviter
          l’utilisation détournée ou frauduleuse de ces données; • prendre
          toutes précautions conformes aux usages et à l’état de l’art pour
          préserver la sécurité physique et logique de ces données; • m’assurer,
          dans la limite de mes attributions, que seuls des moyens de
          communication sécurisés seront utilisés pour transférer ces données; •
          en cas de cessation de mes fonctions, restituer intégralement les
          données, fichiers informatiques et tout support d’information relatif
          à ces données. Cet engagement de confidentialité, en vigueur pendant
          toute la durée de mes fonctions, demeurera effectif, sans limitation
          de durée après la cessation de mes fonctions, quelle qu’en soit la
          cause, dès lors que cet engagement concerne l’utilisation et la
          communication de données à caractère personnel. J’ai été informé que
          toute violation du présent engagement m’expose à des sanctions
          disciplinaires et pénales conformément à la réglementation en vigueur,
          notamment au regard des articles 226-16 à 226-24 du code pénal Fait à
          LYON, le 28/04/2022, Sébastien ROYER
        </div>
      </body>
    </html>
  )
}

export default MobileAppConfidentiality
