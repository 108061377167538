import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { singleDoc } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import {
  AdministrativeFileDoc,
  ConsentStatus,
  LegalConsent,
} from "../../../../domain/AdministrativeFile"
import { ModalConfig } from "./Consent"
import { arrayUnion } from "firebase/firestore"
import firebase from "firebase/compat"
import { SelectConsentDocStatus } from "../../../../components/select/SelectConsentDocStatus"
import { omitNils } from "../../../../core/lang"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface ConsentModalProps {
  adminFile: AdministrativeFileDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (adminFile: AdministrativeFileDoc, config: ModalConfig) => void
}

interface FormValues {
  label: string
  status: ConsentStatus
}

export const defaultValues: FormValues = {
  label: "",
  status: "Demandé",
}

export const ConsentModal: React.FC<ConsentModalProps> = ({
  adminFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset } = useForm<LegalConsent>({
    defaultValues: config.consent || defaultValues,
  })

  useEffect(() => {
    reset(config.consent || defaultValues)
  }, [config.consent, reset])

  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)

  const createOrUpdate = (
    consent: LegalConsent
  ): Promise<AdministrativeFileDoc> => {
    if (config.isUpdate && config.consent) {
      const updatedConsents = adminFile.consents.map((l, i) =>
        i === config.index ? consent : l
      )
      return singleDoc
        .administrativeFile(adminFile.id)
        .update({
          consents: updatedConsents,
        })
        .then(() => ({
          ...adminFile,
          consents: updatedConsents,
        }))
    } else {
      return singleDoc
        .administrativeFile(adminFile.id)
        .update({
          consents: arrayUnion(consent),
        })
        .then(() => ({
          ...adminFile,
          consents: [...adminFile.consents, consent],
        }))
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    if (!config.user) return
    setIsConfirmationLoading(true)
    let consent: LegalConsent
    const updates = {
      updatedBy: omitNils({
        id: config.user.id,
        avatarUrl: config.user.avatarUrl,
        initials: config.user.initials,
        color: config.user.color,
        firstname: config.user.firstname,
        lastname: config.user.lastname,
        profileId: config.user.profileId,
      }),
      updatedAt: firebase.firestore.Timestamp.now(),
    }
    if (config.isUpdate && config.consent) {
      consent = {
        ...config.consent,
        ...values,
        ...updates,
      }
    } else {
      consent = {
        ...values,
        ...updates,
      }
    }

    createOrUpdate(consent)
      .then((adminFile) => {
        onSubmit(adminFile, config)
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(config.consent || defaultValues)
    onClose()
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="xs"
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {config.isUpdate ? "Editer consentement" : "Consentement"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => handleClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="label"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Libellé"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="status"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectConsentDocStatus
                        {...field}
                        selectProps={{ style: { width: 396 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
