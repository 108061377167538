import isNil from "lodash/isNil"
import omitBy from "lodash/omitBy"

export const isObjectEmpty = (obj: {}) => {
  return typeof obj === "object" && Object.keys(obj).length === 0
}

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const delay = (ms: number) =>
  new Promise((res: Function) => setTimeout(res, ms))

export const isDefined = <T>(value?: T) => !isNil(value)

export const or0 = (value?: number) => value || 0

export const orEmpty = <T>(value?: T[]) => value || []

export const orWhite = <T>(value?: T): T | string => value || ""

export const length = <T>(value?: T[]): number =>
  Array.isArray(value) ? value.length : 0

export const replace = <T>(index: number, data: T, array: T[] = []): T[] => [
  ...array.slice(0, index),
  data,
  ...array.slice(index + 1),
]

export const omitNils = <T extends Record<string, unknown>>(value?: T): T =>
  omitBy<T>(value, isNil) as T
