import React, { useEffect, useState } from "react"
import { Box, Table, TableBody } from "@mui/material"
import { ButtonAddIcon } from "../../../../components/Icon"
import { Center } from "../../../../components/Center"
import { getAllTreatments, singleDoc } from "../../../../core/firebase"
import { TreatmentsHeader } from "./TreatmentsHeader"
import { TreatmentDoc } from "../../../../domain/Treatment"
import { MedicalFileDoc } from "../../../../domain/MedicalFile"
import { TreatmentRow } from "./TreatmentRow"
import { TreatmentModal } from "./TreatmentModal"
import { useAuthUser } from "../../../../core/auth"
import { UserDoc } from "../../../../domain/User"

interface TreatmentsProps {
  medicalFile: MedicalFileDoc
}

export interface ModalConfig {
  isOpen: boolean
  user?: UserDoc
  isUpdate?: boolean
  treatment?: TreatmentDoc
  index?: number
}

export const Treatments: React.FC<TreatmentsProps> = ({ medicalFile }) => {
  const { user } = useAuthUser()
  const [treatments, setTreatments] = useState<TreatmentDoc[]>()
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    user: user,
    isOpen: false,
  })

  useEffect(() => {
    getAllTreatments(medicalFile.residentId).then((snapshot) => {
      setTreatments(
        snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      )
    })
  }, [medicalFile.residentId])

  const onModalSubmit = (
    treatments: TreatmentDoc[],
    config: ModalConfig
  ): void => {
    setTreatments(treatments)
    setModalConfig(config)
  }

  const onAddClickHandler = () => {
    setModalConfig({ isOpen: true, user: user })
  }

  const onEditClickHandler = (index: number): void => {
    if (!treatments) return
    setModalConfig({
      isOpen: true,
      user: user,
      isUpdate: true,
      index: index,
      treatment: treatments[index],
    })
  }

  const onDeleteClickHandler = (index: number): void => {
    if (!treatments) return
    singleDoc
      .treatment(treatments[index].id)
      .remove()
      .then(() => {
        setTreatments(treatments.filter((_, i) => i !== index))
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <ButtonAddIcon buttonProps={{ onClick: () => onAddClickHandler() }} />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        <Table size="small" sx={{ minWidth: 400 }} aria-label="simple table">
          <TreatmentsHeader />
          <TableBody>
            {treatments?.map((treatment, index) => (
              <TreatmentRow
                key={index} // TODO: FIND A BETTER KEY THAN INDEX
                index={index}
                onEditClick={(index) => onEditClickHandler(index)}
                treatment={treatment}
                onDeleteClick={(index) => onDeleteClickHandler(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      {modalConfig.isOpen && (
        <TreatmentModal
          treatments={treatments}
          medicalFile={medicalFile}
          config={modalConfig}
          onClose={() => setModalConfig({ isOpen: false, user: user })}
          onSubmit={onModalSubmit}
        />
      )}
    </>
  )
}
