import React from "react"
import { Avatar, Box, Typography } from "@mui/material"
import { Resident } from "../../../domain/Resident"
import { FacilityDoc } from "../../../domain/Facility"

interface ResidentInfoProps {
  resident: Resident
  facilities?: Record<string, FacilityDoc>
}

export const ResidentInfo: React.FC<ResidentInfoProps> = ({
  resident,
  facilities,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minWidth: 280,
        width: 280,
        marginRight: 2,
      }}
    >
      <Avatar
        variant={"rounded"}
        src={resident.avatarUrl}
        sx={{
          backgroundColor: resident.color,
          height: 65,
          width: 65,
          marginRight: 1,
        }}
      >
        {resident.initials}
      </Avatar>
      <Box>
        <Typography sx={{ color: "#FFFFFF", fontSize: 16 }}>
          {resident.firstname}
          <br />
          {resident.lastname}
        </Typography>
        <Typography sx={{ color: "#FFFFFF8A", fontSize: 16 }}>
          {resident.room} - {facilities && facilities[resident.facilityId].name}
        </Typography>
      </Box>
    </Box>
  )
}
