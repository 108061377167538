import React, { useEffect, useState } from "react"
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import { MuiTextField } from "../../../../components/TextField"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { dayjsOf, singleDoc, timestampOf } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { ModalConfig } from "./Pathologies"
import { arrayUnion } from "firebase/firestore"
import { MedicalFileDoc, Pathology } from "../../../../domain/MedicalFile"
import dayjs, { Dayjs } from "dayjs"
import { MuiCheckbox } from "../../../../components/checklist/CustomChecklist"
import { MuiDateField } from "../../../../components/DateField"
import { PathologyLibrary } from "../../../../domain/Medical"
import { IskusSpinner } from "../../../../components/IskusSpinner"

interface PathologyModalProps {
  medicalFile: MedicalFileDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (medicalFile: MedicalFileDoc, config: ModalConfig) => void
}

type FormValues = {
  name: string
  startDate: Dayjs
  ald: boolean
  comment?: string
}

export const defaultValues: FormValues = {
  name: "",
  startDate: dayjs(),
  ald: false,
  comment: "",
}

export const PathologyModal: React.FC<PathologyModalProps> = ({
  medicalFile,
  config,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues:
      {
        ...config.pathology,
        startDate:
          config.pathology?.startDate && dayjsOf(config.pathology.startDate),
      } || defaultValues,
  })
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const [pathosLibrary, setPathosLibrary] = useState<PathologyLibrary>()
  const sortedPathosLibrary = pathosLibrary?.pathologies.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  useEffect(() => {
    singleDoc
      .medical<PathologyLibrary>("pathologies")
      .fetch()
      .then((pathos) => {
        setPathosLibrary(pathos.data())
      })
  }, [])

  useEffect(() => {
    if (config.pathology) {
      reset({
        ...config.pathology,
        startDate:
          config.pathology?.startDate && dayjsOf(config.pathology.startDate),
      })
    } else {
      reset(defaultValues)
    }
  }, [config.pathology, reset])

  const createOrUpdate = (pathology: Pathology): Promise<MedicalFileDoc> => {
    if (config.isUpdate && config.pathology) {
      const updatedPathologies = medicalFile.pathologies.map((p, i) =>
        i === config.index ? pathology : p
      )
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          pathologies: updatedPathologies,
        })
        .then(() => ({
          ...medicalFile,
          pathologies: updatedPathologies,
        }))
    } else {
      return singleDoc
        .medicalFile(medicalFile.id)
        .update({
          pathologies: arrayUnion(pathology),
        })
        .then(() => ({
          ...medicalFile,
          pathologies: [...medicalFile.pathologies, pathology],
        }))
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    console.log("values", values)
    setIsConfirmationLoading(true)
    const pathology: Pathology = {
      name: values.name,
      startDate: timestampOf(values.startDate),
      ald: values.ald,
      comment: values.comment,
    }
    createOrUpdate(pathology)
      .then((medicalFile) => {
        onSubmit(medicalFile, config)
        setIsConfirmationLoading(false)
        handleClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  const handleClose = () => {
    reset(defaultValues)
    onClose()
  }

  const handleLibraryItemClick = (name: string, ald: boolean) => {
    setValue("name", name)
    setValue("ald", ald)
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          maxWidth="md"
          open={config.isOpen}
          onClose={handleClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {config.isUpdate
                    ? "Editer la pathologie"
                    : "Ajouter une pathologie"}
                </Typography>
                <IconButton
                  buttonProps={{
                    onClick: () => handleClose(),
                  }}
                  path={mdiWindowClose}
                  color={"#FFFFFF"}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* FORM FIELDS */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            fullWidth
                            required={true}
                            type="text"
                            label={"Pathologie"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="comment"
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <MuiTextField
                            multiline={true}
                            rows={2}
                            fullWidth
                            type="text"
                            label={"Commentaire optionnel"}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="ald"
                        control={control}
                        defaultValue={true}
                        render={({ field: { value, ...field } }) => (
                          <MuiCheckbox checked={value} {...field} />
                        )}
                      />
                      <Typography sx={{ color: "#FFFFFFB2" }}>
                        {"Pathologie reconnue ALD"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <MuiDateField
                            {...field}
                            required={true}
                            label="Date de début"
                            format="DD-MM-YYYY"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* LIST SELECTION */}
                <Grid item xs={6}>
                  <Box sx={{ height: "100%", backgroundColor: "#FFFFFF1A" }}>
                    <List
                      sx={{
                        width: "100%",
                        overflow: "auto",
                        maxHeight: 450,
                      }}
                      component="div"
                    >
                      {sortedPathosLibrary &&
                        sortedPathosLibrary.map((pathos) => {
                          return (
                            <List
                              key={pathos.name}
                              component="div"
                              disablePadding
                            >
                              <ListItemButton
                                sx={{
                                  maxHeight: 25,
                                  color: "#FFFFFF",
                                  fontSize: 16,
                                }}
                                onClick={() =>
                                  handleLibraryItemClick(
                                    pathos.name,
                                    pathos.ald
                                  )
                                }
                              >
                                <ListItemText
                                  primary={pathos.name}
                                  sx={{ whiteSpace: "nowrap" }}
                                />
                              </ListItemButton>
                            </List>
                          )
                        })}
                    </List>
                  </Box>
                </Grid>
                {/* BUTTONS */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
