import React from "react"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"

interface Props {
  setSelectedCategory: (category: string) => void
  selectedCategory: string
  availableCategories: string[]
}

export const SelectCategory: React.FC<Props> = (props: Props) => {
  const { availableCategories, setSelectedCategory, selectedCategory } = props
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value)
  }

  return (
    <FormControl size="small" sx={{ minWidth: 220 }}>
      <InputLabel
        id="select-label"
        shrink={true}
        sx={{
          "&.MuiFormLabel-root": {
            color: "#FFFFFF99",
          },
        }}
      >
        Catégorie
      </InputLabel>
      <Select
        labelId="select-label"
        label="Catégorie"
        notched={true}
        displayEmpty={true}
        value={selectedCategory}
        onChange={handleChange}
        sx={{
          "&.MuiOutlinedInput-root": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFFFFF99",
          },
        }}
      >
        <MenuItem value={""}>
          <em>Toutes les catégories</em>
        </MenuItem>
        {availableCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
