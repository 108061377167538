import Upload from "rc-upload"
import React, { useState } from "react"
import {
  uploadResidentsFilesReturnPath,
  uploadResidentsFilesReturnUrl,
} from "../core/firebaseStorage"
import {
  UploadRequestOption as RcCustomRequestOptions,
  RcFile,
} from "rc-upload/lib/interface"
import { DialogConfirm } from "./DialogConfirm"

interface Props {
  children: React.ReactNode
  filenamePrefix: string
  returnedOption: "url" | "path"
  onChange?: (fileUrl: string) => void
}

export const FileUploader: React.FC<Props> = ({
  children,
  filenamePrefix,
  returnedOption,
  onChange,
}) => {
  const [message, setMessage] = useState<string>()
  const [open, setOpen] = useState(false)

  function customRequest({ file, onError, onSuccess }: RcCustomRequestOptions) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const a: any = file
    triggerUpload(a)
      .then((fileUrl) => {
        console.log("fileUrl", fileUrl)
        onChange && fileUrl && onChange(fileUrl)
      })
      .then(onSuccess)
      .catch(onError)

    return {
      abort() {
        console.log("upload progress is aborted.")
      },
    }
  }

  function beforeUpload(file: RcFile) {
    setMessage(undefined)
    const isJpeg = file.type === "image/jpeg"
    const isPng = file.type === "image/png"
    const isPdf = file.type === "application/pdf"
    const isWord =
      file.type === "application/msword" ||
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    const isJpgOrPngOrPdfOrWord = isJpeg || isPng || isPdf || isWord
    if (!isJpgOrPngOrPdfOrWord) {
      setMessage(
        "Le format de fichier n'est pas supporté. Veuillez choisir un fichier au format JPG, PNG, PDF ou Word."
      )
      setOpen(true)
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      setMessage("La taille du fichier doit être inférieure à 2Mo.")
      setOpen(true)
    }
    return isJpgOrPngOrPdfOrWord && isLt2M
  }

  async function triggerUpload(uploadFile: RcFile) {
    if (!uploadFile) {
      return
    }
    let extension
    switch (uploadFile.type) {
      case "image/jpeg":
        extension = "jpg"
        break
      case "image/png":
        extension = "png"
        break
      case "application/pdf":
        extension = "pdf"
        break
      case "application/msword":
        extension = "doc"
        break
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        extension = "docx"
        break
      default:
        throw new Error("Unsupported file type")
    }
    const filename = `${filenamePrefix}-${new Date().valueOf()}.${extension}`

    if (returnedOption === "url") {
      return uploadResidentsFilesReturnUrl(filename, uploadFile)
    } else {
      return uploadResidentsFilesReturnPath(filename, uploadFile)
    }
  }

  const handleCloseDialog = () => {
    setOpen(false)
    setMessage(undefined)
  }

  return (
    <>
      <Upload
        name="avatar"
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        style={{
          cursor: "pointer",
        }}
      >
        {children}
      </Upload>
      {open && (
        <DialogConfirm
          isDialogOpen={open}
          setIsDialogOpen={handleCloseDialog}
          onConfirm={handleCloseDialog}
          cancelText={"Fermer"}
          title={message || "Erreur"}
        />
      )}
    </>
  )
}
