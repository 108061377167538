import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Link } from "../Link"

interface ListItemLinkProps {
  icon?: React.ReactElement
  text: string
  to: string
  drawerOpen: boolean
}

export const ListItemLink: React.FC<ListItemLinkProps> = (
  props: ListItemLinkProps
) => {
  const { icon, text, to, drawerOpen } = props
  return (
    <ListItemButton
      key={text}
      component={Link}
      to={to}
      selected={to === window.location.pathname}
      sx={{
        minHeight: 48,
        justifyContent: drawerOpen ? "initial" : "center",
        "&.Mui-selected": { backgroundColor: "#ffffff1a" },
      }}
    >
      <ListItemIcon
        sx={{
          color: "#ffffff8f",
          minWidth: 0,
          mr: drawerOpen ? 3 : "auto",
          justifyContent: "center",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={text}
        sx={{ color: "#ffffffde", opacity: drawerOpen ? 1 : 0 }}
      />
    </ListItemButton>
  )
}
