import React from "react"
import { Typography } from "antd"
import { Flex } from "../Flex"

interface Props {
  description?: string
  className?: string
}

export const PanelTitle: React.FC<Props> = ({
  children,
  description,
  ...props
}) => (
  <Flex alignItems={"baseline"} flexWrap={"wrap"} gap={20} {...props}>
    <Typography.Title level={4}>{children}</Typography.Title>
    <Typography.Text style={{ fontSize: "1.1rem" }}>
      {description}
    </Typography.Text>
  </Flex>
)
