import React, { useMemo } from "react"
import { ZoneId } from "../../domain/Zone"
import { CustomSelect } from "./CustomSelect"
import { SelectProps } from "@mui/material"
import { useWorkContext } from "../../contexts/work.context"

interface Props {
  value?: ZoneId
  onChange: (value: ZoneId) => void
  selectProps?: SelectProps
}

interface EventItems {
  value: string
  label: string
  imageUrl: string
}

export const SelectEventCategory: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
}) => {
  const { eventCategories } = useWorkContext()

  const eventCategoryOptions: EventItems[] = useMemo(() => {
    if (!eventCategories) return []
    return eventCategories.map((category) => ({
      value: category.id,
      label: category.label,
      imageUrl: category.imageUrl,
    }))
  }, [eventCategories])

  return (
    <CustomSelect
      label="Catégorie"
      value={value}
      options={eventCategoryOptions}
      allOptionsLabel="Selectionnez une catégorie"
      onChange={onChange}
      selectProps={selectProps}
    />
  )
}
