import { styled } from "@mui/material/styles"
import { Input, TextField } from "@mui/material"

export const MuiTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: "#FFFFFF99",
    "&.Mui-focused": {
      color: "#FFFFFF99",
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFFFFFE6",
    "& fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF99",
    },
  },
  "& .MuiInput-root": {
    color: "#FFFFFFE6",
    "&:before": {
      borderColor: "#FFFFFF99",
    },
    "&:hover:before": {
      borderColor: "#FFFFFF99",
    },
  },
  "& .MuiInputBase-root": {
    color: "#FFFFFFE6",
    "&:before": {
      borderColor: "#FFFFFF99",
    },
    "&:hover:before": {
      borderColor: "#FFFFFF99",
    },
  },
})
