import React, { useEffect, useMemo, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { SubHeader } from "../../../../components/SubHeader"
import { useWorkContext } from "../../../../contexts/work.context"
import { Badge } from "../../../../components/Badge"
import { dayjsOf, getAllMedicalFiles } from "../../../../core/firebase"
import { DietEntry, MedicalFileDoc } from "../../../../domain/MedicalFile"
import dayjs from "dayjs"
import { isEqual, uniqWith } from "lodash"
import { NumberChip } from "./KitchenChip"

const today = dayjs()

const RowHeader: React.FC<{
  text: string
  width: number
  color?: string | undefined
}> = ({ text, width, color }) => {
  return (
    <Grid item xs={width}>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#FFFFFF1A",
          width: 257,
          minHeight: 42,
          paddingLeft: 2,
          border: "1px solid #FFFFFF",
          borderRadius: 2,
          alignItems: "center",
        }}
      >
        <Badge color={color ? color : "#FFFFFF"} size={8} />
        <Typography
          style={{
            marginLeft: 10,
            color: "#FFFFFFB2",
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Grid>
  )
}

const filterMedicalFiles = (
  medicalFiles: MedicalFileDoc[],
  zoneId?: string,
  field?: "texture" | "feedingMethod",
  value?: string
) => {
  const filteredByZone = zoneId
    ? medicalFiles.filter((m) => m.zoneId === zoneId)
    : medicalFiles
  const filteredByField =
    field && value
      ? filteredByZone.filter((m) =>
          m.temporaryDiet && dayjsOf(m.temporaryDiet.endDate)! >= today
            ? m.temporaryDiet[field].id === value
            : m.standardDiet && m.standardDiet[field].id === value
        )
      : filteredByZone
  return filteredByField
}

const filterMedicalFilesByDiets = (
  medicalFiles: MedicalFileDoc[],
  zoneId?: string,
  diets?: string[]
) => {
  console.log(
    "FilterMedicalFilesByDiets params : medicalFiles:",
    medicalFiles,
    "zoneId:",
    zoneId,
    "diet:",
    diets
  )
  const filteredByZone = zoneId
    ? medicalFiles.filter((m) => m.zoneId === zoneId)
    : medicalFiles
  const filteredByDiets =
    diets && diets.length > 0
      ? filteredByZone.filter((m) => {
          const medicalFileDiets =
            m.temporaryDiet?.diet && dayjsOf(m.temporaryDiet.endDate)! >= today
              ? m.temporaryDiet?.diet
              : m.standardDiet?.diet
          return isEqual(
            medicalFileDiets
              ?.reduce((acc: string[], d) => [...acc, d.id], [])
              .sort(),
            diets.sort()
          )
        })
      : // If no diets provided, return all medical files without special diets
      diets?.length == 0
      ? filteredByZone.filter(
          (m) =>
            (m.standardDiet?.diet === undefined &&
              m.temporaryDiet?.diet === undefined) ||
            m.standardDiet?.diet?.length === 0 ||
            (m.temporaryDiet?.diet &&
              dayjsOf(m.temporaryDiet.endDate)! >= today &&
              m.temporaryDiet?.diet?.length === 0)
        )
      : filteredByZone
  console.log("filteredByDiets", filteredByDiets)
  return filteredByDiets
}

export const KitchenSummary: React.FC = () => {
  const { diets, facility } = useWorkContext()
  const [medicalFiles, setMedicalFiles] = useState<MedicalFileDoc[]>([])
  const zones = useMemo(() => facility && facility.zones, [facility])

  const columnWidth = 9 / ((zones && zones.length + 1) || 1)

  useEffect(() => {
    if (facility) {
      getAllMedicalFiles(facility.id).then((snapshot) =>
        setMedicalFiles(snapshot.docs.map((d) => d.data()))
      )
    }
  }, [facility])

  // Calculate all diets combinations
  const getDietsCombinaison = (
    medicalFiles: MedicalFileDoc[]
  ): (DietEntry[] | undefined)[] => {
    if (!diets) return [undefined]
    const dietsCombinaisons = medicalFiles.map((m) => {
      const diet =
        (m.temporaryDiet &&
          dayjsOf(m.temporaryDiet.endDate)! >= today &&
          m.temporaryDiet?.diet) ||
        m.standardDiet?.diet

      if (diet) return diet.sort()
      else return
    })
    return uniqWith(dietsCombinaisons, isEqual)
  }
  const dietsCombinations = getDietsCombinaison(medicalFiles)

  return (
    <Box sx={{ marginTop: 5, marginBottom: 5 }}>
      <SubHeader text={"Textures"} />
      <Box style={{ marginTop: 40, marginBottom: 50 }}>
        <Grid container spacing={2}>
          {/* HEADER */}
          <Grid container item spacing={2}>
            <Grid item xs={3}></Grid>
            {zones?.map((zone) => (
              <Grid key={zone.id} item xs={columnWidth}>
                <Typography
                  style={{
                    color: "#FFFFFFB2",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {zones && zone.label}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={columnWidth}>
              <Typography
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Total
              </Typography>
            </Grid>
          </Grid>
          {/* END HEADER */}
          {/* ROW */}
          {diets?.textures?.map((texture) => (
            <Grid container item spacing={2} key={texture.id}>
              <RowHeader text={texture.label} width={3} color={texture.color} />
              {zones?.map((zone) => (
                <Grid key={zone.id} item xs={columnWidth}>
                  <NumberChip
                    medicalFiles={filterMedicalFiles(
                      medicalFiles,
                      zone.id,
                      "texture",
                      texture.id
                    )}
                    color={texture.color}
                    alpha={0.7}
                  />
                </Grid>
              ))}
              <Grid item xs={columnWidth}>
                <NumberChip
                  medicalFiles={filterMedicalFiles(
                    medicalFiles,
                    undefined,
                    "texture",
                    texture.id
                  )}
                  color={texture.color}
                />
              </Grid>
            </Grid>
          ))}
          {/* END ROW */}
          <Grid container item spacing={2}>
            <Grid item xs={columnWidth}></Grid>
          </Grid>
          {/* TOTAL */}
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Typography
                style={{
                  marginLeft: 34,
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Total
              </Typography>
            </Grid>
            {zones?.map((zone) => (
              <Grid key={zone.id} item xs={columnWidth}>
                <NumberChip
                  medicalFiles={filterMedicalFiles(medicalFiles, zone.id)}
                  color={"#FFFFFF"}
                  alpha={0.7}
                  isTotal={true}
                />
              </Grid>
            ))}
            <Grid item xs={columnWidth}>
              <NumberChip
                medicalFiles={filterMedicalFiles(medicalFiles)}
                color={"#FFFFFF"}
                isTotal={true}
              />
            </Grid>
          </Grid>
          {/* END TOTAL */}
        </Grid>
      </Box>
      <SubHeader text={"Régimes"} />
      {/* ################################## REGIMES ##################################### */}
      <Box style={{ marginTop: 40, marginBottom: 50 }}>
        <Grid container spacing={2}>
          {/* HEADER */}
          <Grid container item spacing={2}>
            <Grid item xs={3}></Grid>
            {zones?.map((zone) => (
              <Grid key={zone.id} item xs={columnWidth}>
                <Typography
                  style={{
                    color: "#FFFFFFB2",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {zones && zone.label}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={columnWidth}>
              <Typography
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Total
              </Typography>
            </Grid>
          </Grid>
          {/* END HEADER */}
          {/* ROW */}
          {diets &&
            dietsCombinations &&
            dietsCombinations.map(
              (combination, id) =>
                combination &&
                combination?.length > 0 && (
                  <Grid container item spacing={2} key={id}>
                    <RowHeader
                      text={combination
                        ?.map(
                          (diet: DietEntry) =>
                            diets.diets.filter((d) => d.id === diet.id)[0].label
                        )
                        .join(" / ")}
                      width={3}
                    />
                    {zones?.map((zone) => (
                      <Grid key={zone.id} item xs={columnWidth}>
                        <NumberChip
                          medicalFiles={filterMedicalFilesByDiets(
                            medicalFiles,
                            zone.id,
                            combination.reduce(
                              (acc: string[], diet: DietEntry) => [
                                ...acc,
                                diet.id,
                              ],
                              []
                            )
                          )}
                          isTotal={true}
                          alpha={0.7}
                        />
                      </Grid>
                    ))}

                    <Grid item xs={columnWidth}>
                      <NumberChip
                        medicalFiles={filterMedicalFilesByDiets(
                          medicalFiles,
                          undefined,
                          combination.reduce(
                            (acc: string[], diet: DietEntry) => [
                              ...acc,
                              diet.id,
                            ],
                            []
                          )
                        )}
                        isTotal={true}
                      />
                    </Grid>
                  </Grid>
                )
            )}
          {/* END ROW */}
          {/* NORMAL DIET ROW */}
          <Grid container item spacing={2}>
            <RowHeader text={"Normal"} width={3} />
            {zones?.map((zone) => (
              <Grid key={zone.id} item xs={columnWidth}>
                <NumberChip
                  medicalFiles={filterMedicalFilesByDiets(
                    medicalFiles,
                    zone.id,
                    []
                  )}
                  isTotal={true}
                  alpha={0.7}
                />
              </Grid>
            ))}

            <Grid item xs={columnWidth}>
              <NumberChip
                medicalFiles={filterMedicalFilesByDiets(
                  medicalFiles,
                  undefined,
                  []
                )}
                isTotal={true}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={columnWidth}></Grid>
          </Grid>

          {/* TOTAL */}
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Typography
                style={{
                  marginLeft: 34,
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                Total
              </Typography>
            </Grid>
            {zones?.map((zone) => (
              <Grid key={zone.id} item xs={columnWidth}>
                <NumberChip
                  medicalFiles={filterMedicalFilesByDiets(
                    medicalFiles,
                    zone.id
                  )}
                  color={"#FFFFFF"}
                  alpha={0.7}
                  isTotal={true}
                />
              </Grid>
            ))}
            <Grid item xs={columnWidth}>
              <NumberChip
                medicalFiles={filterMedicalFiles(medicalFiles)}
                color={"#FFFFFF"}
                isTotal={true}
              />
            </Grid>
          </Grid>
          {/* END TOTAL */}
        </Grid>
      </Box>
    </Box>
  )
}
