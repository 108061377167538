import React from "react"
import { HeaderNew } from "../../components/header/HeaderNew"
import { Box } from "@mui/material"
import { Icon } from "../../components/Icon"
import { Tab, Tabs } from "@mui/material"
import {
  mdiDoctor,
  mdiInformationOutline,
  mdiMedicalBag,
  mdiCalendar,
  mdiOfficeBuildingCog,
  mdiDomain,
  mdiGestureTapButton,
} from "@mdi/js"

interface Props {
  activeTab: number
  setActiveTab: (tab: number) => void
}

export const HeaderToolbar: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }
  return (
    <HeaderNew title="Administration">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      ></Box>
      <Box
        sx={{
          height: 60,
          marginRight: 5,
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          {/* <Tab
            icon={<Icon path={mdiInformationOutline} />}
            label="Tâches"
            iconPosition="start"
            value={0}
          />
          <Tab
            icon={<Icon path={mdiGestureTapButton} />}
            label="Message"
            iconPosition="start"
            value={1}
          />
          <Tab
            icon={<Icon path={mdiMedicalBag} />}
            label="Jobs"
            iconPosition="start"
            value={2}
          /> */}
          <Tab
            icon={<Icon path={mdiOfficeBuildingCog} />}
            label="Etablissements"
            iconPosition="start"
            value={3}
          />
          <Tab
            icon={<Icon path={mdiDomain} />}
            label="Organisations"
            iconPosition="start"
            value={4}
          />
        </Tabs>
      </Box>
    </HeaderNew>
  )
}
