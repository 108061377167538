import React, { CSSProperties } from "react"

interface Props {
  className?: string
}

export const Flex: React.FC<Props & CSSProperties> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={className} style={{ display: "flex", ...props }}>
      {children}
    </div>
  )
}
