import React, { useMemo } from "react"
import { useWorkContext } from "../../contexts/work.context"
import { ZoneId } from "../../domain/Zone"
import { CustomSelect } from "./CustomSelect"
import { SelectProps } from "@mui/material"
import { FacilityDoc } from "../../domain/Facility"

interface Props {
  facility?: FacilityDoc
  value?: ZoneId
  onChange: (value: ZoneId) => void
  selectProps?: SelectProps
}

export const SelectZone: React.FC<Props> = ({
  facility,
  value,
  onChange,
  selectProps,
}) => {
  const { facility: defaultFacility } = useWorkContext()
  const myFacility = useMemo(
    () => facility || defaultFacility,
    [facility, defaultFacility]
  )
  const zonesSelectOptions =
    myFacility?.zones.map((zone) => ({
      label: zone.label,
      value: zone.id,
    })) || []

  return (
    <CustomSelect
      label="Zone"
      value={value}
      options={zonesSelectOptions}
      allOptionsLabel="Toutes zones"
      onChange={onChange}
      selectProps={selectProps}
    />
  )
}
