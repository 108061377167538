import { TableCell, TableRow, Typography } from "@mui/material"
import React from "react"

export const FormRow: React.FC<{
  title: string
  children: React.ReactNode
}> = ({ title, children }) => {
  return (
    <TableRow
      sx={{
        border: 0,
        // "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell
        colSpan={1}
        align="left"
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          border: 0,
          paddingY: 0.5,
          paddingLeft: 2,
          paddingRight: 0,
          verticalAlign: "center",
        }}
      >
        <Typography sx={{ color: "#FFFFFF", fontSize: 14 }} gutterBottom>
          {title}
        </Typography>
      </TableCell>
      {children}
    </TableRow>
  )
}
