import React, { useMemo } from "react"
import { Card } from "../../../components/Card"
import { ResidentDoc } from "../../../domain/Resident"
import { Box } from "@mui/material"
import { useWorkContext } from "../../../contexts/work.context"
import { AlertChip } from "./AlertChip"
import {
  mdiHumanHandsup,
  mdiSilverware,
  mdiDoctor,
  mdiThermometer,
  mdiToilet,
  mdiBandage,
  mdiHandWater,
  mdiBrain,
  mdiBed,
  mdiPill,
} from "@mdi/js"
import { Link } from "../../../components/Link"
import { WebScreen } from "../../../domain/Role"
import { Screens } from "../../screen"
import { ResidentInfo } from "./ResidentInfo"
import { useResidents } from "../../../contexts/residents.context"

interface ResidentCardProps {
  resident: ResidentDoc
}

const icons: Record<string, string> = {
  alimentation: mdiSilverware,
  chute: mdiHumanHandsup,
  compteRendu: mdiDoctor,
  constantes: mdiThermometer,
  constipation: mdiToilet,
  diarrhee: mdiToilet,
  douleurs: mdiBandage,
  etatCutane: mdiHandWater,
  psychisme: mdiBrain,
  sommeil: mdiBed,
  traitements: mdiPill,
  transit: mdiToilet,
  urines: mdiToilet,
  selles: mdiToilet,
}

export const ResidentCard: React.FC<ResidentCardProps> = ({ resident }) => {
  const { facilities, messageButtons } = useWorkContext()
  const { setCurrent } = useResidents()
  const messageIconByTheme = useMemo(
    () =>
      (messageButtons || []).reduce((dict, button) => {
        dict[button.theme] = button.icon
        return dict
      }, {} as Record<string, string>),
    [messageButtons]
  )
  const residentAlerts = resident.alerts
  const alertsKeys = residentAlerts && Object.keys(residentAlerts)
  const alerts =
    (alertsKeys &&
      alertsKeys.map((key) => {
        if (
          residentAlerts[key].alertLevel === "danger" ||
          residentAlerts[key].alertLevel === "warning"
        ) {
          return {
            theme: key,
            icon: messageIconByTheme[key],
            ...residentAlerts[key],
          }
        }
      })) ||
    []
  return (
    <Link
      onClick={() => setCurrent(resident)}
      to={`${Screens[WebScreen.ADMIN_RESIDENT].rootPath}/${resident.id}`}
    >
      <Card boxProps={{ style: { width: 422, minHeight: 81 } }}>
        <ResidentInfo resident={resident} facilities={facilities} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            gap: 0.5,
          }}
        >
          {alerts.map(
            (alert) =>
              alert && (
                <AlertChip
                  key={alert.theme}
                  alert={alert}
                  iconPath={icons[alert.theme]}
                />
              )
          )}
        </Box>
      </Card>
    </Link>
  )
}
