import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Button } from "@mui/material"
import { SubHeader } from "../../../../components/SubHeader"
import { ResidentDoc } from "../../../../domain/Resident"
import { Icon } from "../../../../components/Icon"
import { mdiPlus } from "@mdi/js"
import { EventModal } from "./EventModal"
import { EventDoc } from "../../../../domain/Event"
import { onSnapshot, query, where } from "firebase/firestore"
import { COLLECTIONS, dayjsOf, timestampOf } from "../../../../core/firebase"
import dayjs from "dayjs"
import { EventCard } from "./EventCard"

interface ResidentPlanningProps {
  resident: ResidentDoc
}

export interface ModalConfig {
  isOpen: boolean
  isUpdate?: boolean
  event?: EventDoc
}

const currentDate = dayjs()
const currentTimestamp = timestampOf(currentDate.subtract(15, "day"))

export const ResidentPlanning: React.FC<ResidentPlanningProps> = ({
  resident,
}) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })
  const [residentEvents, setResidentEvents] = useState<EventDoc[]>([])
  const [scrollIndex, setScrollIndex] = useState<number>(0)
  const scrollRef = useRef<HTMLDivElement>(null)
  const watchUnsubscribe = useRef<Function>(() => {})

  // Transform residentEvents to an array of objects, each including a date and an array of events
  const eventsByDay = useMemo(
    () =>
      residentEvents
        .reduce((acc, event) => {
          const startdate = dayjsOf(event.startDate)
          const endDate = dayjsOf(event.endDate)
          // Determine all days with events (eventDays)
          const eventDays = []
          if (startdate && endDate) {
            for (
              let i = startdate;
              i.isBefore(endDate);
              i = i.add(1, "day").startOf("day")
            ) {
              eventDays.push(i.format("DD/MM/YYYY"))
            }
          }
          // Loop through eventDays and add event to array for the corresponding day
          eventDays.forEach((day) => {
            const dayEvents = acc.find((d) => d.date === day)
            if (dayEvents) {
              dayEvents.events.push(event)
              dayEvents.events.sort(
                (a, b) =>
                  dayjs(dayjsOf(a.startDate)).unix() -
                  dayjs(dayjsOf(b.startDate)).unix()
              )
            } else {
              if (!day) {
                return acc
              }
              acc.push({ date: day, events: [event] })
            }
          })
          return acc
        }, [] as { date: string; events: EventDoc[] }[])
        .sort(
          (a, b) =>
            dayjs(a.date, "DD/MM/YYYY").unix() -
            dayjs(b.date, "DD/MM/YYYY").unix()
        ),
    [residentEvents]
  )

  console.log("EVENTSBYDAY : ", eventsByDay)

  const onModalSubmit = (events: EventDoc[], config: ModalConfig): void => {
    setResidentEvents(events)
    setModalConfig(config)
  }

  const handleCreateEvent = () => {
    setModalConfig({ isOpen: true })
  }

  const handleEventCardClick = (event: EventDoc) => {
    setModalConfig({ isOpen: true, isUpdate: true, event })
  }

  useEffect(() => {
    if (!resident) {
      return
    }
    setResidentEvents([])

    watchUnsubscribe.current()
    watchUnsubscribe.current = onSnapshot(
      query<EventDoc>(
        COLLECTIONS.EVENTS,
        where("recipients", "array-contains", resident.id),
        where("startDate", ">=", currentTimestamp)
      ),
      (snapshot) => {
        setResidentEvents(
          snapshot.docs.map((d) => {
            return { ...d.data(), id: d.id }
          })
        )
      }
    )
    return () => watchUnsubscribe.current()
  }, [resident])

  useEffect(() => {
    let closestDate = null
    let closesDistance = Infinity

    eventsByDay.forEach((day, index) => {
      const distance = dayjs(day.date, "DD/MM/YYYY").diff(currentDate, "day")
      if (distance >= 0 && distance < closesDistance) {
        closestDate = index
        closesDistance = distance
      }
    })
    if (closestDate && scrollRef.current) {
      setScrollIndex(closestDate)
    }
  }, [eventsByDay])

  useEffect(() => {
    if (!scrollIndex) {
      return
    } else if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [scrollIndex])

  return (
    <>
      <Box sx={{ marginTop: 5, marginBottom: 5 }}>
        {eventsByDay.map((day, index) => {
          return (
            <Box key={day.date} ref={index === scrollIndex ? scrollRef : null}>
              <SubHeader
                text={dayjs(day.date, "DD/MM/YYYY").format("dddd D MMMM")}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 6,
                  padding: 2,
                }}
              >
                {day.events.map((event) => {
                  return (
                    <EventCard
                      key={event.id}
                      event={event}
                      onClick={handleEventCardClick}
                    />
                  )
                })}
              </Box>
            </Box>
          )
        })}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
            style={{ marginTop: 50 }}
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#001C41",
              fontWeight: 500,
              fontSize: 14,
              textTransform: "capitalize",
            }}
            onClick={() => {
              handleCreateEvent()
            }}
          >
            Nouveau
          </Button>
        </Box>
      </Box>
      <EventModal
        resident={resident}
        config={modalConfig}
        events={residentEvents}
        onClose={() => setModalConfig({ isOpen: false })}
        onSubmit={onModalSubmit}
      />
    </>
  )
}
