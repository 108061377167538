import React, { useEffect, useState } from "react"
import { chain, sortBy } from "lodash"
import { Page } from "../../components/page/Page"
import "./dashboard.less"
import { bem } from "../../styles/bem"
import { getDocs, orderBy, query, where } from "firebase/firestore"
import { COLLECTIONS, converter } from "../../core/firebase"
import { Message, MessageDoc } from "../../domain/Message"
import { useAuthUser } from "../../core/auth"
import { QueryDocumentSnapshot } from "@firebase/firestore"
import { Filters, HeaderToolbar } from "./components/HeaderToolbar"
import { useWorkContext } from "../../contexts/work.context"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import { Box } from "@material-ui/core"
import { NewMessageRow } from "./components/NewMessageRow"
import { NotificationModal } from "./components/NotificationModal"
import { useResidents } from "../../contexts/residents.context"
import { ResidentDoc } from "../../domain/Resident"
import { IskusSpinner } from "../../components/IskusSpinner"

const b = "dashboard"

function filter(
  messages: QueryDocumentSnapshot<MessageDoc>[] | undefined,
  filters: Filters,
  searchText: string,
  residents: Record<string, ResidentDoc> | undefined
) {
  if (!messages) {
    return undefined
  }
  return chain(messages)
    .thru((messages) =>
      !filters.theme
        ? messages
        : messages.filter((m) => m.get("theme") === filters.theme)
    )
    .thru((messages) =>
      !filters.profile
        ? messages
        : messages.filter((m) => m.get("profiles").includes(filters.profile))
    )
    .thru((messages) =>
      !filters.levels
        ? messages
        : messages.filter((m) => filters.levels.includes(m.get("alertLevel")))
    )
    .thru((messages) =>
      !filters.zone
        ? messages
        : messages.filter((m) => filters.zone === m.get("zoneId"))
    )
    .thru((messages) =>
      searchText.length < 3
        ? messages
        : messages.filter((m) => {
            const resident = residents && residents[m.get("residentId")]
            return (
              resident &&
              `${resident.firstname}-${resident.lastname}-${resident.room}`
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
          })
    )
    .value()
}

export const Dashboard: React.FC = () => {
  usePageTitle(WebScreen.DASHBOARD_LIST)
  const { user } = useAuthUser()
  const { facility } = useWorkContext()
  const { residentById } = useResidents()
  const [filters, setFilters] = useState<Filters>({
    profile: "",
    theme: "",
    levels: ["warning", "danger"],
    zone: "",
  })
  const [searchText, setSearchText] = useState<string>("")
  const [selectedZoneId, setSelectedZoneId] = useState<string>()
  const [displayArchived, setDisplayArchived] = useState<boolean>(false)
  const [messages, setMessages] =
    useState<QueryDocumentSnapshot<MessageDoc>[]>()
  const [activeMessage, setActiveMessage] = useState<Message>()
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (!facility) {
      return
    }
    const toSelect = sortBy(facility.zones, (zone) => zone.index)[0]
    toSelect && setSelectedZoneId(toSelect.id)
  }, [facility])

  useEffect(() => {
    if (!user) {
      return
    }
    setMessages(undefined)

    const whereArchived = displayArchived
      ? where("isArchived", "in", [true, false])
      : where("isArchived", "==", false)

    const multiFacilityQuery = user.isMultiFacilities
      ? where("organizationId", "==", user.organizationId)
      : where("facilityId", "==", user.facilityId)

    console.log("multiFacilityQuery", multiFacilityQuery)

    getDocs<MessageDoc>(
      query<Message>(
        COLLECTIONS.MESSAGE,
        multiFacilityQuery,
        whereArchived,
        orderBy("creationDate", "desc")
      ).withConverter(converter<MessageDoc>())
    ).then((snapshot) => {
      console.log("snapshot", snapshot.docs)
      setMessages(snapshot.docs)
    })
  }, [user, displayArchived])

  if (!selectedZoneId) {
    return <IskusSpinner />
  }

  const filteredMessages = filter(messages, filters, searchText, residentById)

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderToolbar
        filters={filters}
        onSearchTextChange={setSearchText}
        displayArchived={displayArchived}
        onFiltersChange={setFilters}
        onDisplayArchivedChange={setDisplayArchived}
      />
      <Page>
        <Box
          sx={{
            marginTop: 50,
            maxWidth: "100%",
          }}
        >
          {!filteredMessages ? (
            <IskusSpinner />
          ) : (
            <div className={bem(b, "messages")}>
              {filteredMessages.map((message) => (
                <NewMessageRow
                  key={message.id}
                  message={message}
                  setActiveMessage={setActiveMessage}
                  setIsNotificationModalOpen={setIsNotificationModalOpen}
                />
              ))}
            </div>
          )}
        </Box>
        {activeMessage && (
          <NotificationModal
            data={activeMessage}
            isOpen={isNotificationModalOpen}
            setIsOpen={setIsNotificationModalOpen}
          />
        )}
      </Page>
    </Box>
  )
}
