import React, { useState } from "react"
import { Box, Table, TableBody } from "@mui/material"
import { ButtonAddIcon } from "../../../../components/Icon"
import { Center } from "../../../../components/Center"
import { singleDoc } from "../../../../core/firebase"
import { arrayRemove } from "firebase/firestore"
import { MedicalFileDoc, Pathology } from "../../../../domain/MedicalFile"
import { PathologiesHeader } from "./PathologiesHeader"
import { PathologyRow } from "./PathologyRow"
import { PathologyModal } from "./PathologyModal"

interface PathologiesProps {
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

export interface ModalConfig {
  isOpen: boolean
  isUpdate?: boolean
  pathology?: Pathology
  index?: number
}

export const Pathologies: React.FC<PathologiesProps> = ({
  medicalFile,
  setMedicalFile,
}) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
    pathology: undefined,
  })

  const pathologies = medicalFile.pathologies

  const onModalSubmit = (
    medicalFile: MedicalFileDoc,
    config: ModalConfig
  ): void => {
    setMedicalFile(medicalFile)
    setModalConfig(config)
  }

  const onAddClickHandler = () => {
    setModalConfig({ isOpen: true })
  }

  const onEditClickHandler = (index: number): void => {
    if (!pathologies) return
    setModalConfig({
      isOpen: true,
      isUpdate: true,
      index: index,
      pathology: pathologies[index],
    })
  }

  const onDeleteClickHandler = (index: number): void => {
    if (!pathologies) return
    singleDoc
      .medicalFile(medicalFile.id)
      .update({
        pathologies: arrayRemove(pathologies[index]),
      })
      .then(() => {
        setMedicalFile({
          ...medicalFile,
          pathologies: medicalFile.pathologies.filter((_, i) => i !== index),
        })
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <ButtonAddIcon buttonProps={{ onClick: () => onAddClickHandler() }} />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        <Table size="small" sx={{ minWidth: 400 }} aria-label="simple table">
          <PathologiesHeader />
          <TableBody>
            {pathologies?.map((pathology, index) => (
              <PathologyRow
                key={index} // TODO: FIND A BETTER KEY THAN INDEX
                index={index}
                onEditClick={(index) => onEditClickHandler(index)}
                pathology={pathology}
                onDeleteClick={(index) => onDeleteClickHandler(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <PathologyModal
        config={modalConfig}
        onClose={() => setModalConfig({ isOpen: false })}
        onSubmit={onModalSubmit}
        medicalFile={medicalFile}
      />
    </>
  )
}
