import React from "react"
import { Constants } from "../../../domain/Constants"
import { useWorkContext } from "../../../contexts/work.context"
import { Badge, Box, Chip, Typography } from "@mui/material"
import { AlertColors, AlertLevel } from "../../../domain/Message"

interface NotifConstantProps {
  constants: Constants
  isModal?: boolean
}

const AlertLevelBadge: React.FC<{ alertLevel: AlertLevel | undefined }> = ({
  alertLevel,
}) => {
  if (!alertLevel) {
    return null
  }
  const badgeColor = AlertColors[alertLevel].end as string
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 2,
      }}
    >
      <Chip
        sx={{
          backgroundColor: badgeColor,
          borderRadius: 100,
          width: 15,
          height: 15,
        }}
      />
    </Box>
  )
}

export const NotifConstant: React.FC<NotifConstantProps> = ({
  constants,
  isModal,
}) => {
  const { vitalSigns } = useWorkContext()
  const keys = Object.keys(constants.measures)
  const filteredVitalSigns = vitalSigns?.filter((vitalSign) =>
    keys.includes(vitalSign.id)
  )
  return (
    <Box>
      {filteredVitalSigns &&
        filteredVitalSigns.map(({ id, name, unit }) => (
          <Box
            key={id}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography sx={{ fontSize: isModal ? 20 : 16, color: "#FFFFFF" }}>
              {name} : {constants.measures[id].value} {unit}
            </Typography>
            <AlertLevelBadge alertLevel={constants.measures[id].alertLevel} />
          </Box>
        ))}
    </Box>
  )
}
