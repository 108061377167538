import { Timestamp } from "firebase/firestore"
import { Document } from "./Document"

export const dependencyLevels = [
  { id: 1, label: "GIR 1" },
  { id: 2, label: "GIR 2" },
  { id: 3, label: "GIR 3" },
  { id: 4, label: "GIR 4" },
  { id: 5, label: "GIR 5" },
  { id: 6, label: "GIR 6" },
]

export const dependencyLevelByValue = dependencyLevels.reduce(
  (byValue, level) => {
    byValue[level.id] = level.label
    return byValue
  },
  {} as Record<number, string>
)

export type QualifiedIdentity = "oui" | "non" | "en cours"
export type FamilyStatus =
  | "Célibataire"
  | "marié.e"
  | "pacsé.e"
  | "divorcé.e"
  | "veuf.ve"
  | "concubinage"
  | "séparé.e.s"
  | "autre"

export type Gender = "femme" | "homme" | "autre"

export type Ties =
  | "Fils"
  | "Fille"
  | "Conjoint(e )"
  | "Sœur"
  | "Frère"
  | "Famille proche"
  | "famille éloignée"
  | "ami(e )"
  | "Autre"

export interface Identity {
  firstname: string
  lastname: string
  birthname?: string
  birthdate?: Timestamp
  birthplace?: string
  familyStatus?: FamilyStatus
  gender?: Gender
  nationality?: string
  postalAddress?: string
  phoneNumber?: string
  identityDocumentNumber?: string
  INSEECode?: string
  qualifiedIdentity?: QualifiedIdentity
  administrativeIdentification?: string
  zoneId: string
  room: number
  height?: number // cm
  weight?: number // kg
  arrivalDate?: Timestamp
}

export interface HealthCare {
  socialSecurityNumber?: string
  socialSecurityScheme?: string
  healthFund?: string
  mutualInsuranceCompany?: string
  mutualInsuranceNumber?: string
  dependencyLevel?: number
  gpName?: string
  gpEmail?: string
  gpPhoneNumber?: string
}

export interface Acquaintance {
  name: string
  ties: Ties
  phoneNumber: string
  dayContact: boolean
  nightContact: boolean
}

export type LegalProtectionStatus =
  | "En cours"
  | "Demande en cours"
  | "Demande refusée"
  | "Terminé"

export interface LegalProtection {
  label: string
  status: LegalProtectionStatus
  updatedAt: Timestamp
  updatedBy: {
    id: string
    avatarUrl?: string
    initials: string
    color: string
    firstname: string
    lastname: string
    profileId: string
  }
  fileUrl?: string
}

export type idFilesNames =
  | "socialSecurityCard"
  | "idDocument"
  | "mutualCard"
  | "otherDoc"

export type ConsentStatus = "Accordé" | "Refusé" | "Demandé"

export interface LegalConsent {
  label: string
  status: ConsentStatus
  updatedAt: Timestamp
  updatedBy: {
    id: string
    avatarUrl?: string
    initials: string
    color: string
    firstname: string
    lastname: string
    profileId: string
  }
  fileUrl?: string
}

export interface AdministrativeFile {
  residentId: string
  facilityId: string
  organizationId: string
  identity: Identity
  healthCare: HealthCare
  acquaintances: Acquaintance[]
  legalProtections: LegalProtection[]
  consents: LegalConsent[]
  idFiles?: Record<idFilesNames, string>
  aboutResident?: string
}

export type AdministrativeFileDoc = Document & AdministrativeFile
