import React, { CSSProperties } from "react"

const style: CSSProperties = {
  backgroundColor: "rgba(255,255,255,0.5)",
  border: "9px solid #FFFFFF",
  borderRadius: "20px",
  padding: "20px 15px",
}

interface Props {
  className?: string
}

export const Panel: React.FC<Props> = ({ children, ...props }) => (
  <div style={style} {...props}>
    {children}
  </div>
)
