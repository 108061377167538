import React from "react"
import { Col, Row, Typography } from "antd"
import { I18 } from "../../../core/i18n"

export const HeaderToolbar: React.FC = () => {
  return (
    <Row>
      <Col>
        <Typography.Title level={4}>
          {I18.t("organisationHeaderTitle")}
        </Typography.Title>
      </Col>
    </Row>
  )
}
