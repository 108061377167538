import { Box } from "@mui/material"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useResidents } from "../../contexts/residents.context"
import { HeaderToolbar } from "./components/HeaderToolbar"
import { Page } from "../../components/page/Page"
import { CarePlan } from "./components/carePlan/CarePlan"
import { MedFile } from "./components/medicalFile/MedFile"
import { AdminFile } from "./components/administrativeFile/AdminFile"
import { ResidentPlanning } from "./components/residentPlanning/ResidentPlanning"

const TabPanel: React.FC<{
  activeTab: number
  index: number
  children: React.ReactNode
}> = ({ activeTab, index, children }) => {
  return (
    <Box hidden={activeTab !== index} sx={{}}>
      {children}
    </Box>
  )
}

export const Resident: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(3)
  const { residents } = useResidents()
  const { id: userId } = useParams<{ id: string }>()
  const resident = residents?.find((r) => r.id === userId)

  console.log("entering Resident.tsx with following resident: ", resident)

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <HeaderToolbar
        resident={resident}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Page>
        {resident && (
          <>
            <TabPanel activeTab={activeTab} index={0}>
              <AdminFile resident={resident} />
            </TabPanel>
            <TabPanel activeTab={activeTab} index={1}>
              <MedFile resident={resident} />
            </TabPanel>
            <TabPanel activeTab={activeTab} index={2}>
              <CarePlan resident={resident} />
            </TabPanel>
            <TabPanel activeTab={activeTab} index={3}>
              <ResidentPlanning resident={resident} />
            </TabPanel>
          </>
        )}
      </Page>
    </Box>
  )
}
