import React, { useEffect, useState } from "react"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import { useWorkContext } from "../../../contexts/work.context"
import { sortBy } from "lodash"

interface Props {
  setSelectedProfile: (profile: string) => void
  selectedProfile: string
}

export const SelectProfile: React.FC<Props> = (props: Props) => {
  const { setSelectedProfile, selectedProfile } = props
  const { profiles } = useWorkContext()

  const options = sortBy(
    profiles?.map(({ id, fullName }) => ({
      label: fullName,
      value: id,
    })),
    (option) => option.label
  )

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProfile(event.target.value)
  }

  return (
    <FormControl size="small" sx={{ minWidth: 220 }}>
      <InputLabel
        id="select-label"
        sx={{
          "&.MuiFormLabel-root": {
            color: "#FFFFFF99",
          },
        }}
      >
        Profil métier
      </InputLabel>
      <Select
        labelId="select-label"
        label="Profil métier"
        value={selectedProfile}
        onChange={handleChange}
        sx={{
          "&.MuiOutlinedInput-root": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFFFFF99",
          },
        }}
      >
        <MenuItem value={""}>
          <em>Tous les métiers</em>
        </MenuItem>
        {options &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
