import React from "react"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { Consent } from "./Consent"
import { Protection } from "./Protection"

interface LegalProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export const Legal: React.FC<LegalProps> = ({ adminFile, setAdminFile }) => {
  return (
    <>
      <Protection adminFile={adminFile} setAdminFile={setAdminFile} />
      <Consent adminFile={adminFile} setAdminFile={setAdminFile} />
    </>
  )
}
