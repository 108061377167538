import React, { useEffect } from "react"
import "./App.less"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Login } from "./pages/login/Login"
import { Dashboard } from "./pages/dashboard/Dashboard"
import { ProvideAuthUser, useAuthUser } from "./core/auth"
import { useNavigate } from "react-router-dom"
import "./core/firebase" // to ensure app loading
import { ResetPassword } from "./pages/login/ResetPassword"
import { ProvideResidents } from "./contexts/residents.context"
import { ProvideWorkContext } from "./contexts/work.context"
import { Staff } from "./pages/staff/Staff"
import { Residents } from "./pages/residents/Residents"
import { Organisation } from "./pages/organisation/Organisation"
import { Analytics } from "./pages/analytics/Analytics"
import { Screens, useRoutedAllowed } from "./pages/screen"
import { WebScreen } from "./domain/Role"
import { Facility } from "./pages/facility/Facility"
import MobileAppConfidentiality from "./pages/confidentiality/MobileAppConfidentiality"
import { NavigationDrawer } from "./components/drawer/NavigationDrawer"
import { Protocols } from "./pages/protocols/Protocols"
import { Resident } from "./pages/resident/Resident"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./styles/theme"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { frFR } from "@mui/x-date-pickers/locales"
import { Kitchen } from "./pages/kitchen/Kitchen"
import { IskusSpinner } from "./components/IskusSpinner"

function App() {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        frFR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <ProvideAuthUser>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </ProvideAuthUser>
    </LocalizationProvider>
  )
}

function AppRoutes() {
  const navigate = useNavigate()
  const location = useLocation()
  const { initializing, user } = useAuthUser()

  // TODO: avoid to navigate in render cycle, ignore 'navigate' in deps as it change for every render
  useEffect(() => {
    const pathName = location.pathname

    if (pathName === Screens[WebScreen.CONFIDENTIALITY].path) return

    const isLoginPage =
      pathName === "/" || pathName.startsWith(Screens[WebScreen.LOGIN].path)
    if (!initializing && user && isLoginPage) {
      navigate(Screens[WebScreen.DASHBOARD_LIST].path)
    }
    if (!initializing && !user) {
      navigate(Screens[WebScreen.LOGIN].path)
    }
  }, [initializing, user]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProvideWorkContext>
      <ProvideResidents>
        {/* allowing navigation to sit next to pages, not on top of them */}
        <div style={{ display: "flex" }}>
          <NavigationDrawer />
          <Routes>
            <Route path="/" element={<IskusSpinner />} />
            <Route path={Screens[WebScreen.LOGIN].path} element={<Login />} />
            <Route
              path={Screens[WebScreen.LOGIN_RESET_PWD].path}
              element={<ResetPassword />}
            />
            <Route
              path={Screens[WebScreen.CONFIDENTIALITY].path}
              element={<MobileAppConfidentiality />}
            />
            <Route
              path={Screens[WebScreen.DASHBOARD_LIST].path}
              element={
                <PrivateRoute screen={WebScreen.DASHBOARD_LIST}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.DASHBOARD_KPI].path}
              element={
                <PrivateRoute screen={WebScreen.DASHBOARD_KPI}>
                  <Analytics />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.ADMIN_FACILITY].path}
              element={
                <PrivateRoute screen={WebScreen.ADMIN_FACILITY}>
                  <Facility />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.PLANNING].path}
              element={
                <PrivateRoute screen={WebScreen.PLANNING}>
                  <Organisation />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.ADMIN_RESIDENTS].path}
              element={
                <PrivateRoute screen={WebScreen.ADMIN_RESIDENTS}>
                  <Residents />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.ADMIN_RESIDENT].path}
              element={
                <PrivateRoute screen={WebScreen.ADMIN_RESIDENT}>
                  <Resident />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.ADMIN_USERS].path}
              element={
                <PrivateRoute screen={WebScreen.ADMIN_USERS}>
                  <Staff />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.PROTOCOLS].path}
              element={
                <PrivateRoute screen={WebScreen.PROTOCOLS}>
                  <Protocols />
                </PrivateRoute>
              }
            />
            <Route
              path={Screens[WebScreen.KITCHEN].path}
              element={
                // <PrivateRoute screen={WebScreen.PROTOCOLS}>
                <Kitchen />
                // </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={<Navigate to={Screens[WebScreen.DASHBOARD_LIST].path} />}
            />
          </Routes>
        </div>
      </ProvideResidents>
    </ProvideWorkContext>
  )
}

const PrivateRoute: React.FC<{ screen: WebScreen }> = ({
  children,
  screen,
}) => {
  const allowed = useRoutedAllowed(screen)

  // If authorized, return an outlet that will render child elements
  return allowed ? (children as React.ReactElement) : null
}

export default App
