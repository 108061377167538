import React, { useEffect, useState } from "react"
import { countBy } from "lodash"
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts"
import { useWorkContext } from "../../../contexts/work.context"
import { Message } from "../../../domain/Message"
import { or0 } from "../../../core/lang"
import { ResidentDoc } from "../../../domain/Resident"
import { Flex } from "../../../components/Flex"
import { PanelTitle } from "../../../components/panel/PanelTitle"
import { Panel } from "../../../components/panel/Panel"
import { SelectResident } from "../../../components/select/SelectResident"
import { ZoneId } from "../../../domain/Zone"

interface Props {
  zone?: ZoneId
  messages: Message[]
}
interface ThemeMessagesSum {
  name: string
  color: string
  value: number
}

export const IncidentsOfResidentChart: React.FC<Props> = ({
  messages,
  zone,
}) => {
  const { messageButtons } = useWorkContext()
  const [resident, setResident] = useState<ResidentDoc>()

  const filteredMessages = !resident
    ? []
    : messages.filter((m) => m.residentId === resident.id)
  const countByTheme = countBy(filteredMessages, (m) => m.theme)

  const data = messageButtons?.map<ThemeMessagesSum>((button) => ({
    name: button.label,
    color: button.color,
    value: or0(countByTheme[button.theme]),
  }))

  useEffect(() => setResident(undefined), [zone])

  return (
    <Panel>
      <Flex justifyContent={"space-between"}>
        <PanelTitle description={"sur 1 mois"}>
          Détails pour un résident
        </PanelTitle>
        <SelectResident
          autoSelect
          filterZone={zone}
          value={resident}
          onChange={setResident}
        />
      </Flex>
      {!resident ? (
        <div style={{ height: 250 }}></div>
      ) : (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            height={250}
            data={data}
            stackOffset={"silhouette"}
            margin={{
              top: 20,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="name"
              interval={0}
              angle={-45}
              tickFormatter={(name: string) =>
                typeof name === "string" ? name.substring(0, 8) : ""
              }
            />
            <Tooltip />
            <Bar dataKey="value" cx="50%" cy="50%" label={{ position: "top" }}>
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Panel>
  )
}
