import React from "react"
import { Col, Row, Typography } from "antd"
import { Center } from "../../components/Center"
import "./login.less"
import { ResetPasswordForm } from "./ResetPasswordForm"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"

export const ResetPassword: React.FC = () => {
  usePageTitle(WebScreen.LOGIN_RESET_PWD)

  return (
    <Row className={"login"} style={{ width: "100%" }}>
      <Col className={"loginPage"} span={12}>
        <Center>
          <Typography.Title type="secondary">ISKUS</Typography.Title>
          <ResetPasswordForm />
        </Center>
      </Col>
      <Col className={"loginPicture"} span={12}></Col>
    </Row>
  )
}
