import React from "react"
import { useWorkContext } from "../../contexts/work.context"
import { CustomSelect } from "./CustomSelect"
import { SelectProps } from "@mui/material"
import { FacilityDoc } from "../../domain/Facility"

interface Props {
  value?: string
  onChange: (value: string) => void
  selectProps?: SelectProps
  selectedFacilities?: Record<string, FacilityDoc>
}

export const SelectFacility: React.FC<Props> = ({
  value,
  onChange,
  selectProps,
  selectedFacilities,
}) => {
  const { facilities } = useWorkContext()
  const facilitiesForKeys = selectedFacilities || facilities
  const keys = facilitiesForKeys && Object.keys(facilitiesForKeys)
  const facilitiesSelectOptions =
    (facilitiesForKeys &&
      keys?.map((facilityId) => ({
        label: facilitiesForKeys[facilityId].name,
        value: facilityId,
      }))) ||
    []

  return (
    <CustomSelect
      label="Etablissement"
      value={value}
      options={facilitiesSelectOptions}
      allOptionsLabel="Tous les établissements"
      onChange={onChange}
      selectProps={selectProps}
    />
  )
}
