import React from "react"
import { Box } from "@mui/material"
import { BoxProps } from "@mui/material"

export const Card: React.FC<{ boxProps?: BoxProps }> = ({
  boxProps,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        bgcolor: "#FFFFFF33",
        width: "100%",
        padding: 1,
        borderRadius: 2,
        border: "1px solid #FFFFFF",
      }}
      {...boxProps}
      {...props}
    >
      {children}
    </Box>
  )
}

export const EventCardContainer: React.FC<{ boxProps?: BoxProps }> = ({
  boxProps,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#FFFFFF33",
        borderRadius: 2,
        border: "1px solid #FFFFFF",
      }}
      {...boxProps}
      {...props}
    >
      {children}
    </Box>
  )
}
