import React from "react"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

interface Props {
  options: { id: string; label: string }[]
  value: string | undefined
  setValue: (value: string) => void
}

export const FormSelect: React.FC<Props> = ({ options, value, setValue }) => {
  return (
    <FormControl sx={{ minWidth: 200 }} variant="standard">
      <Select
        labelId="select-label"
        notched={true}
        displayEmpty={true}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          "&:after": {
            borderColor: "#FFFFFF",
          },
          "&:before": {
            borderColor: "white",
          },
          "&:not(.Mui-disabled):hover::before": {
            borderColor: "white",
          },
          "&.MuiSelect-standard": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFFFFF",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

interface PropsUncontrolled {
  options: { id: string | number; label: string }[]
}

export const FormSelectUncontrolled: React.FC<PropsUncontrolled> = ({
  options,
  ...field
}) => {
  return (
    <FormControl sx={{ minWidth: 200 }} variant="standard">
      <Select
        {...field}
        labelId="select-label"
        notched={true}
        displayEmpty={true}
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          "&:after": {
            borderColor: "#FFFFFF",
          },
          "&:before": {
            borderColor: "white",
          },
          "&:not(.Mui-disabled):hover::before": {
            borderColor: "white",
          },
          "&.MuiSelect-standard": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
          "& .MuiSelect-icon": {
            color: "#FFFFFF",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// options={[
//   { id: "Célibataire", label: "Célibataire" },
//   { id: "marié.e", label: "Marié.e" },
//   { id: "pacsé.e", label: "Pacsé.e" },
//   { id: "divorcé.e", label: "Divorcé.e" },
// ]}
