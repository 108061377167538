import { styled } from "@mui/material/styles"
import { TimeField } from "@mui/x-date-pickers"

export const MuiTimeField = styled(TimeField)({
  "& .MuiInputLabel-root": {
    color: "#FFFFFF99",
    "&.Mui-focused": {
      color: "#FFFFFF99",
    },
  },
  "& .MuiOutlinedInput-root": {
    color: "#FFFFFFE6",
    "& fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF99",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF99",
    },
  },
})
