import React, { useState } from "react"
import { Avatar, Box, Typography } from "@mui/material"
import { Icon, IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiCamera } from "@mdi/js"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { ResidentDoc } from "../../../../domain/Resident"
import { EditResidentAvatarModal } from "./EditResidentAvatar"

interface ResidentAvatarProps {
  resident: ResidentDoc
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export const ResidentAvatar: React.FC<ResidentAvatarProps> = ({ resident }) => {
  const [isEditing, setIsEditing] = useState(false)
  const avatarUrl = resident.avatarUrl

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiCamera} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"Avatar du résident"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, display: "flex", flexDirection: "row" }}>
        <Avatar
          variant={"rounded"}
          src={avatarUrl}
          sx={{
            backgroundColor: resident.color,
            height: 150,
            width: 150,
            marginRight: 1,
          }}
        >
          {resident.initials}
        </Avatar>
        <IconButton
          path={mdiPencil}
          color={"#FFFFFF8F"}
          buttonProps={{ onClick: () => setIsEditing(true) }}
        />
      </Box>
      <EditResidentAvatarModal
        resident={resident}
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
      />
    </>
  )
}
