import { Select } from "antd"
import { CustomSelect } from "./CustomSelect"
import React from "react"
import { useWorkContext } from "../../contexts/work.context"
import { FormControlProps, SelectProps } from "@mui/material"

interface Props {
  facilityId: string
  value?: string
  onChange?: (value: string) => void
  selectProps?: SelectProps
  formControlProps?: FormControlProps
  allOptionsLabel?: string
}

export const SelectTeam: React.FC<Props> = ({
  facilityId,
  value,
  onChange,
  selectProps,
  formControlProps,
  allOptionsLabel,
}) => {
  const { facilities } = useWorkContext()

  if (!facilities) return <></>

  const teamSelectOptions =
    facilities[facilityId]!.teams.map((team) => ({
      label: team.name,
      value: team.id,
    })) || []

  return (
    <CustomSelect
      label="Equipe"
      value={value}
      options={teamSelectOptions}
      allOptionsLabel={allOptionsLabel || "Toutes équipes"}
      onChange={onChange}
      selectProps={selectProps}
      formControlProps={formControlProps}
    />
  )
}
