import React, { useState } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material"
import { FormCell } from "../../../../components/form/FormCell"
import { FormRow } from "../../../../components/form/FormRow"
import { RowSeparator } from "../../../../components/form/RowSeparator"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import {
  AdministrativeFileDoc,
  HealthCare,
} from "../../../../domain/AdministrativeFile"
import { MuiTextField } from "../../../../components/TextField"
import { FormSelectUncontrolled } from "../../../../components/select/FormSelect"
import { MuiButton } from "../../../../components/Button"
import { singleDoc } from "../../../../core/firebase"
import { omitNils } from "../../../../core/lang"
import { DialogConfirm } from "../../../../components/DialogConfirm"
import { dependencyLevels } from "../../../../domain/AdministrativeFile"

const defaultValues: HealthCare = {
  socialSecurityNumber: "",
  socialSecurityScheme: "",
  healthFund: "",
  mutualInsuranceCompany: "",
  mutualInsuranceNumber: "",
  dependencyLevel: 6,
  gpName: "",
  gpEmail: "",
  gpPhoneNumber: "",
}

interface EditHealthCareProps {
  adminFile?: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
  setIsEditing: (isEditing: boolean) => void
}

export const EditHealthCare: React.FC<EditHealthCareProps> = ({
  adminFile,
  setAdminFile,
  setIsEditing,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { control, handleSubmit, getValues } = useForm<HealthCare>({
    defaultValues: adminFile?.healthCare || defaultValues,
  })

  const handleUpdateAdminFile = () => {
    const values = getValues()
    if (!adminFile) return
    const full = omitNils({
      ...values,
    })
    singleDoc
      .administrativeFile(adminFile?.id)
      .update({ healthCare: full })
      .then(() => {
        setAdminFile({ ...adminFile, healthCare: full })
        setIsEditing(false)
      })
  }

  const onFormSubmit: SubmitHandler<HealthCare> = (value: HealthCare) => {
    if (!adminFile) return
    setIsDialogOpen(true)
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Table
        sx={{ minWidth: 400, tableLayout: "fixed" }}
        aria-label="simple table"
      >
        <TableBody>
          <FormRow title={"N° Sécurité sociale"}>
            <FormCell
              content={
                <Controller
                  name="socialSecurityNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Régime générale"}>
            <FormCell
              content={
                <Controller
                  name="socialSecurityScheme"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Caisse d'affiliation"}>
            <FormCell
              content={
                <Controller
                  name="healthFund"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Mutuelle"}>
            <FormCell
              content={
                <Controller
                  name="mutualInsuranceCompany"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"N° adhérent mutuelle"}>
            <FormCell
              content={
                <Controller
                  name="mutualInsuranceNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Classement GIR"}>
            <FormCell
              content={
                <Controller
                  name="dependencyLevel"
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={dependencyLevels}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <RowSeparator />
          <FormRow title={"Médecin traitant"}>
            <FormCell
              content={
                <Controller
                  name="gpName"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Contact mail"}>
            <FormCell
              content={
                <Controller
                  name="gpEmail"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
          <FormRow title={"Contact téléphone"}>
            <FormCell
              content={
                <Controller
                  name="gpPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <MuiTextField
                      sx={{
                        "& .MuiInput-root": {
                          fontSize: 14,
                        },
                      }}
                      variant="standard"
                      type="text"
                      {...field}
                    />
                  )}
                />
              }
            />
          </FormRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={1} sx={{ border: 0 }} />
            <TableCell colSpan={1} sx={{ border: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 2,
                  width: "100%",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <MuiButton
                  onClick={() => {
                    setIsEditing(false)
                  }}
                >
                  Annuler
                </MuiButton>
                <MuiButton type="submit">Ok</MuiButton>
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <DialogConfirm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        title={"Mettre à jour la fiche administrative ?"}
        onConfirm={() => handleUpdateAdminFile()}
        cancelText="Annuler"
        confirmText="Mettre à jour"
      />
    </form>
  )
}
