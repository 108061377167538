import { useAuthUser } from "../../../core/auth"
import { SelectCategory } from "./SelectCategory"
import { SelectProfile } from "./SelectProfile"
import { TextSearch } from "./TextSearch"
import { HeaderNew } from "../../../components/header/HeaderNew"
import { Box } from "@mui/material"

interface Props {
  setSelectedCategory: (category: string) => void
  setSelectedProfile: (profil: string) => void
  selectedCategory: string
  selectedProfile: string
  availableCategories: string[]
  setSearchText: (text: string) => void
}

const allowedRolesIds = ["admin", "interdepartmental"]

export const HeaderToolbar: React.FC<Props> = (props: Props) => {
  const {
    setSelectedCategory,
    selectedCategory,
    setSelectedProfile,
    selectedProfile,
    availableCategories,
    setSearchText,
  } = props

  const { user } = useAuthUser()

  const showSelectProfile = user && allowedRolesIds.includes(user.roleId)

  return (
    <HeaderNew title="Protocoles">
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <TextSearch setSearchText={setSearchText} />
        <SelectCategory
          availableCategories={availableCategories}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        {showSelectProfile && (
          <SelectProfile
            setSelectedProfile={setSelectedProfile}
            selectedProfile={selectedProfile}
          />
        )}
      </Box>
    </HeaderNew>
  )
}
