import { Document } from "./Document"
import { Timestamp } from "../core/firebase"

export type MessageTheme = string
export type AlertLevel = "pending" | "ok" | "warning" | "danger"
export const AlertLevels: ReadonlyArray<AlertLevel> = [
  "pending",
  "ok",
  "warning",
  "danger",
] as const

export type AlertGradient = { start: string; end: string; label: string }
export const AlertColors: Record<AlertLevel, AlertGradient> = {
  ok: { start: "#66BB6A", end: "#009A6B", label: "white" },
  pending: { start: "#0288D1", end: "#446AF0", label: "white" },
  warning: { start: "#FFA726", end: "#F66D3A", label: "white" },
  danger: { start: "#F44336", end: "#CC0000", label: "white" },
}

export type CategoryStyle = {
  shortHeader: string
  header: string
  color: string
}

export const CommentCategories: Record<string, CategoryStyle> = {
  problems: {
    shortHeader: "C",
    header: "Problèmes rencontrés",
    color: "#FFFFFF",
  },
  observations: { shortHeader: "C", header: "Constat", color: "#FFFFFF" },
  actions: { shortHeader: "P", header: "Prise en charge", color: "#90CAF9" },
  results: { shortHeader: "B", header: "Bilan", color: "#00D361" },
}

export interface FallInputs {
  witnesses?: string
  location?: string
  positionBefore?: string
  positionAfter?: string
  equipment?: string[]
  riskElements?: string
  outfit?: string[]
}

export interface Message {
  facilityId: string
  residentId: string
  zoneId: string
  theme: MessageTheme
  user: {
    id: string
    avatarUrl: string
    initials: string
    firstname: string
    lastname: string
    profileId: string
    color: string
  }
  creationDate: Timestamp
  comments: Record<string, string>
  comment: string
  isArchived: boolean
  alertLevel: AlertLevel
  profiles: string[]
  organizationId: string
  transmissionId: string
  selectedTags?: Record<string, string[]>
  archivedDate?: Timestamp
  archivedBy?: string
  archiveComment?: string
  parentTheme?: MessageTheme
  painLevel?: number
  fall?: FallInputs
  constantsDocId?: string
}

export type MessageDoc = Document & Message

export const colorOfLevel = (level?: AlertLevel): AlertGradient => {
  const alertLevel: AlertLevel = level || "ok"
  return AlertColors[alertLevel] || AlertColors["ok"]
}
