import React, {
  Context,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import keyBy from "lodash/keyBy"
import { Resident, ResidentDoc } from "../domain/Resident"
import {
  onSnapshot,
  doc,
  QueryDocumentSnapshot,
  getDocs,
  query,
  where,
  getDoc,
} from "firebase/firestore"
import { COLLECTIONS, converter, db } from "../core/firebase"
import { useAuthUser } from "../core/auth"
import { orEmpty } from "../core/lang"
import firebase from "firebase/compat"

interface ResidentsContext {
  residents?: ResidentDoc[]
  residentById: Record<string, ResidentDoc>
  current?: QueryDocumentSnapshot<ResidentDoc>
  setCurrent: (resident: ResidentDoc) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const residentsContext: Context<ResidentsContext> = createContext(null) as any

export function useResidents(): ResidentsContext {
  return useContext(residentsContext)
}

const watchResident = (
  resident: ResidentDoc,
  onChange: (resident: QueryDocumentSnapshot<ResidentDoc>) => void
): Function => {
  return onSnapshot(doc(COLLECTIONS.RESIDENT_DOC, resident.id), (doc) => {
    console.log("Document data:", doc.data())
    if (doc.exists()) {
      console.log("Current resident has been updated")
      onChange(doc as QueryDocumentSnapshot<ResidentDoc>)
    }
  })
}

export const ProvideResidents: React.FC = ({ children }) => {
  const { user } = useAuthUser()
  const [residents, setResidents] = useState<ResidentDoc[]>()
  const [current, setCurrent] = useState<QueryDocumentSnapshot<ResidentDoc>>()
  const residentById = useMemo(
    () => keyBy(orEmpty(residents), (r) => r.id),
    [residents]
  )
  const watchUnsubscribe = useRef<Function>(() => {})

  const updateCurrent = useCallback((resident: ResidentDoc) => {
    console.log("updateCurrent", resident)
    watchUnsubscribe.current()
    watchUnsubscribe.current = watchResident(resident, setCurrent)
  }, [])

  useEffect(() => {
    if (!user) {
      return
    }
    setResidents(undefined)

    const multiFacilityQuery = user.isMultiFacilities
      ? where("organizationId", "==", user.organizationId)
      : where("facilityId", "==", user.facilityId)

    const unsubscribe = onSnapshot(
      query<ResidentDoc>(COLLECTIONS.RESIDENT_DOC, multiFacilityQuery),
      (snapshot) => {
        setResidents(
          snapshot.docs.map((d) => {
            return { ...d.data(), id: d.id }
          })
        )
      }
    )
    return () => unsubscribe()
  }, [user])

  return (
    <residentsContext.Provider
      value={{ residents, residentById, current, setCurrent: updateCurrent }}
    >
      {children}
    </residentsContext.Provider>
  )
}
