import React from "react"
import { countBy } from "lodash"
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts"
import { useWorkContext } from "../../../contexts/work.context"
import { Message } from "../../../domain/Message"
import { or0 } from "../../../core/lang"

interface Props {
  messages: Message[]
}
interface ThemeMessagesSum {
  name: string
  color: string
  value: number
}

const renderPercentageLabel = (label: { percent: number }) => {
  return `${(label.percent * 100).toFixed(0)}%`
}

export const IncidentsSumChart: React.FC<Props> = ({ messages }) => {
  const { messageButtons } = useWorkContext()
  const countByTheme = countBy(messages, (m) => m.theme)

  const data = messageButtons?.map<ThemeMessagesSum>((button) => ({
    name: button.label,
    color: button.color,
    value: or0(countByTheme[button.theme]),
  }))

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart height={250}>
        <Tooltip />
        <Legend
          width={150}
          layout={"vertical"}
          verticalAlign="top"
          align={"right"}
        />
        <Pie
          outerRadius={75}
          dataKey="value"
          cx="40%"
          cy="50%"
          data={data}
          paddingAngle={5}
          labelLine={false}
          label={renderPercentageLabel}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
