import React, { useState } from "react"
import { Box, Table, TableBody, Typography } from "@mui/material"
import { Icon } from "../../../../components/Icon"
import { useWorkContext } from "../../../../contexts/work.context"
import { dayjsOf } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiAccount } from "@mdi/js"
import { Center } from "../../../../components/Center"
import { MuiDatePicker } from "../../../../components/DateField"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { EditIdentity } from "./EditIdentity"
import { FormCell } from "../../../../components/form/FormCell"
import { FormRow } from "../../../../components/form/FormRow"
import { RowSeparator } from "../../../../components/form/RowSeparator"

interface IdentityProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export const Identity: React.FC<IdentityProps> = ({
  adminFile,
  setAdminFile,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const identity = adminFile.identity
  const { facility, facilities } = useWorkContext()
  const residentFacility = facilities && facilities[adminFile.facilityId]
  const zoneLabel = facilities
    ? residentFacility?.zones.find((zone) => zone.id === identity.zoneId)?.label
    : facility?.zones.find((zone) => zone.id === identity.zoneId)?.label

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiAccount} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"Identité"}
          </Typography>
        </Box>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          {!isEditing && (
            <IconButton
              path={mdiPencil}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  setIsEditing(true)
                },
              }}
            />
          )}
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
        }}
      >
        {!isEditing ? (
          <Table
            sx={{ minWidth: 400, tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableBody>
              <FormRow title={"Nom"}>
                <FormCell content={identity.lastname} />
              </FormRow>
              <FormRow title={"Prénom"}>
                <FormCell content={identity.firstname} />
              </FormRow>
              <FormRow title={"Nom de naissance"}>
                <FormCell content={identity.birthname} />
              </FormRow>
              <FormRow title={"Date de naissance"}>
                <MuiDatePicker
                  format="DD-MM-YYYY"
                  value={identity.birthdate && dayjsOf(identity.birthdate)}
                  readOnly
                  disableOpenPicker={true}
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: 0,
                    },
                  }}
                />
              </FormRow>
              <FormRow title={"Lieu de naissance"}>
                <FormCell content={identity.birthplace} />
              </FormRow>
              <FormRow title={"Situation de famille"}>
                <FormCell content={identity.familyStatus} />
              </FormRow>
              <FormRow title={"Genre"}>
                <FormCell content={identity.gender} />
              </FormRow>
              <FormRow title={"Nationalité"}>
                <FormCell content={identity.nationality} />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Adresse"}>
                <FormCell content={identity.postalAddress} />
              </FormRow>
              <FormRow title={"Numéro de téléphone"}>
                <FormCell content={identity.phoneNumber} />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Numéro carte d'identité"}>
                <FormCell content={identity.identityDocumentNumber} />
              </FormRow>
              <FormRow title={"code Insee (lieu Inc)"}>
                <FormCell content={identity.INSEECode} />
              </FormRow>
              <FormRow title={"Identité Qualifiée"}>
                <FormCell content={identity.qualifiedIdentity} />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Identification administrative"}>
                <FormCell content={identity.administrativeIdentification} />
              </FormRow>
              <FormRow title={"Numéro de chambre"}>
                <FormCell content={identity.room} />
              </FormRow>
              <FormRow title={"Zone"}>
                <FormCell content={zoneLabel} />
              </FormRow>
              <FormRow title={"Date d'entrée"}>
                <MuiDatePicker
                  format="DD-MM-YYYY"
                  value={identity.arrivalDate && dayjsOf(identity.arrivalDate)}
                  readOnly
                  disableOpenPicker={true}
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: 0,
                    },
                  }}
                />
              </FormRow>
              <RowSeparator />
              <FormRow title={"Taille (cm)"}>
                <FormCell content={identity.height} />
              </FormRow>
              <FormRow title={"Poids initial (kg)"}>
                <FormCell content={identity.weight} />
              </FormRow>
            </TableBody>
          </Table>
        ) : (
          <EditIdentity
            facility={residentFacility}
            adminFile={adminFile}
            setAdminFile={setAdminFile}
            setIsEditing={setIsEditing}
          />
        )}
      </Box>
    </>
  )
}
