import React, { useMemo } from "react"
import TextField from "@mui/material/TextField"
import debounce from "lodash/debounce"
import Box from "@mui/material/Box"

interface Props {
  setSearchText: (text: string) => void
}

export const TextSearch: React.FC<Props> = ({ setSearchText }: Props) => {
  const debouncedSetSearchText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  )

  return (
    <Box component="form" autoComplete="off" sx={{ minWidth: 220 }}>
      <TextField
        id="text-field"
        label="Rechercher par nom, mot clé..."
        variant="outlined"
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "#FFFFFF99",
            "&.Mui-focused": {
              color: "#FFFFFF99",
            },
          },
          "& .MuiOutlinedInput-root": {
            color: "#FFFFFFE6",
            "& fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF99",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF99",
            },
          },
        }}
        onChange={(event) => debouncedSetSearchText(event.target.value)}
      />
    </Box>
  )
}
