import React, { useState, useEffect } from "react"
import { getDocs, query } from "firebase/firestore"
import { COLLECTIONS, converter } from "../../core/firebase"
import { ProtocolCard } from "./components/ProtocolCard"
import Grid from "@mui/material/Grid"
import { Box } from "@material-ui/core"
import { Protocol, ProtocolDoc } from "../../domain/Protocol"
import { HeaderToolbar } from "./components/HeaderToolbar"
import { chain } from "lodash"
import { useAuthUser } from "../../core/auth"
import { UserDoc } from "../../domain/User"
import { Page } from "../../components/page/Page"

const filter = (
  protocols: Protocol[],
  selectedProfile: string,
  selectedCategory: string,
  searchText: string,
  user?: UserDoc
) => {
  if (!protocols || !user) {
    return undefined
  }

  return chain(protocols)
    .thru((protocols) =>
      user.roleId === "admin" || user.roleId === "interdepartmental"
        ? protocols
        : protocols.filter((p) => p.profiles.includes(user.profileId))
    )
    .thru((protocols) =>
      !selectedProfile
        ? protocols
        : protocols.filter((p) => p.profiles.includes(selectedProfile))
    )
    .thru((protocols) =>
      !selectedCategory
        ? protocols
        : protocols.filter((p) => p.category === selectedCategory)
    )
    .thru((protocols) =>
      searchText
        ? protocols.filter((p) =>
            `${p.name}-${p.category}-${p.keywords?.join("-")}`
              .toLowerCase()
              .includes(searchText.toLowerCase().trim())
          )
        : protocols
    )
    .value()
}

export const Protocols: React.FC = () => {
  const [protocols, setProtocols] = useState<Protocol[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string>("")
  const [selectedProfile, setSelectedProfile] = useState<string>("")
  const [searchText, setSearchText] = useState<string>("")

  const { user } = useAuthUser()

  const organizationId = user?.organizationId

  useEffect(() => {
    getDocs<ProtocolDoc>(
      query<Protocol>(COLLECTIONS.PROTOCOLS).withConverter(
        converter<ProtocolDoc>()
      )
    ).then((snapshot) => {
      setProtocols(snapshot.docs.map((d) => d.data()))
      user && setSelectedProfile(user?.profileId)
    })
  }, [user, organizationId])

  const filteredProtocols = filter(
    protocols,
    selectedProfile,
    selectedCategory,
    searchText,
    user
  )

  const availableCategories = !selectedProfile
    ? protocols.map((p) => p.category)
    : protocols
        .filter((p) => p.profiles.includes(selectedProfile))
        .reduce<string[]>((acc, p): string[] => {
          return acc.includes(p.category) ? acc : [...acc, p.category]
        }, [])

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderToolbar
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        setSelectedProfile={setSelectedProfile}
        selectedProfile={selectedProfile}
        availableCategories={availableCategories}
        setSearchText={setSearchText}
      />
      <Page>
        <Box sx={{ m: 4 }}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {filteredProtocols?.map((protocol) => (
              <Grid key={protocol.name} item xs="auto">
                <ProtocolCard protocol={protocol} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Page>
    </Box>
  )
}
