import { mapValues } from "lodash"
import { Message, MessageDoc } from "../../domain/Message"
import {
  ProfilePresence,
  ProfilesPresence,
} from "./profilesPresenceFromTimesheets"
import { ProfileId } from "../../domain/Profile"

export type ProfileAlerts = {
  ratio: number
  warning: number
  danger: number
}
export type ProfilesAlerts = Record<ProfileId, ProfileAlerts>

export function profilesAlerts(
  messages: MessageDoc[],
  profiles: ProfileId[],
  presences: ProfilesPresence
): ProfilesAlerts {
  const builderByProfile = messages.reduce((builders, msg) => {
    msg.profiles
      .filter((profile) => profiles.includes(profile))
      .forEach((profile) => builders[profile].addMessage(msg))
    return builders
  }, initProfilesBuilders(profiles, presences))

  return mapValues(builderByProfile, (builder) => builder.build())
}

type ProfilesAlertsBuilder = Record<ProfileId, Builder>

function initProfilesBuilders(
  profiles: ProfileId[],
  presences: ProfilesPresence
): ProfilesAlertsBuilder {
  return profiles.reduce((builders, profile) => {
    builders[profile] = new Builder(presences[profile])
    return builders
  }, {} as ProfilesAlertsBuilder)
}

class Builder {
  private dangers = 0
  private warns = 0

  constructor(readonly presences: ProfilePresence) {}

  addMessage(msg: Message): void {
    if (msg.alertLevel === "danger") {
      this.dangers++
    }
    if (msg.alertLevel === "warning") {
      this.warns++
    }
  }

  build(): ProfileAlerts {
    const ratio = (this.warns + this.dangers * 2) / this.presences.total
    return {
      danger: this.dangers,
      warning: this.warns,
      ratio: Number.isFinite(ratio) ? ratio : 0,
    }
  }
}
