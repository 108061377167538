export const bm = (b: string, m: Record<string, boolean> = {}) => {
  const modifiers = Object.entries(m).reduce(
    (acc, [modifier, enabled]) => (enabled ? `${acc} ${b}--${modifier}` : acc),
    ""
  )
  return `${b} ${modifiers}`
}

export const bem = (b: string, e: string, m: Record<string, boolean> = {}) => {
  const modifiers = Object.entries(m).reduce(
    (acc, [modifier, enabled]) => (enabled ? `${b}-${e}--${modifier}` : acc),
    ""
  )
  return `${b}-${e} ${modifiers}`
}

export const classes = (...classNames: string[]) => {
  return classNames.join(" ")
}
