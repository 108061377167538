import React, { useEffect, useMemo, useState } from "react"
import { Page } from "../../components/page/Page"
import { getDocs, query, where } from "firebase/firestore"
import {
  COLLECTIONS,
  converter,
  createDoc,
  getOrganizationFacilities,
  sendPasswordResetEmailTo,
  singleDoc,
} from "../../core/firebase"
import { useAuthUser } from "../../core/auth"
import { User, UserDoc } from "../../domain/User"
import { Filters, HeaderToolbar } from "./components/HeaderToolbar"
import { Modal } from "antd"
import { useWorkContext } from "../../contexts/work.context"
// import { CreateUserModal, ModalConfig } from "./components/CreateUserModal"
import { userPasswd } from "../../domain/common"
import { I18 } from "../../core/i18n"
import "./staff.less"
import { usePageTitle } from "../usePageTitle"
import { WebScreen } from "../../domain/Role"
import createUser from "../../core/cloudFunctions"
// NEW IMPORTS
import { Box } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { Header } from "./components/TableHeader"
import { Row } from "./components/TableRow"
import { UserModal, ModalConfig } from "./components/UserModal"
import { IskusSpinner } from "../../components/IskusSpinner"
import { chain } from "lodash"
import { ProfileDoc } from "../../domain/Profile"
import { FacilityDoc } from "../../domain/Facility"

function filter(
  users: UserDoc[] | undefined,
  filters: Filters,
  searchText: string,
  roleLabelById: Record<string, string>,
  profiles?: ProfileDoc[]
) {
  if (!users) {
    return undefined
  }
  return chain(users)
    .thru((users) =>
      !filters.facility
        ? users
        : users.filter((u) => u.facilityId === filters.facility)
    )
    .thru((users) =>
      searchText.length < 3
        ? users
        : users.filter((u) =>
            `${u.firstname}-${u.lastname}-${u.email}-${
              roleLabelById[u.roleId]
            }-${
              profiles?.filter((p) => p.id.includes(u.profileId))[0]?.fullName
            }`
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
    )
    .value()
}

export const Staff: React.FC = () => {
  usePageTitle(WebScreen.ADMIN_USERS)
  const { roleLabelById, profiles, currentOrganizationId } = useWorkContext()
  const { user } = useAuthUser()
  const [filters, setFilters] = useState<Filters>({
    facility: "",
  })
  const [searchText, setSearchText] = useState<string>("")
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })
  const [users, setUsers] = useState<UserDoc[]>()

  const filteredUsers = useMemo(() => {
    return filter(users, filters, searchText, roleLabelById, profiles)?.sort(
      (a, b) =>
        a.lastname.localeCompare(b.lastname) ||
        a.firstname.localeCompare(b.firstname)
    )
  }, [users, filters, searchText, roleLabelById, profiles])

  const selectedFacilities = useMemo(() => {
    if (!user || !user.isSuperAdmin || !currentOrganizationId) {
      return undefined
    }
    const facilitiesDict = {} as Record<string, FacilityDoc>
    getOrganizationFacilities(currentOrganizationId).then((facilities) => {
      facilities.docs.map((doc) => {
        facilitiesDict[doc.data().id] = doc.data()
      })
    })
    return facilitiesDict
  }, [user, currentOrganizationId])

  useEffect(() => {
    if (!user) {
      return
    }
    setUsers(undefined)

    const multiFacilityQuery =
      user.isSuperAdmin && currentOrganizationId
        ? where("organizationId", "==", currentOrganizationId)
        : user.isMultiFacilities
        ? where("organizationId", "==", user.organizationId)
        : where("facilityId", "==", user.facilityId)

    getDocs<UserDoc>(
      query<User>(
        COLLECTIONS.USERS,
        multiFacilityQuery,
        where("isArchived", "==", false)
      ).withConverter(converter<UserDoc>())
    ).then((snapshot) => {
      setUsers(snapshot.docs.map((d) => d.data()))
    })
  }, [user, currentOrganizationId])

  const generateUserInAuth = (user: UserDoc) => {
    const randomPassword = userPasswd()
    createUser(user.email, randomPassword)
      .then(() => displayPasswordModal(user, randomPassword))
      .then(() => setUsers([user, ...(users || [])]))
      .catch(displayUserCreationError)
  }

  const updateUser = (user: UserDoc) => {
    setUsers(users?.map((u) => (u.id === user.id ? user : u)))
  }

  const onEditClick = (editing: UserDoc) => {
    setModalConfig({ isOpen: true, isUpdate: true, user: editing })
  }

  const onRemove = (removing: UserDoc) => {
    singleDoc
      .user(removing.id)
      .update({ isActive: false, isArchived: true })
      .then(() => setUsers(users?.filter((u) => u.id !== removing.id)))
  }

  const onResetPassword = (ofUser: UserDoc) => {
    sendPasswordResetEmailTo(ofUser.email).then(() =>
      displayPasswordResetEmailSentModal(ofUser)
    )
  }

  if (!user) {
    return null
  }

  function onModalSubmit(user: UserDoc, config: ModalConfig) {
    if (!config.isUpdate) {
      console.log("creating token, then user in auth")
      createDoc(COLLECTIONS.TOKEN, {
        userId: user.id,
        email: user.email,
      }).then(() => generateUserInAuth(user))
    } else {
      updateUser(user)
    }
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <HeaderToolbar
        filters={filters}
        onFiltersChange={setFilters}
        onSearchTextChange={setSearchText}
        onCreate={() => setModalConfig({ isOpen: true, isUpdate: false })}
        selectedFacilities={selectedFacilities}
      />
      <Page>
        {!users ? (
          <IskusSpinner />
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: "#FFFFFF33",
                marginTop: 5,
                borderRadius: 1,
                padding: 2,
              }}
            >
              {/* NEW TABLE */}
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <Header />
                <TableBody>
                  {filteredUsers?.map((user) => (
                    <Row
                      key={user.id}
                      user={user}
                      onEditClick={onEditClick}
                      onRemove={onRemove}
                      onResetPassword={onResetPassword}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        {modalConfig.isOpen && (
          <UserModal
            admin={user}
            config={modalConfig}
            onClose={() => setModalConfig({ isOpen: false })}
            onSubmit={onModalSubmit}
            selectedFacilities={selectedFacilities}
          />
        )}
      </Page>
    </Box>
  )
}

function displayPasswordModal(user: UserDoc, randomPassword: string) {
  Modal.info({
    title: I18.t("staffNewPasswordTitle", user),
    content: (
      <div>
        <p>{I18.t("staffNewPasswordDescription1", user)}</p>
        <p>{I18.t("staffNewPasswordDescription2")}</p>
        <h1 style={{ textAlign: "center" }}>{randomPassword}</h1>
      </div>
    ),
  })
}

function displayPasswordResetEmailSentModal(user: UserDoc) {
  Modal.info({
    title: I18.t("staffNewPasswordTitle", user),
    content: (
      <div>
        <p>{I18.t("staffPasswordResetEmailDescription1", user)}</p>
        <p>{I18.t("staffPasswordResetEmailDescription2")}</p>
      </div>
    ),
  })
}

function displayUserCreationError(error: Error) {
  Modal.error({
    title: I18.t("error"),
    content: (
      <div>
        <p>{I18.t("staffCreateUserError")}</p>
        <p>{error?.message}</p>
      </div>
    ),
  })
}
