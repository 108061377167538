import Card from "@mui/material/Card"
import Tooltip from "@mui/material/Tooltip"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import image from "../../../assets/protocol-image.png"
import { Protocol } from "../../../domain/Protocol"
import { dayjsOf } from "../../../core/firebase"
import { Box } from "@mui/material"

interface ProtocolCardProps {
  protocol: Protocol
}

export const ProtocolCard: React.FC<ProtocolCardProps> = (
  props: ProtocolCardProps
) => {
  const { name, creationDate, updateDate, version, category, fileUrl } =
    props.protocol

  return (
    <Tooltip title="Ouvrir le document">
      <CardActionArea href={fileUrl} target="_blank">
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            width: 240,
            height: 325,
            backgroundColor: "#FFFFFF1A",
          }}
        >
          <CardMedia component="img" sx={{ height: 160 }} src={image} />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <Tooltip title={name} placement="top">
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  maxHeight: 60,
                  overflow: "hidden",
                }}
              >
                {name}
              </Typography>
            </Tooltip>
            <Box sx={{ color: "#90CAF9", display: "flex" }}>
              <Typography>
                {dayjsOf(updateDate ? updateDate : creationDate)?.format(
                  "DD MMMM YYYY"
                )}
              </Typography>
              <Typography> &nbsp;• v{version}</Typography>
            </Box>
            <Chip
              label={category}
              sx={{ color: "#FFFFFF", backgroundColor: "#FFFFFF29" }}
            />
          </CardContent>
        </Card>
      </CardActionArea>
    </Tooltip>
  )
}
