import React, { useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../../components/Button"
import { HexColorPicker } from "react-colorful"
import { AvatarUploader } from "../../../../components/AvatarUploader"
import { useWorkContext } from "../../../../contexts/work.context"
import { singleDoc } from "../../../../core/firebase"
import { omitNils } from "../../../../core/lang"
import { IconButton } from "../../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { Resident, ResidentDoc } from "../../../../domain/Resident"
import { useResidents } from "../../../../contexts/residents.context"
import { IskusSpinner } from "../../../../components/IskusSpinner"

type FormValues = {
  color: string
  avatarUrl: string
}

interface EditResidentAvatarModalProps {
  resident: ResidentDoc
  isOpen: boolean
  onClose: () => void
}

export const defaultValues: FormValues = {
  color: "",
  avatarUrl: "",
}

export const EditResidentAvatarModal: React.FC<EditResidentAvatarModalProps> =
  ({ resident, isOpen, onClose }) => {
    const { facility, facilities } = useWorkContext()
    const { setCurrent } = useResidents()
    const { control, handleSubmit } = useForm<FormValues>({
      defaultValues:
        { color: resident.color, avatarUrl: resident.avatarUrl } ||
        defaultValues,
    })
    const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
    const facilityName = facilities
      ? facilities[resident.facilityId].name
      : facility?.name

    const updateResident = (values: FormValues): Promise<ResidentDoc> => {
      const full: Resident = omitNils({
        ...resident,
        alerts: { ...resident.alerts },
        constants: { ...resident.constants },
        color: values.color,
        avatarUrl: values.avatarUrl,
      })

      return singleDoc
        .resident(resident.id)
        .update({ color: values.color, avatarUrl: values.avatarUrl })
        .then(() => ({ ...full, id: resident.id }))
    }

    // TODO: FIX THIS
    const onFormSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
      setIsConfirmationLoading(true)
      updateResident(values)
        .then((residentDoc) => {
          setCurrent(residentDoc)
          setIsConfirmationLoading(false)
          onClose()
        })
        .catch((e) => {
          console.log("Error", e)
          setIsConfirmationLoading(false)
        })
    }

    return (
      <>
        {isConfirmLoading ? (
          <IskusSpinner />
        ) : (
          <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
          >
            <DialogContent
              sx={{
                backgroundColor: "#374D70",
                padding: 3,
                borderWidth: 1,
                borderColor: "#FFFFFF",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: 24,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {"Editer l'avatar"}
                </Typography>
                <IconButton
                  buttonProps={{
                    onClick: () => onClose(),
                  }}
                  path={mdiWindowClose}
                  color={"#FFFFFF"}
                />
              </Box>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                      {"Couleur: "}
                    </Typography>
                    <Controller
                      name="color"
                      control={control}
                      render={({ field }) => <HexColorPicker {...field} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                      {"Avatar: "}
                    </Typography>
                    <Controller
                      name="avatarUrl"
                      control={control}
                      render={({ field }) => (
                        <AvatarUploader
                          {...field}
                          filenamePrefix={`${facilityName}-${resident?.lastname}-${resident?.firstname}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: 2,
                        width: "100%",
                        alignItems: "center",
                        marginTop: 2,
                      }}
                    >
                      <MuiButton
                        onClick={() => {
                          onClose()
                        }}
                      >
                        Annuler
                      </MuiButton>
                      <MuiButton type="submit">Ok</MuiButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  }
