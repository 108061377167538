import React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material"
import {
  Timestamp,
  dayjsOf,
  singleDoc,
  timestampOf,
} from "../../../../core/firebase"
import { useWorkContext } from "../../../../contexts/work.context"
import { RHFCheckboxes } from "../../../../components/checklist/CustomChecklist"
import { FormSelectUncontrolled } from "../../../../components/select/FormSelect"
import { MuiContainedButton } from "../../../../components/Button"
import { DietEntry, MedicalFileDoc } from "../../../../domain/MedicalFile"
import { ResidentDoc } from "../../../../domain/Resident"
import { MuiDatePicker } from "../../../../components/DateField"
import { Dayjs } from "dayjs"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { omitNils } from "../../../../core/lang"
import firebase from "firebase/compat"

type DietInputs = {
  diet?: DietEntry[]
  allergiesRestrictions?: DietEntry[]
  feedingMethod: DietEntry
  texture: DietEntry
  hydratation?: DietEntry[]
  endDate?: Timestamp
}

interface FormValues {
  diet?: DietEntry[]
  allergiesRestrictions?: DietEntry[]
  feedingMethod?: string
  texture?: string
  hydratation?: DietEntry[]
  endDate?: Dayjs
}

const defaultValues: FormValues = {
  diet: [],
  allergiesRestrictions: [],
  feedingMethod: undefined,
  texture: undefined,
  hydratation: [],
}

interface EditDietProps {
  isTemporary: boolean
  diet?: {
    startDate?: Timestamp
    endDate?: Timestamp
    allergiesRestrictions?: DietEntry[]
    diet?: DietEntry[]
    feedingMethod?: DietEntry
    texture?: DietEntry
    hydratation?: DietEntry[]
  }
  setEditDiet: (editDiet: boolean) => void
  medicalFile?: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
  resident: ResidentDoc
  setEditTempDiet: (editTempDiet: boolean) => void
}

const EditDietRow: React.FC<{
  title: string
  component: React.ReactNode
}> = ({ title, component }) => {
  return (
    <TableRow>
      <TableCell
        align="left"
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          borderBottom: "1px solid #FFFFFF1F",
          verticalAlign: "top",
        }}
      >
        {title}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          borderBottom: "1px solid #FFFFFF1F",
        }}
      >
        {component}
      </TableCell>
    </TableRow>
  )
}

export const EditDiet: React.FC<EditDietProps> = ({
  isTemporary,
  diet,
  setEditDiet,
  medicalFile,
  setMedicalFile,
  setEditTempDiet,
}) => {
  const { diets } = useWorkContext()
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues:
      {
        ...diet,
        feedingMethod: diet?.feedingMethod?.id,
        texture: diet?.texture?.id,
        endDate: diet?.endDate && dayjsOf(diet?.endDate),
      } || defaultValues,
  })

  const createOrUpdate = (dietInputs: DietInputs) => {
    if (!isTemporary && medicalFile && medicalFile.id) {
      singleDoc
        .medicalFile(medicalFile.id)
        .update({
          standardDiet: dietInputs,
        })
        .then(() => {
          setMedicalFile({ ...medicalFile, standardDiet: dietInputs })
          setEditDiet(false)
        })
    } else if (
      isTemporary &&
      medicalFile &&
      medicalFile.id &&
      dietInputs.endDate
    ) {
      console.log("update medical file with temporary diet", medicalFile.id)
      const temporaryDiet = {
        ...dietInputs,
        startDate: firebase.firestore.Timestamp.now(),
        endDate: dietInputs.endDate,
      }
      singleDoc
        .medicalFile(medicalFile.id)
        .update({
          temporaryDiet: temporaryDiet,
        })
        .then(() => {
          setMedicalFile({
            ...medicalFile,
            temporaryDiet: temporaryDiet,
          })
          setEditTempDiet(false)
        })
    }
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    console.log(data)
    if (!diets || !data.feedingMethod || !data.texture) {
      return
    }
    const dietInputs: DietInputs = omitNils({
      diet: data.diet && data.diet?.length > 0 ? data.diet : undefined,
      allergiesRestrictions:
        data.allergiesRestrictions && data.allergiesRestrictions?.length > 0
          ? data.allergiesRestrictions
          : undefined,
      feedingMethod: diets.feedingMethods.find(
        (d) => d.id === data.feedingMethod
      )!,
      texture: diets.textures.find((d) => d.id === data.texture)!,
      hydratation:
        data.hydratation && data.hydratation?.length > 0
          ? data.hydratation
          : undefined,
      endDate: data.endDate && timestampOf(data.endDate),
    })

    console.log("Diet inputs", dietInputs)

    createOrUpdate(dietInputs)
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Table sx={{ minWidth: 480 }} aria-label="simple table">
        {diets && (
          <TableBody>
            <EditDietRow
              title={"Régime alimentaire"}
              component={
                <RHFCheckboxes
                  options={diets.diets}
                  control={control}
                  name="diet"
                />
              }
            />
            <EditDietRow
              title={"Mode d'alimentation"}
              component={
                <Controller
                  name="feedingMethod"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={diets.feedingMethods}
                    />
                  )}
                />
              }
            />
            <EditDietRow
              title={"Texture"}
              component={
                <Controller
                  name="texture"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormSelectUncontrolled
                      {...field}
                      options={diets.textures}
                    />
                  )}
                />
              }
            />
            <EditDietRow
              title={"Hydratation"}
              component={
                <RHFCheckboxes
                  options={diets.hydratation}
                  control={control}
                  name="hydratation"
                />
              }
            />
            <EditDietRow
              title={"Interdit & Allergie alimentaire"}
              component={
                <RHFCheckboxes
                  options={diets.allergiesRestrictions}
                  control={control}
                  name="allergiesRestrictions"
                />
              }
            />
            {isTemporary && (
              <EditDietRow
                title={"Date de fin"}
                component={
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{ required: isTemporary ? true : false }}
                    render={({ field }) => (
                      <MuiDatePicker
                        {...field}
                        format="DD-MM-YYYY"
                        disablePast
                      />
                    )}
                  />
                }
              />
            )}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <MuiContainedButton
                sx={{
                  marginRight: 2,
                  height: 36,
                  backgroundColor: "transparent",
                  color: "#FFFFFF",
                  borderColor: "#90CAF980",
                  "&:hover": {
                    borderColor: "#90CAF980",
                    backgroundColor: "#90CAF980",
                  },
                }}
                onClick={() => {
                  console.log("cancel edit")
                  setEditDiet(false)
                }}
              >
                {"Annuler"}
              </MuiContainedButton>
              <MuiContainedButton
                sx={{ marginLeft: 2, height: 36 }}
                type="submit"
              >
                {"Enregistrer"}
              </MuiContainedButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </form>
  )
}
