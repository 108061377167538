import badgeImage from "../../../assets/badge.jpg"

export const createQRBadge = async (qrCodeDataURL: string, name: string) => {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  if (!context) return

  const loadImage = (src: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image()
      image.onload = () => resolve(image)
      image.onerror = reject
      image.src = src
    })

  // Load the background image
  const backgroundImage = await loadImage(badgeImage)

  // Set the canvas size to match the background image size
  canvas.width = backgroundImage.width
  canvas.height = backgroundImage.height

  // Draw the background image on the canvas
  context.drawImage(backgroundImage, 0, 0)

  // Calculate the position to place the QR code
  const qrCodeSize =
    Math.min(backgroundImage.width, backgroundImage.height) * 0.7
  const qrCodeX = (canvas.width - qrCodeSize) / 2
  const qrCodeY = (canvas.height - qrCodeSize) / 2 + 10

  // Draw the QR code on the canvas
  const qrCodeImage = await loadImage(qrCodeDataURL)
  context.drawImage(qrCodeImage, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize)

  // Set the text properties
  const fontSize = 16
  const textX = canvas.width / 2
  const textY = qrCodeY + qrCodeSize + 15
  context.font = `${fontSize}px Arial`
  context.textAlign = "center"
  context.fillStyle = "#000"

  // Draw the user's name below the QR code
  context.fillText(name, textX, textY)

  // Create the print button inside the new window
  const printButton = document.createElement("button")
  printButton.textContent = "Imprimer"
  printButton.style.marginLeft = "20px"
  printButton.addEventListener("click", () => {
    // Print the canvas
    newWindow?.print()
  })

  // Open the canvas image in a new window
  const newWindow = window.open("")
  newWindow && newWindow.document.body.appendChild(canvas)
  newWindow?.document.body.appendChild(printButton)
}
