import I18n from "i18n-js"
import fr from "./locales/fr"

// export const I18n = require('react-native-i18n').default;
export const I18 = I18n

I18.fallbacks = true
I18.defaultLocale = "fr"
I18.locale = "fr"
I18.missingBehaviour = "guess"

I18.translations = {
  fr,
}
