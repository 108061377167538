import React from "react"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useWorkContext } from "../../../../contexts/work.context"
import { DietCategories } from "../../../../domain/Diet"
import { DietEntry, MedicalFileIcons } from "../../../../domain/MedicalFile"
import { Timestamp, dayjsOf, singleDoc } from "../../../../core/firebase"
import { IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiDelete } from "@mdi/js"
import { Center } from "../../../../components/Center"
import { EditDiet } from "./EditDiet"
import { MedicalFileDoc } from "../../../../domain/MedicalFile"
import { ResidentDoc } from "../../../../domain/Resident"
import { MuiDatePicker } from "../../../../components/DateField"
import { deleteField } from "firebase/firestore"
import { MuiButton } from "../../../../components/Button"
import { ChecklistSelectedItem } from "./components/ChecklistSelectedItem"

const DietRow: React.FC<{
  title: string
  children: React.ReactNode
}> = ({ title, children }) => {
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell
        align="left"
        sx={{
          color: "#FFFFFF",
          fontSize: 14,
          borderBottom: "1px solid #FFFFFF1F",
          verticalAlign: "top",
        }}
      >
        <Typography sx={{ color: "#FFFFFF", fontSize: 14 }} gutterBottom>
          {title}
        </Typography>
      </TableCell>
      {children}
    </TableRow>
  )
}

const DietArrayCell: React.FC<{
  category: DietCategories
  content: DietEntry[]
}> = ({ category, content }) => {
  return (
    <TableCell
      align="left"
      sx={{
        display: "flex",
        flexDirection: "column",
        color: "#FFFFFF",
        fontSize: 14,
        borderBottom: "1px solid #FFFFFF1F",
        gap: 1,
      }}
    >
      {content.map((item) => (
        <ChecklistSelectedItem
          key={item.id}
          color={item.color}
          path={MedicalFileIcons[item.iconId]}
          label={item.label}
        />
        // <Typography key={item} sx={{ color: "#FFFFFF", fontSize: 14 }}>
        //   {diets &&
        //     diets[category].find((d: DietEntry) => d.id === item)?.label}
        // </Typography>
      ))}
    </TableCell>
  )
}
const DietCell: React.FC<{
  category: DietCategories
  content: DietEntry
}> = ({ category, content }) => {
  // const { diets } = useWorkContext()
  return (
    <TableCell
      align="left"
      sx={{
        color: "#FFFFFF",
        fontSize: 14,
        borderBottom: "1px solid #FFFFFF1F",
      }}
    >
      <ChecklistSelectedItem
        key={content.id}
        color={content.color}
        path={MedicalFileIcons[content.iconId]}
        label={content.label}
      />
      {/* <Typography sx={{ color: "#FFFFFF", fontSize: 14 }}>
        {diets &&
          diets[category].find((d: DietEntry) => d.id === content)?.label}
      </Typography> */}
    </TableCell>
  )
}

interface DietTableProps {
  isTemporary: boolean
  diet?: {
    startDate?: Timestamp
    endDate?: Timestamp
    allergiesRestrictions?: DietEntry[]
    diet?: DietEntry[]
    feedingMethod?: DietEntry
    texture?: DietEntry
    hydratation?: DietEntry[]
  }
  editDiet: boolean
  setEditDiet: (value: boolean) => void
  setEditTempDiet: (value: boolean) => void
  medicalFile?: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
  resident: ResidentDoc
}

export const DietTable: React.FC<DietTableProps> = ({
  isTemporary,
  diet,
  editDiet,
  setEditDiet,
  medicalFile,
  setMedicalFile,
  resident,
  setEditTempDiet,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const handleDeleteTempDiet = () => {
    // delete temporary diet in db
    if (!resident.medicalFileId || !medicalFile) return
    singleDoc
      .medicalFile(resident.medicalFileId)
      .update({
        temporaryDiet: deleteField(),
      })
      // update medical file state without temporary diet
      .then(() => {
        const newMedicalFile = { ...medicalFile }
        delete newMedicalFile.temporaryDiet
        setMedicalFile(newMedicalFile)
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            marginLeft: 3,
            alignItems: "center",
            display: "flex",
            height: 50,
            fontSize: 15,
            fontWeight: 500,
            color: "#FFFFFFB2",
            fontVariant: "small-caps",
          }}
        >
          {!isTemporary ? "Régime standard" : "Régime temporaire"}
        </Typography>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          {!editDiet && (
            <IconButton
              path={mdiPencil}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  setEditDiet(true)
                },
              }}
            />
          )}
          {isTemporary && !editDiet && (
            <IconButton
              path={mdiDelete}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  console.log("delete temporary diet")
                  setIsDialogOpen(true)
                },
              }}
            />
          )}
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
        }}
      >
        {!editDiet ? (
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableBody>
              {diet?.diet && (
                <DietRow title={"Régime alimentaire"}>
                  <DietArrayCell category="diets" content={diet?.diet} />
                </DietRow>
              )}
              {diet?.feedingMethod && (
                <DietRow title={"Mode d'alimentation"}>
                  <DietCell
                    category="feedingMethods"
                    content={diet?.feedingMethod}
                  />
                </DietRow>
              )}
              {diet?.texture && (
                <DietRow title={"Texture"}>
                  <DietCell category="textures" content={diet?.texture} />
                </DietRow>
              )}
              {diet?.hydratation && (
                <DietRow title={"Hydratation"}>
                  <DietArrayCell
                    category="hydratation"
                    content={diet?.hydratation}
                  />
                </DietRow>
              )}
              {diet?.allergiesRestrictions && (
                <DietRow title={"Interdit & Allergie alimentaire"}>
                  <DietArrayCell
                    category="allergiesRestrictions"
                    content={diet?.allergiesRestrictions}
                  />
                </DietRow>
              )}
              {diet?.endDate && (
                <DietRow title={"Date de fin"}>
                  <MuiDatePicker
                    format="DD-MM-YYYY"
                    value={dayjsOf(diet.endDate)}
                    readOnly
                    disableOpenPicker={true}
                  />
                </DietRow>
              )}
            </TableBody>
          </Table>
        ) : (
          <EditDiet
            isTemporary={isTemporary}
            diet={diet}
            setEditDiet={setEditDiet}
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
            resident={resident}
            setEditTempDiet={setEditTempDiet}
          />
        )}
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        PaperProps={{ sx: { backgroundColor: "#374D70" } }}
      >
        <DialogTitle sx={{ color: "#FFFFFF" }}>{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#FFFFFF" }}>
            {`Confirmez-vous la suppression du régime temporaire pour ${resident.firstname} ${resident.lastname} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setIsDialogOpen(false)}>Annuler</MuiButton>
          <MuiButton onClick={() => handleDeleteTempDiet()}>
            Supprimer
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
