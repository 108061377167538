import React, { useEffect, useState } from "react"
import { Button, Form, Input, Space, Typography } from "antd"
import { mdiAccount, mdiLockOutline } from "@mdi/js"
import { signInWithEmailAndPassword } from "firebase/auth"
import { Link } from "react-router-dom"
import { Icon } from "../../components/Icon"
import { auth } from "../../core/firebase"
import { I18 } from "../../core/i18n"
import "./login.less"

interface FormValues {
  email: string
  password: string
}

export const LoginForm: React.FC = () => {
  const [form] = Form.useForm()
  const [, forceUpdate] = useState({})
  const [displayMessage, setDisplayMessage] = useState(false)

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({})
  }, [])

  const onFinish = (values: FormValues) => {
    setDisplayMessage(false)
    signInWithEmailAndPassword(auth(), values.email, values.password).catch(
      (e) => {
        console.log("Auth error:", e)
        setDisplayMessage(true)
      }
    )
  }

  return (
    <Space direction={"vertical"} className={"loginForm"} size={20}>
      <span>
        {I18.t("loginHeader")} <b>ISKUS</b>
      </span>
      <span>{I18.t("loginSentence")}</span>
      <Form
        form={form}
        name="login"
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, pattern: /(\S)+@(\S)+$/ }]}
          help={""}
        >
          <Input
            prefix={<Icon path={mdiAccount} />}
            placeholder={I18.t("email")}
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]} help={""}>
          <Input
            prefix={<Icon path={mdiLockOutline} />}
            type="password"
            placeholder={I18.t("password")}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              className={"loginButton"}
              type="primary"
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              {I18.t("connect")}
            </Button>
          )}
        </Form.Item>
        {displayMessage && (
          <Typography.Text type={"danger"}>
            {I18.t("loginError")}
          </Typography.Text>
        )}
        <Form.Item>
          <Link
            className={"loginPasswordResetButton"}
            to="/login/reset-password"
          >
            {I18.t("loginPasswordForgotten")}
          </Link>
        </Form.Item>
      </Form>
    </Space>
  )
}
