import React, { useState } from "react"
import { Box, Table, TableBody, Typography } from "@mui/material"
import { Center } from "../../../../components/Center"
import { Icon, ButtonAddIcon } from "../../../../components/Icon"
import {
  AdministrativeFileDoc,
  LegalProtection,
} from "../../../../domain/AdministrativeFile"
import { mdiScaleBalance } from "@mdi/js"
import { ProtectionHeader } from "./ProtectionHeader"
import { ProtectionRow } from "./ProtectionRow"
import { ProtectionModal } from "./ProtectionModal"
import { UserDoc } from "../../../../domain/User"
import { useAuthUser } from "../../../../core/auth"
import { arrayRemove } from "firebase/firestore"
import { singleDoc } from "../../../../core/firebase"

interface ProtectionProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}

export interface ModalConfig {
  isOpen: boolean
  user?: UserDoc
  isUpdate?: boolean
  legalProtection?: LegalProtection
  index?: number
}

export const Protection: React.FC<ProtectionProps> = ({
  adminFile,
  setAdminFile,
}) => {
  const { user } = useAuthUser()
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    user: user,
    isOpen: false,
  })
  const legalProtections = adminFile.legalProtections

  const onModalSubmit = (
    adminFile: AdministrativeFileDoc,
    config: ModalConfig
  ): void => {
    setAdminFile(adminFile)
    setModalConfig(config)
  }

  const onAddClickHandler = () => {
    setModalConfig({ isOpen: true, user: user })
  }

  const onEditClickHandler = (index: number) => {
    setModalConfig({
      isOpen: true,
      user: user,
      isUpdate: true,
      index: index,
      legalProtection: legalProtections[index],
    })
  }

  const onDeleteClickHandler = (index: number) => {
    singleDoc
      .administrativeFile(adminFile.id)
      .update({
        legalProtections: arrayRemove(adminFile.legalProtections[index]),
      })
      .then(() => {
        setAdminFile({
          ...adminFile,
          legalProtections: adminFile.legalProtections.filter(
            (legalProtection, i) => i !== index
          ),
        })
      })
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiScaleBalance} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"Protection Juridique"}
          </Typography>
        </Box>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <ButtonAddIcon buttonProps={{ onClick: () => onAddClickHandler() }} />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        <Table size="small" sx={{ minWidth: 400 }} aria-label="simple table">
          <ProtectionHeader />
          <TableBody>
            {legalProtections?.map((legalProtection, index) => (
              <ProtectionRow
                key={legalProtection.label}
                index={index}
                adminFile={adminFile}
                setAdminFile={setAdminFile}
                onEditClick={(index) => onEditClickHandler(index)}
                legalProtection={legalProtection}
                onDeleteClick={(index) => onDeleteClickHandler(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <ProtectionModal
        adminFile={adminFile}
        config={modalConfig}
        onClose={() => setModalConfig({ isOpen: false })}
        onSubmit={onModalSubmit}
      />
    </>
  )
}
