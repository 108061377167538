import React, { useState } from "react"
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiTextField } from "../../../components/TextField"
import { User, UserDoc } from "../../../domain/User"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { MuiButton } from "../../../components/Button"
import { SelectProfile } from "../../../components/select/SelectProfile"
import { SelectContract } from "../../../components/select/SelectContract"
import { SelectRole } from "../../../components/select/SelectRole"
import { HexColorPicker } from "react-colorful"
import { AvatarUploader } from "../../../components/AvatarUploader"
import { useWorkContext } from "../../../contexts/work.context"
import { COLLECTIONS, createDoc, singleDoc } from "../../../core/firebase"
import { omitNils } from "../../../core/lang"
import { MuiCheckbox } from "../../../components/checklist/CustomChecklist"
import { IconButton } from "../../../components/Icon"
import { mdiWindowClose } from "@mdi/js"
import { IskusSpinner } from "../../../components/IskusSpinner"
import { SelectFacility } from "../../../components/select/SelectFacility"
import { FacilityDoc } from "../../../domain/Facility"

type FormValues = {
  lastname: string
  firstname: string
  email: string
  profileId: string
  contractId: string
  roleId: string
  isActive: boolean
  color: string
  avatarUrl: string
  isMultiFacilities: boolean
  facilityId: string
}

export interface ModalConfig {
  isOpen: boolean
  user?: UserDoc
  isUpdate?: boolean
}

interface UserModalProps {
  admin: UserDoc
  config: ModalConfig
  onClose: () => void
  onSubmit: (user: UserDoc, config: ModalConfig) => void
  selectedFacilities?: Record<string, FacilityDoc>
}

export const defaultValues: FormValues = {
  lastname: "",
  firstname: "",
  email: "",
  profileId: "",
  contractId: "",
  roleId: "",
  isActive: true,
  color: "#C40000",
  avatarUrl: "",
  isMultiFacilities: false,
  facilityId: "",
}

export const UserModal: React.FC<UserModalProps> = ({
  admin,
  config,
  onClose,
  onSubmit,
  selectedFacilities,
}) => {
  const { facility, currentOrganizationId } = useWorkContext()
  const { control, handleSubmit } = useForm<User>({
    defaultValues: config.user || {
      ...defaultValues,
      facilityId: !admin.isMultiFacilities ? admin.facilityId : undefined,
    },
  })
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)

  const createOrUpdate = (user: User): Promise<UserDoc> => {
    const full: User = omitNils({
      ...user,
      isArchived: false,
      organizationId: currentOrganizationId || admin.organizationId,
      initials: `${user.lastname.charAt(0).toUpperCase()}${user.firstname
        .charAt(0)
        .toUpperCase()}`,
    })

    if (config.isUpdate && config.user) {
      return singleDoc
        .user(config.user.id)
        .update(full)
        .then(() => ({ ...full, id: config.user!.id }))
    }
    return createDoc(COLLECTIONS.USERS, full).then((ref) => ({
      ...full,
      id: ref.id,
    }))
  }

  const onFormSubmit: SubmitHandler<User> = (user: User) => {
    console.log("User", user)
    setIsConfirmationLoading(true)
    createOrUpdate(user)
      .then((userDoc) => {
        onSubmit(userDoc, config)
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
      })
  }

  return (
    <>
      {isConfirmLoading ? (
        <IskusSpinner />
      ) : (
        <Dialog
          open={config.isOpen}
          onClose={onClose}
          PaperProps={{ sx: { backgroundColor: "#374D70", borderRadius: 2 } }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#374D70",
              padding: 3,
              borderWidth: 1,
              borderColor: "#FFFFFF",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: 500,
                  fontVariant: "small-caps",
                }}
              >
                {config.isUpdate
                  ? "Editer un utilisateur"
                  : "Nouvel utilisateur"}
              </Typography>
              <IconButton
                buttonProps={{
                  onClick: () => onClose(),
                }}
                path={mdiWindowClose}
                color={"#FFFFFF"}
              />
            </Box>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="lastname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Nom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="firstname"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="text"
                        label={"Prénom"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        required={true}
                        type="email"
                        label={"Email"}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="profileId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectProfile
                        {...field}
                        selectProps={{ style: { width: 250 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="contractId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectContract
                        {...field}
                        selectProps={{ style: { width: 250 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="roleId"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <SelectRole
                        {...field}
                        selectProps={{ style: { width: 250 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  {admin.roleId === "admin" &&
                    admin.isMultiFacilities === true && (
                      <Controller
                        name="facilityId"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <SelectFacility
                            {...field}
                            selectProps={{ style: { width: 250 } }}
                            selectedFacilities={selectedFacilities}
                          />
                        )}
                      />
                    )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#FFFFFFB2" }}>
                    {"Actif: "}
                  </Typography>
                  <Controller
                    name="isActive"
                    control={control}
                    defaultValue={true}
                    render={({ field: { value, ...field } }) => (
                      <MuiCheckbox checked={value} {...field} />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {admin.roleId === "admin" &&
                    admin.isMultiFacilities === true && (
                      <>
                        <Typography sx={{ color: "#FFFFFFB2" }}>
                          {"Multi-établissements: "}
                        </Typography>
                        <Controller
                          name="isMultiFacilities"
                          control={control}
                          defaultValue={false}
                          render={({ field: { value, ...field } }) => (
                            <MuiCheckbox checked={value} {...field} />
                          )}
                        />
                      </>
                    )}
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                    {"Couleur: "}
                  </Typography>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <HexColorPicker {...field} defaultValue={"#C40000"} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#FFFFFFB2", marginBottom: 2 }}>
                    {"Avatar: "}
                  </Typography>
                  <Controller
                    name="avatarUrl"
                    control={control}
                    render={({ field }) => (
                      <AvatarUploader
                        {...field}
                        filenamePrefix={`${facility?.name}-${config.user?.lastname}-${config.user?.firstname}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      gap: 2,
                      width: "100%",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <MuiButton
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Annuler
                    </MuiButton>
                    <MuiButton type="submit">Ok</MuiButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
