import {
  Box,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Center } from "../../../../components/Center"
import { mdiPencil } from "@mdi/js"
import { IconButton } from "../../../../components/Icon"
import {
  Dependency,
  MedicalFileDoc,
  MedicalFileIcons,
} from "../../../../domain/MedicalFile"
import { MuiContainedButton } from "../../../../components/Button"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  MuiCheckbox,
  RHFCheckboxes,
} from "../../../../components/checklist/CustomChecklist"
import { singleDoc } from "../../../../core/firebase"
import { DependenciesLibrary } from "../../../../domain/Medical"
import { ChecklistSelectedItem } from "./components/ChecklistSelectedItem"

interface DependenciesProps {
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

interface FormValues {
  dependencies: Dependency[]
}

const defaultValues: FormValues = {
  dependencies: [],
}

export const Dependencies: React.FC<DependenciesProps> = ({
  medicalFile,
  setMedicalFile,
}) => {
  const [editData, setEditData] = React.useState(false)
  const [dependenciesOptions, setDependenciesOptions] =
    useState<DependenciesLibrary>()
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: { dependencies: medicalFile.dependencies } || defaultValues,
  })

  useEffect(() => {
    singleDoc
      .medical<DependenciesLibrary>("dependencies")
      .fetch()
      .then((dependenciesOpt) => {
        setDependenciesOptions(dependenciesOpt.data())
      })
  }, [])

  useEffect(() => {
    reset({ dependencies: medicalFile.dependencies } || defaultValues)
  }, [medicalFile.dependencies, reset])

  const createOrUpdate = (dependencies: Dependency[]) => {
    return singleDoc
      .medicalFile(medicalFile.id)
      .update({
        dependencies,
      })
      .then(() => setMedicalFile({ ...medicalFile, dependencies }))
  }

  const onFormSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    console.log(data)
    console.log("form submitting !")
    createOrUpdate(data.dependencies)
      .then(() => setEditData(false))
      .catch((e) => {
        console.log(e)
        setEditData(false)
      })
  }

  const dependencies = medicalFile.dependencies

  return (
    <Grid container spacing={8}>
      <Grid item xs={6}>
        {/* TODO: MAKE A STANDARD MEDICAL FILE COMPONENT */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginLeft: 3,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 15,
              fontWeight: 500,
              color: "#FFFFFFB2",
              fontVariant: "small-caps",
            }}
          >
            {"Liste des dépendances"}
          </Typography>
          <Center
            style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
          >
            <IconButton
              path={mdiPencil}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  console.log("edit")
                  setEditData(true)
                },
              }}
            />
          </Center>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF33",
            borderRadius: 1,
            border: "1px solid #FFFFFF",
            padding: !editData ? 2 : 0,
          }}
        >
          {!editData ? (
            // READ MODE
            <Grid container spacing={2}>
              {dependencies.length > 0 ? (
                dependencies.map((dependency) => {
                  return (
                    <Grid item xs={6} key={dependency.id}>
                      <ChecklistSelectedItem
                        color={"#F2932B"}
                        path={MedicalFileIcons[dependency.iconId]}
                        label={dependency.label}
                      />
                    </Grid>
                  )
                })
              ) : (
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontStyle: "italic",
                      fontSize: 14,
                    }}
                  >
                    Aucune dépendance
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            // EDIT MODE
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <Table sx={{ minWidth: 480 }} aria-label="simple table">
                {/* TODO: include checkboxes with react hook form */}
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#FFFFFF",
                        fontSize: 14,
                        borderBottom: "1px solid #FFFFFF1F",
                        verticalAlign: "top",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {dependenciesOptions?.dependencies && (
                          <RHFCheckboxes
                            options={dependenciesOptions?.dependencies}
                            name="dependencies"
                            control={control}
                          />
                        )}
                        {/* <Controller
                          name="dependencies"
                          control={control}
                          render={({ field: { value, ...field } }) => (
                            <>
                              {dependenciesOptions?.dependencies.map(
                                (dependencyOption) => {
                                  return (
                                    <FormControlLabel
                                      key={dependencyOption.id}
                                      label={dependencyOption.label}
                                      sx={{
                                        height: 22,
                                        ".MuiFormControlLabel-label": {
                                          fontSize: 14,
                                          lineHeight: "20px",
                                          color: "#FFFFFF",
                                        },
                                      }}
                                      control={
                                        <MuiCheckbox
                                          {...field}
                                          value={dependencyOption.id}
                                          checked={value.some(
                                            (dependency) =>
                                              dependency.id ===
                                              dependencyOption.id
                                          )}
                                          onChange={(event, checked) => {
                                            if (checked) {
                                              field.onChange([
                                                ...value,
                                                dependencyOption,
                                              ])
                                            } else {
                                              field.onChange(
                                                value.filter(
                                                  (dependency) =>
                                                    dependency.id !==
                                                    dependencyOption.id
                                                )
                                              )
                                            }
                                          }}
                                        />
                                      }
                                    />
                                  )
                                }
                              )}
                            </>
                          )}
                        /> */}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={1} align="center" sx={{ border: 0 }}>
                      <MuiContainedButton
                        sx={{
                          marginRight: 2,
                          height: 36,
                          backgroundColor: "transparent",
                          color: "#FFFFFF",
                          borderColor: "#90CAF980",
                          "&:hover": {
                            borderColor: "#90CAF980",
                            backgroundColor: "#90CAF980",
                          },
                        }}
                        onClick={() => {
                          console.log("cancel edit")
                          setEditData(false)
                          reset(
                            { dependencies: medicalFile.dependencies } ||
                              defaultValues
                          )
                        }}
                      >
                        {"Annuler"}
                      </MuiContainedButton>
                      <MuiContainedButton
                        sx={{ marginLeft: 2, height: 36 }}
                        type="submit"
                      >
                        {"Enregistrer"}
                      </MuiContainedButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </form>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
