import React from "react"
import { TableCell } from "@mui/material"

export const FormCell: React.FC<{
  content: unknown
}> = ({ content }) => {
  return (
    <TableCell
      colSpan={1}
      align="left"
      sx={{
        color: "#FFFFFF",
        fontSize: 14,
        border: 0,
        padding: 0,
      }}
    >
      {content}
    </TableCell>
  )
}
