import React, { useState } from "react"
import { ResidentDoc } from "../../../../domain/Resident"
import { MedicalFileDoc } from "../../../../domain/MedicalFile"
import { dayjsOf } from "../../../../core/firebase"
import { Box, Button } from "@mui/material"
import { mdiPlus } from "@mdi/js"
import { Icon } from "../../../../components/Icon"
import dayjs from "dayjs"
import { DietTable } from "./DietTable"

interface DietProps {
  resident: ResidentDoc
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

export const Diet: React.FC<DietProps> = ({
  resident,
  medicalFile,
  setMedicalFile,
}) => {
  const [editDiet, setEditDiet] = useState<boolean>(false)
  const [editTempDiet, setEditTempDiet] = useState<boolean>(false)

  const standardDiet = medicalFile?.standardDiet
  const temporaryDiet =
    medicalFile?.temporaryDiet?.endDate &&
    dayjsOf(medicalFile.temporaryDiet.endDate)! > dayjs() &&
    medicalFile.temporaryDiet

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
      <Box sx={{ flex: 1 }}>
        {standardDiet || editDiet ? (
          <DietTable
            isTemporary={false}
            diet={standardDiet}
            editDiet={editDiet}
            setEditDiet={setEditDiet}
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
            resident={resident}
            setEditTempDiet={setEditTempDiet}
          />
        ) : (
          <Button
            variant="contained"
            startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
            style={{ marginTop: 50 }}
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#001C41",
              fontWeight: 500,
              fontSize: 14,
              textTransform: "capitalize",
            }}
            onClick={() => {
              setEditDiet(true)
            }}
          >
            Régime Standard
          </Button>
        )}
      </Box>
      <Box sx={{ flex: 1 }}>
        {temporaryDiet || editTempDiet ? (
          <DietTable
            isTemporary={true}
            diet={temporaryDiet || standardDiet}
            editDiet={editTempDiet}
            setEditDiet={setEditTempDiet}
            medicalFile={medicalFile}
            setMedicalFile={setMedicalFile}
            resident={resident}
            setEditTempDiet={setEditTempDiet}
          />
        ) : (
          standardDiet && (
            <Button
              variant="contained"
              startIcon={<Icon path={mdiPlus} color={"000000DE"} />}
              style={{ marginTop: 50 }}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#001C41",
                fontWeight: 500,
                fontSize: 14,
                textTransform: "capitalize",
              }}
              onClick={() => {
                setEditTempDiet(true)
              }}
            >
              Régime temporaire
            </Button>
          )
        )}
      </Box>
    </Box>
  )
}
