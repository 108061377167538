import React from "react"
import { Avatar, Box, Typography } from "@mui/material"
import { Timestamp, dayjsOf } from "../../../core/firebase"
import dayjs from "dayjs"

interface NotificationInfosProps {
  user: {
    id: string
    avatarUrl: string
    initials: string
    firstname: string
    lastname: string
    profileId: string
    color: string
  }
  creationDate: Timestamp
}

const formatDate = (date: Timestamp) => {
  const now = dayjs()
  const diff = now.diff(date.toDate(), "hour")
  if (diff < 24) {
    return `Il y a ${diff} heure${diff < 2 ? "" : "s"}`
  } else if (diff < 5 * 24) {
    const daysPassed = Math.floor(diff / 24)
    return `Il y a ${daysPassed} jour${daysPassed < 2 ? "" : "s"}`
  } else {
    return dayjsOf(date)!.format("DD/MM à HH:mm")
  }
}

export const NotificationInfos: React.FC<NotificationInfosProps> = ({
  user,
  creationDate,
}) => {
  const date = formatDate(creationDate)
  return (
    <Box
      sx={{
        minWidth: 200,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Avatar
        src={user.avatarUrl}
        sx={{
          height: 40,
          width: 40,
          marginRight: 1,
          backgroundColor: user.color,
        }}
      >
        {user.initials}
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ color: "#FFFFFF", fontSize: 16 }}>
          {user.firstname}
        </Typography>
        <Typography sx={{ color: "#FFFFFF8A", fontSize: 16 }}>
          {date}
        </Typography>
      </Box>
    </Box>
  )
}
