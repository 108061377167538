import { Box } from "@mui/material"
import logo from "./ISKUS-PNG-4.png"
// import logo from "./iskusLogoBlanc.png"
import smallLogo from "./ISKUS-PNG-1.png"
// import smallLogo from "./iskusLogoBlancSmall.png"

interface DrawerHeaderProps {
  drawerOpen: boolean
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = (
  props: DrawerHeaderProps
) => {
  const drawerOpen = props.drawerOpen

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        ...(drawerOpen && { justifyContent: "flex-start", ml: 2 }),
        backgroundColor: "#001C41",
      }}
    >
      {drawerOpen ? (
        <img src={logo} alt="logo" style={{ height: 50 }} />
      ) : (
        <img src={smallLogo} alt="logo" style={{ height: 50 }} />
      )}
    </Box>
  )
}
