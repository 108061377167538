import React, {
  Context,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { FacilityDoc } from "../domain/Facility"
import { useAuthUser } from "../core/auth"
import {
  getAllCares,
  getAllContracts,
  getAllMessageButtons,
  getAllOrganizations,
  getAllProfiles,
  getAllTasks,
  getAllVitalSigns,
  getOrganizationFacilities,
  singleDoc,
} from "../core/firebase"
import { MessageTheme } from "../domain/Message"
import { Role } from "../domain/Role"
import { getAllRoles } from "../core/firebase"
import { ProfileDoc } from "../domain/Profile"
import { ContractDoc } from "../domain/Contract"
import { TaskDoc } from "../domain/Task"
import { MessageButtonDoc } from "../domain/MessageButton"
import { VitalSignDoc } from "../domain/VitalSigns"
import { CarePlanActivityLibraryItem } from "../domain/CarePlan"
import { Diets } from "../domain/Diet"
import { EventCategory } from "../domain/Event"
import { OrganizationDoc } from "../domain/Organization"

interface WorkContext {
  facility?: FacilityDoc
  setFacility?: Dispatch<SetStateAction<FacilityDoc | undefined>>
  messageLabelByTheme: Record<MessageTheme, string>
  roleLabelById: Record<string, string>
  contractLabelById: Record<string, string>
  role?: Role
  profiles?: ProfileDoc[]
  contracts?: ContractDoc[]
  tasks?: TaskDoc[]
  messageButtons?: MessageButtonDoc[]
  vitalSigns?: VitalSignDoc[]
  facilities?: Record<string, FacilityDoc>
  eventCategories: EventCategory[]
  cares: CarePlanActivityLibraryItem[]
  diets: Diets | undefined
  organizations?: OrganizationDoc[]
  currentOrganizationId?: string
  setCurrentOrganizationId: Dispatch<SetStateAction<string | undefined>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const workContextContext: Context<WorkContext> = createContext(null) as any

export function useWorkContext(): WorkContext {
  return useContext(workContextContext)
}

export const ProvideWorkContext: React.FC = ({ children }) => {
  const { user } = useAuthUser()
  const [facility, setFacility] = useState<FacilityDoc | undefined>()
  const [facilities, setFacilities] = useState<Record<string, FacilityDoc>>()
  const [organizations, setOrganizations] = useState<OrganizationDoc[]>()
  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | undefined
  >()
  const [roleLabelById, setRoleLabelById] = useState<Record<string, string>>({})
  const [role, setRole] = useState<Role | undefined>()
  const [profiles, setProfiles] = useState<ProfileDoc[]>()
  const [contracts, setContracts] = useState<ContractDoc[]>()
  const [tasks, setTasks] = useState<TaskDoc[]>()
  const [messageButtons, setMessageButtons] = useState<MessageButtonDoc[]>()
  const [vitalSigns, setVitalSigns] = useState<VitalSignDoc[]>()
  const [cares, setCares] = useState<CarePlanActivityLibraryItem[]>([])
  const [diets, setDiets] = useState<Diets | undefined>()
  const [eventCategories, setEventCategories] = useState<EventCategory[]>([])
  const messageLabelByTheme = useMemo(
    () =>
      (messageButtons || []).reduce((dict, button) => {
        dict[button.theme] = button.label
        return dict
      }, {} as Record<string, string>),
    [messageButtons]
  )
  const contractLabelById = useMemo(
    () =>
      (contracts || []).reduce((dict, contract) => {
        dict[contract.id] = contract.fullName
        return dict
      }, {} as Record<string, string>),
    [contracts]
  )

  useEffect(() => {
    async function initData() {
      getAllRoles().then((roles) =>
        setRoleLabelById(
          roles.docs.reduce((dict, role) => {
            dict[role.data().id] = role.data().name
            return dict
          }, {} as Record<string, string>)
        )
      )
    }
    initData()
    console.log("initData")
  }, [])

  useEffect(() => {
    async function initData() {
      getAllProfiles().then((profiles) => {
        console.log(
          "PROFILES DOCS:",
          profiles.docs.map((doc) => doc.data())
        )
        setProfiles(profiles.docs.map((doc) => doc.data()))
      })
    }
    initData()
    console.log("initData")
  }, [])

  useEffect(() => {
    async function initData() {
      getAllContracts().then((contracts) => {
        console.log(
          "CONTRACTS DOCS:",
          contracts.docs.map((doc) => doc.data())
        )
        setContracts(contracts.docs.map((doc) => doc.data()))
      })
    }
    initData()
  }, [])

  useEffect(() => {
    async function initData() {
      getAllMessageButtons().then((buttons) => {
        console.log(
          "BUTTONS DOCS:",
          buttons.docs.map((doc) => doc.data())
        )
        setMessageButtons(buttons.docs.map((doc) => doc.data()))
      })
    }
    initData()
  }, [])

  useEffect(() => {
    async function initData() {
      getAllTasks().then((tasks) => {
        console.log(
          "TASKS DOCS:",
          tasks.docs.map((doc) => doc.data())
        )
        setTasks(tasks.docs.map((doc) => doc.data()))
      })
    }
    initData()
  }, [])

  useEffect(() => {
    if (!user) {
      setFacility(undefined)
      return
    }
    singleDoc
      .facility(user.facilityId)
      .fetch()
      .then((result) => {
        if (result.exists()) {
          setFacility(result.data())
        }
      })
    singleDoc
      .role(user.roleId)
      .fetch()
      .then((result) => {
        console.log(result.data())
        setRole(result.data())
      })
    singleDoc
      .diets("diets")
      .fetch()
      .then((result) => {
        console.log(result.data())
        setDiets(result.data())
      })
    singleDoc
      .calendar<{ categories: EventCategory[] }>("eventCategories")
      .fetch()
      .then((categories) => {
        if (!categories.exists()) return
        console.log("categories", categories.data())
        setEventCategories(categories.data().categories)
      })
  }, [user])

  useEffect(() => {
    async function initData() {
      getAllVitalSigns().then((vitalSigns) => {
        console.log(
          "VITAL SIGN DOCS:",
          vitalSigns.docs.map((doc) => doc.data())
        )
        setVitalSigns(vitalSigns.docs.map((doc) => doc.data()))
      })
    }
    initData()
  }, [])

  useEffect(() => {
    async function initData() {
      getAllCares().then((cares) => {
        console.log(
          "CARES ITEM:",
          cares.docs.map((doc) => doc.data())
        )
        setCares(cares.docs.map((doc) => doc.data()))
      })
    }
    initData()
  }, [])

  useEffect(() => {
    async function initData() {
      if (!user) return
      if (user?.isMultiFacilities) {
        getOrganizationFacilities(user.organizationId).then((facilities) => {
          const facilitiesDict = {} as Record<string, FacilityDoc>
          facilities.docs.map((doc) => {
            facilitiesDict[doc.data().id] = doc.data()
          })
          console.log("Current ORganization facilities:", facilitiesDict)
          setFacilities(facilitiesDict)
        })
      } else {
        singleDoc
          .facility(user.facilityId)
          .fetch()
          .then((result) => {
            if (result.exists()) {
              setFacilities({ [result.data().id]: result.data() })
            }
          })
      }
    }
    initData()
  }, [user])

  useEffect(() => {
    async function initData() {
      if (!user) return
      if (user.isSuperAdmin) {
        getAllOrganizations().then((organizations) => {
          return setOrganizations(
            organizations.docs.map((doc) => {
              return { ...doc.data(), id: doc.id }
            })
          )
        })
      }
    }
    initData()
  }, [user])

  console.log(
    "current organization",
    currentOrganizationId || "no organization"
  )

  console.log("current facilities:", facilities)

  return (
    <workContextContext.Provider
      value={{
        facility,
        setFacility,
        messageLabelByTheme,
        roleLabelById,
        role,
        profiles,
        contracts,
        tasks,
        messageButtons,
        contractLabelById,
        vitalSigns,
        facilities,
        eventCategories,
        cares,
        diets,
        organizations,
        currentOrganizationId,
        setCurrentOrganizationId,
      }}
    >
      {children}
    </workContextContext.Provider>
  )
}
