import React, { useEffect, useState } from "react"
import { Center } from "../../components/Center"
import { getAllFacilities, singleDoc } from "../../core/firebase"
import { FacilityDoc } from "../../domain/Facility"
import { ButtonAddIcon } from "../../components/Icon"
import { IskusSpinner } from "../../components/IskusSpinner"
import { Header } from "./components/FacilitiesTableHeader"
import { Box, Table, TableBody } from "@mui/material"
import { Row } from "./components/FacilitiesTableRow"
import { FacilityModal, ModalConfig } from "./components/FacilityModal"

export const UpsertFacility: React.FC = () => {
  const [facilities, setFacilities] = useState<FacilityDoc[]>([])
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })

  useEffect(() => {
    async function initData() {
      getAllFacilities().then((fs) =>
        setFacilities(fs.docs.map((d) => d.data()))
      )
    }
    initData()
  }, [])

  const onAddClick = () => {
    setModalConfig({ isOpen: true })
  }

  const onEditClick = (editing: FacilityDoc) => {
    setModalConfig({ isOpen: true, isUpdate: true, facility: editing })
  }

  const onRemove = (removing: FacilityDoc) => {
    const newFacilities = facilities.filter((f) => f.id !== removing.id)
    singleDoc
      .facility(removing.id)
      .remove()
      .then(() => setFacilities(newFacilities))
      .catch((e) => {
        console.log("Error", e)
      })
  }

  const onClose = (facility?: FacilityDoc) => {
    if (facility) {
      setFacilities(
        facilities.filter((f) => f.id !== facility.id).concat([facility])
      )
    }
    setModalConfig({ isOpen: false })
  }

  return (
    <>
      {!facilities ? (
        <IskusSpinner />
      ) : (
        <Box sx={{ marginTop: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Center
              style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
            >
              <ButtonAddIcon buttonProps={{ onClick: () => onAddClick() }} />
            </Center>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF33",
              borderRadius: 1,
              border: "1px solid #FFFFFF",
              paddingY: 2,
              marginBottom: 6,
            }}
          >
            {/* NEW TABLE */}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Header />
              <TableBody>
                {facilities.map((facility) => (
                  <Row
                    key={facility.id}
                    facility={facility}
                    onEditClick={onEditClick}
                    onRemove={onRemove}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
      {modalConfig.isOpen && (
        <FacilityModal config={modalConfig} onClose={onClose} />
      )}
    </>
  )
}
