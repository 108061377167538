import React, {
  Context,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"
import { User, UserDoc } from "../domain/User"
import { onAuthStateChanged } from "firebase/auth"
import { auth, COLLECTIONS, converter } from "./firebase"
import { getDocs, query, where } from "firebase/firestore"

interface AuthUserContext {
  user?: UserDoc
  initializing: boolean
}

const authUserContext: Context<AuthUserContext> = createContext(null) as never

export function useAuthUser(): AuthUserContext {
  return useContext(authUserContext)
}

export const ProvideAuthUser: React.FC = ({ children }) => {
  const [initializing, setInitializing] = useState<boolean>(true)
  const [user, setUser] = useState<UserDoc>()

  useEffect(() => {
    // return to unsubscribe
    return onAuthStateChanged(auth(), async (userState) => {
      if (!userState) {
        setUser(undefined)
        setInitializing(false)
        return
      }

      const results = await getDocs<UserDoc>(
        query<User>(
          COLLECTIONS.USERS,
          where("email", "==", userState?.email),
          where("isActive", "==", true)
        ).withConverter(converter<UserDoc>())
      )
      if (!results.empty) {
        setUser(results.docs[0].data())
      }
      setInitializing(false)
    })
  }, [])

  return (
    <authUserContext.Provider value={{ initializing, user }}>
      {children}
    </authUserContext.Provider>
  )
}
