import React, { useState } from "react"
import { Box, Table, TableBody } from "@mui/material"
import { ButtonAddIcon } from "../../../../components/Icon"
import { Center } from "../../../../components/Center"
import { singleDoc } from "../../../../core/firebase"
import { arrayRemove } from "firebase/firestore"
import { MedicalFileDoc, Vaccine } from "../../../../domain/MedicalFile"
import { VaccinesHeader } from "./VaccinesHeader"
import { VaccineRow } from "./VaccineRow"
import { VaccineModal } from "./VaccineModal"

interface VaccinesProps {
  medicalFile: MedicalFileDoc
  setMedicalFile: (medicalFile: MedicalFileDoc) => void
}

export interface ModalConfig {
  isOpen: boolean
  isUpdate?: boolean
  vaccine?: Vaccine
  index?: number
}

export const Vaccines: React.FC<VaccinesProps> = ({
  medicalFile,
  setMedicalFile,
}) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
    vaccine: undefined,
  })

  const vaccines = medicalFile.vaccines

  const onModalSubmit = (
    medicalFile: MedicalFileDoc,
    config: ModalConfig
  ): void => {
    setMedicalFile(medicalFile)
    setModalConfig(config)
  }

  const onAddClickHandler = () => {
    setModalConfig({ isOpen: true })
  }

  const onEditClickHandler = (index: number): void => {
    if (!vaccines) return
    setModalConfig({
      isOpen: true,
      isUpdate: true,
      index: index,
      vaccine: vaccines[index],
    })
  }

  const onDeleteClickHandler = (index: number): void => {
    if (!vaccines) return
    singleDoc
      .medicalFile(medicalFile.id)
      .update({
        vaccines: arrayRemove(vaccines[index]),
      })
      .then(() => {
        setMedicalFile({
          ...medicalFile,
          vaccines: medicalFile.vaccines.filter((_, i) => i !== index),
        })
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          <ButtonAddIcon buttonProps={{ onClick: () => onAddClickHandler() }} />
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          marginBottom: 6,
        }}
      >
        <Table size="small" sx={{ minWidth: 400 }} aria-label="simple table">
          <VaccinesHeader />
          <TableBody>
            {vaccines?.map((vaccine, index) => (
              <VaccineRow
                key={index} // TODO: FIND A BETTER KEY THAN INDEX
                index={index}
                onEditClick={(index) => onEditClickHandler(index)}
                vaccine={vaccine}
                onDeleteClick={(index) => onDeleteClickHandler(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <VaccineModal
        config={modalConfig}
        onClose={() => setModalConfig({ isOpen: false })}
        onSubmit={onModalSubmit}
        medicalFile={medicalFile}
      />
    </>
  )
}
