import React, { useState } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material"
import { Icon } from "../../../../components/Icon"
import { IconButton } from "../../../../components/Icon"
import { mdiPencil, mdiAccount } from "@mdi/js"
import { Center } from "../../../../components/Center"
import { AdministrativeFileDoc } from "../../../../domain/AdministrativeFile"
import { FormCell } from "../../../../components/form/FormCell"
import { FormRow } from "../../../../components/form/FormRow"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { MuiTextField } from "../../../../components/TextField"
import { MuiButton } from "../../../../components/Button"
import { singleDoc } from "../../../../core/firebase"

interface AboutProps {
  adminFile: AdministrativeFileDoc
  setAdminFile: (adminFile: AdministrativeFileDoc) => void
}
type FormValues = {
  aboutResident: string
}

const defaultValues: FormValues = {
  aboutResident: "",
}

export const About: React.FC<AboutProps> = ({ adminFile, setAdminFile }) => {
  const [isEditing, setIsEditing] = useState(false)
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: adminFile.aboutResident || defaultValues,
  })
  const about = adminFile.aboutResident

  const onFormSubmit: SubmitHandler<FormValues> = (value: FormValues) => {
    console.log("Values: ", value)
    if (!adminFile) return
    singleDoc
      .administrativeFile(adminFile?.id)
      .update({ aboutResident: value.aboutResident })
      .then(() => {
        setAdminFile({
          ...adminFile,
          aboutResident: value.aboutResident,
        })
        setIsEditing(false)
      })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 40,
          }}
        >
          <Icon path={mdiAccount} color={"#FFFFFF"} />
          <Typography
            sx={{
              marginLeft: 1,
              alignItems: "center",
              display: "flex",
              height: 50,
              fontSize: 24,
              fontWeight: 500,
              color: "#FFFFFFB2",
            }}
          >
            {"A propos de"}
          </Typography>
        </Box>
        <Center
          style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
        >
          {!isEditing && (
            <IconButton
              path={mdiPencil}
              color={"#FFFFFF8F"}
              buttonProps={{
                onClick: () => {
                  setIsEditing(true)
                },
              }}
            />
          )}
        </Center>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF33",
          borderRadius: 1,
          border: "1px solid #FFFFFF",
          paddingY: 2,
          paddingX: 4,
        }}
      >
        {!isEditing ? (
          <Table
            sx={{ minWidth: "100%", tableLayout: "fixed" }}
            aria-label="simple table"
          >
            <TableBody>
              <FormCell content={about}></FormCell>
            </TableBody>
          </Table>
        ) : (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Table
              sx={{ minWidth: "100%", tableLayout: "fixed" }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <Controller
                    name="aboutResident"
                    control={control}
                    render={({ field }) => (
                      <MuiTextField
                        fullWidth
                        multiline
                        rows={3}
                        type="text"
                        {...field}
                      />
                    )}
                  />
                </TableRow>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={1} sx={{ border: 0 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        gap: 2,
                        width: "100%",
                        alignItems: "center",
                        marginTop: 2,
                      }}
                    >
                      <MuiButton
                        onClick={() => {
                          setIsEditing(false)
                        }}
                      >
                        Annuler
                      </MuiButton>
                      <MuiButton type="submit">Ok</MuiButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </form>
        )}
      </Box>
    </>
  )
}
