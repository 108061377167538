import { getDocs, limit, orderBy, query, where } from "firebase/firestore"
import { Message, MessageDoc } from "../../domain/Message"
import { COLLECTIONS, converter, timestampOf } from "../../core/firebase"
import { Dayjs } from "dayjs"

export const getLatestIncident = (facilityId: string): Promise<Message> =>
  getDocs<MessageDoc>(
    query<Message>(
      COLLECTIONS.MESSAGE,
      where("facilityId", "==", facilityId),
      where("alertLevel", "in", ["warning", "danger"]),
      orderBy("creationDate", "desc"),
      limit(1)
    ).withConverter(converter<MessageDoc>())
  ).then((snapshot) => snapshot.docs[0]?.data())

export const getIncidents = (facilityId: string, since: Dayjs) =>
  getDocs<MessageDoc>(
    query<Message>(
      COLLECTIONS.MESSAGE,
      where("facilityId", "==", facilityId),
      where("alertLevel", "in", ["warning", "danger"]),
      where("creationDate", ">", timestampOf(since)),
      orderBy("creationDate", "desc")
    ).withConverter(converter<MessageDoc>())
  ).then((snapshot) => snapshot.docs.map((d) => d.data()))
