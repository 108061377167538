import { Box, CardActionArea, CardMedia, Typography } from "@mui/material"
import { EventCardContainer } from "../../../../components/Card"
import React from "react"
import { EventDoc } from "../../../../domain/Event"
import { dayjsOf } from "../../../../core/firebase"
import { useWorkContext } from "../../../../contexts/work.context"
import image from "../../../../assets/protocol-image.png"

interface EventCardProps {
  event: EventDoc
  onClick?: (event: EventDoc) => void
}

export const EventCard: React.FC<EventCardProps> = ({ event, onClick }) => {
  const { eventCategories } = useWorkContext()
  const renderDate = () => {
    if (
      dayjsOf(event.startDate)?.format("DD/MM/YYYY") ===
      dayjsOf(event.endDate)?.format("DD/MM/YYYY")
    ) {
      return `${dayjsOf(event.startDate)?.format("HH:mm")} - ${dayjsOf(
        event.endDate
      )?.format("HH:mm")}`
    } else {
      return `${dayjsOf(event.startDate)?.format("DD MMM HH:mm")} - ${dayjsOf(
        event.endDate
      )?.format("DD MMM HH:mm")}`
    }
  }

  return (
    <CardActionArea
      onClick={() => onClick && onClick(event)}
      sx={{ width: 335, height: 206 }}
    >
      <EventCardContainer boxProps={{ style: { width: 335, height: 206 } }}>
        <CardMedia
          component="img"
          sx={{
            width: 337,
            height: 100,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            transform: "translateY(-1px)",
          }}
          src={
            eventCategories.find((cat) => cat.id === event.category)
              ?.imageUrl || image
          }
          onError={(e) => (e.currentTarget.src = image)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
            width: 325,
          }}
        >
          <Typography
            // noWrap
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FFFFFF",
              fontSize: 20,
              textAlign: "center",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {eventCategories.find((cat) => cat.id === event.category)?.label}{" "}
            {event.label && ` • ${event.label}`}
          </Typography>
          <Typography
            noWrap
            sx={{
              overflow: "hidden",
              color: "#FFFFFF",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {renderDate()}
          </Typography>
        </Box>
      </EventCardContainer>
    </CardActionArea>
  )
}
