import React, { useEffect, useState } from "react"
import { Center } from "../../components/Center"
import { getAllOrganizations, singleDoc } from "../../core/firebase"
import { ButtonAddIcon } from "../../components/Icon"
import { IskusSpinner } from "../../components/IskusSpinner"
import { Header } from "./components/OrganizationsTableHeader"
import { Box, Table, TableBody } from "@mui/material"
import { Row } from "./components/OrganizationsTableRow"
import { ModalConfig } from "./components/OrganizationModal"
import { OrganizationDoc } from "../../domain/Organization"
import { OrganizationModal } from "./components/OrganizationModal"

export const Organizations: React.FC = () => {
  const [organizations, setOrganizations] = useState<OrganizationDoc[]>([])
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isOpen: false,
  })

  useEffect(() => {
    async function initData() {
      getAllOrganizations().then((organizations) => {
        return setOrganizations(
          organizations.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
          })
        )
      })
    }
    initData()
  }, [])

  const onAddClick = () => {
    setModalConfig({ isOpen: true })
  }

  const onEditClick = (editing: OrganizationDoc) => {
    setModalConfig({ isOpen: true, isUpdate: true, organization: editing })
  }

  const onRemove = (removing: OrganizationDoc) => {
    const newOrganizations = organizations.filter((f) => f.id !== removing.id)
    singleDoc
      .organization(removing.id)
      .remove()
      .then(() => setOrganizations(newOrganizations))
      .catch((e) => {
        console.log("Error", e)
      })
  }

  const onClose = (organization?: OrganizationDoc) => {
    if (organization) {
      setOrganizations(
        organizations
          .filter((o) => o.id !== organization.id)
          .concat([organization])
      )
    }
    setModalConfig({ isOpen: false })
  }

  return (
    <>
      {!organizations ? (
        <IskusSpinner />
      ) : (
        <Box sx={{ marginTop: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Center
              style={{ display: "flex", flexDirection: "row", marginRight: 12 }}
            >
              <ButtonAddIcon buttonProps={{ onClick: () => onAddClick() }} />
            </Center>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF33",
              borderRadius: 1,
              border: "1px solid #FFFFFF",
              paddingY: 2,
              marginBottom: 6,
            }}
          >
            {/* NEW TABLE */}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Header />
              <TableBody>
                {organizations.map((organization) => (
                  <Row
                    key={organization.id}
                    organization={organization}
                    onEditClick={onEditClick}
                    onRemove={onRemove}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
      {modalConfig.isOpen && (
        <OrganizationModal config={modalConfig} onClose={onClose} />
      )}
    </>
  )
}
