import React from "react"
import { Cell } from "../../../../components/table/TableCell"
import { TableHead, TableRow } from "@mui/material"

export const AcquaintanceHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <Cell>Personne à contacter</Cell>
        <Cell>Lien de parenté</Cell>
        <Cell>Numéro de téléphone</Cell>
        <Cell props={{ align: "center" }}>A prévenir le jour</Cell>
        <Cell props={{ align: "center" }}>A prévenir la nuit</Cell>
        <Cell props={{ align: "center" }}>Edition</Cell>
      </TableRow>
    </TableHead>
  )
}
