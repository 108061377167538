import {
  getDownloadURL,
  getStorage,
  getMetadata,
  ref,
  uploadBytes,
  UploadResult as FUploadResult,
  FullMetadata,
  StorageReference,
} from "firebase/storage"
import { app } from "./firebase"

const storage = getStorage(app)
const avatarsRef = ref(storage, "avatars")
const residentsFilesRef = ref(storage, "residentsFiles")

export const uploadAvatar = (name: string, blob: Blob) =>
  uploadBytes(ref(avatarsRef, name), blob).then((res) =>
    getDownloadURL(res.ref)
  )

export const uploadResidentsFilesReturnPath = (name: string, blob: Blob) =>
  uploadBytes(ref(residentsFilesRef, name), blob).then((res) => {
    return res.ref.fullPath
  })

export const uploadResidentsFilesReturnUrl = (name: string, blob: Blob) =>
  uploadBytes(ref(residentsFilesRef, name), blob).then((res) => {
    return getDownloadURL(res.ref)
  })

export const getFileMetadata = async (
  fileRef: StorageReference
): Promise<FullMetadata> => {
  const metadata = getMetadata(fileRef)
  return metadata
}

export const getFileRef = (url: string) => ref(storage, url)

export type UploadResult = FUploadResult
